import RawSlider from '@react-native-community/slider';
import * as React from 'react';

import { useTheme } from './theme';

export function Slider(props: React.ComponentProps<typeof RawSlider>) {
  const { c } = useTheme();
  return (
    // @ts-ignore
    <RawSlider
      thumbTintColor={c('accent')}
      minimumTrackTintColor={c('accent')}
      maximumTrackTintColor={c('accent', 'back', 'disabled')}
      {...props}
    />
  );
}
