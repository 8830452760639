import * as React from 'react';

import { List } from '../components';
import { store, Template } from '../store';
import { t, useNavigation } from '../utils';
import TemplateListItem from './TemplateListItem';
import TemplatesButton from './TemplatesButton';

export default function TemplatesScreen() {
  const { myTemplates } = store;
  const navigation = useNavigation();
  const [editMode, setEditMode] = React.useState(false);
  const [selection, setSelection] = React.useState<Template[]>([]);

  React.useEffect(() => {
    navigation.setOptions({
      title: editMode ? t('$1 selected', selection.length + '') : t('My templates'),
      headerRight: () => (
        <TemplatesButton
          editMode={editMode}
          onPress={async (action) => {
            switch (action) {
              case 'edit':
              case 'endEdit':
                setEditMode(action === 'edit');
                break;
              case 'delete':
                await Promise.all(selection.map((template) => template.deletePermanently({})));
                setSelection(() => []);
                setEditMode(() => false);
            }
          }}
        />
      ),
    });
  }, [editMode, setEditMode, selection]);

  return (
    <List<Template>
      placeholder={t('Add a template ☝️')}
      dataSource={myTemplates}
      renderItem={(template) => (
        <TemplateListItem
          key={template.id}
          template={template}
          selected={editMode ? selection.includes(template) : undefined}
          onPress={
            editMode
              ? () =>
                  setSelection((selection) =>
                    selection.includes(template)
                      ? selection.filter((a) => a !== template)
                      : [...selection, template]
                  )
              : undefined
          }
        />
      )}
    />
  );
}
