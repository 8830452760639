import isEqual from 'lodash/isEqual';
import * as React from 'react';

type Props = {
  children: (props: any[]) => any;
  props: any[];
};

export class PureComponent extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props.props, nextProps.props);
  }

  render() {
    return this.props.children(this.props.props);
  }
}
