import jwt_decode from 'jwt-decode';

import { ToastContextData } from '../components';
import { Navigation } from '../utils';

export type AdminAction = {
  title: string;
  action: string;
  fields: { [key: string]: any };
  inputs?: {
    [key: string]: (input: string) => { [key: string]: any };
  };
  onSuccess?: (result: {
    request: any;
    response: any;
    navigation: Navigation;
    toast: ToastContextData;
  }) => any;
};

function decodeToken(token: string): { templateId: string } {
  const result: any = jwt_decode(token);
  return { templateId: result.tid };
}

export const adminActions: {
  [key: string]: AdminAction;
} = {
  migrate: {
    title: 'Migrate database',
    action: 'migrate',
    fields: {
      resourceType: 'game',
      resourceId: 'all', // o
    },
  },
  userClaims: {
    title: 'Set user claims',
    action: 'userClaims',
    fields: {
      userId: '',
      claims: {
        admin: true,
      },
    },
  },
  duplicateTemplate: {
    title: 'Duplicate template',
    action: 'duplicateTemplate',
    inputs: {
      link: (link: string) => {
        if (!link) return {};
        const url = new URL(link);
        const token = url.searchParams.get('token');
        const ownerId = url.searchParams.get('referrer');
        const { templateId } = decodeToken(token!);
        return templateId ? { templateId, ownerId } : {};
      },
    },
    fields: {
      templateId: '',
      ownerId: '',
    },
    onSuccess: ({ navigation, response }) => navigation.push('template', { template: response.id }),
  },
};

export function getAdminAction(adminActionId: string): AdminAction | undefined {
  return adminActions[adminActionId];
}
