import * as React from 'react';
import { useWindowDimensions } from 'react-native';

import { useTheme, ColorName } from '../../components';
import { Cell } from './Cell';

export function CellBackground(
  props: Omit<React.ComponentProps<typeof Cell>, 'style'> & {
    color?: ColorName;
  }
) {
  const { left, top, width = 0, height = 0, color, debugName, ...otherProps } = props;
  const { c } = useTheme();
  const size = useWindowDimensions();
  return (
    <Cell
      {...otherProps}
      style={color ? { backgroundColor: c(color) } : undefined}
      left={(left ?? 0) + width - size.width}
      top={(top ?? 0) + height - size.height}
      debugName={debugName ?? 'CellBackground'}
    />
  );
}
