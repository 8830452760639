import { Document } from 'firestorter';

import { DocumentFragmentCollection } from './framework';
import type { PublicTemplateListData, PublicTemplateListItemData } from './types';

export class PublicTemplateList extends Document<PublicTemplateListData> {
  public readonly templates = new DocumentFragmentCollection<PublicTemplateListItemData>(
    this,
    'templates'
  );
}
