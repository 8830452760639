import * as React from 'react';
import { View, StyleSheet, ScrollView, useWindowDimensions } from 'react-native';

import {
  AnimationContainer,
  Animation,
  AnimationConfig,
  Title,
  Background,
  useHeaderHeight,
} from '../components';
import DemoGameSlider from '../demo/DemoGameSlider';
import { store, observer, Game, GameClientPhaseType, GameClientPhaseStatus } from '../store';
import { t, useGame } from '../utils';
import GameChoiceResults from './GameChoiceResults';
import GameChooseInput from './GameChooseInput';
import GameDialogueText from './GameDialogueText';
import GameHeaderContainer from './GameHeaderContainer';
import GameIntroFooter from './GameIntroFooter';
import GameLieInput from './GameLieInput';
import GamePlayersView from './GamePlayersView';
import GameQuestionView from './GameQuestionView';
import GameStatusView from './GameStatusView';
import GameTruthView from './GameTruthView';
import GameWaitingStatus from './GameWaitingStatus';

const zoomUp = { opacity: 0, duration: 0.4, transform: [{ translateY: 100 }, { scale: 0.2 }] };
const zoomDown = { opacity: 0, duration: 0.4, transform: [{ translateY: -100 }, { scale: 0.2 }] };
const zoomCenter = { opacity: 0, duration: 0.4, transform: [{ scale: 0.2 }] };
const fade = { opacity: 0, duration: 0.4 };
const slideDown = { opacity: 0, transform: [{ translateY: -300 }], duration: 0.4 };
// const slideUp = { opacity: 0, transform: [{ translateY: 300 }], duration: 0.4 };

const config: { [key: string]: AnimationConfig } = {
  default: { enter: fade, exit: fade, layout: { duration: 0.4 } },
  introHeader: { enter: zoomUp, exit: fade },
  introFooter: { enter: zoomDown, exit: fade },
  question: { enter: slideDown, exit: slideDown },
  lieInput: { enter: zoomCenter, exit: fade },
  truth: { enter: zoomUp, exit: fade },
};

function render(id: string, fn: () => any, condition: boolean, style?: any) {
  if (condition) {
    return (
      <Animation key={id} id={id} config={config[id] ?? config.default} style={style}>
        {fn()}
      </Animation>
    );
  } else {
    return undefined;
  }
}

function spacer(id: string, height: number, condition: boolean) {
  if (condition) {
    return <View key={id} style={{ height }} />;
  } else {
    return undefined;
  }
}

class GameAnimationContainer extends React.PureComponent<{
  game: Game;
  phaseType: GameClientPhaseType;
  phaseStatus: GameClientPhaseStatus;
  height: number;
  isHeaderVisible: boolean;
  headerHeight: number;
  headerTopInset: number;
}> {
  render() {
    const { game, phaseType, phaseStatus, height, isHeaderVisible, headerHeight, headerTopInset } =
      this.props;
    const isTruthVisible =
      (phaseType === 'truth' && phaseStatus !== 'enter') ||
      phaseType === 'roundScore' ||
      phaseType === 'totalScore';
    const children = [
      spacer('header', isHeaderVisible ? headerHeight : headerTopInset, true),
      spacer('intro', height / 3, phaseType === 'intro'),
      render(
        'question',
        () => (
          <GameQuestionView
            question={game.question!}
            sound={game.questionSound}
            image={game.questionImage}
            phaseType={phaseType}
          />
        ),
        phaseType === 'lie' ||
          phaseType === 'choose' ||
          phaseType === 'votes' ||
          phaseType === 'choice' ||
          phaseType === 'truth'
      ),
      spacer('truthSpacer', 100, phaseType === 'truth'),
      render(
        'dialogueText',
        () => <GameDialogueText game={game} phaseType={phaseType} phaseStatus={phaseStatus} />,
        true
      ),
      render('waitStatus', () => <GameWaitingStatus game={game} />, phaseType === 'wait'),
      // players
      render(
        'players',
        () => <GamePlayersView game={game} phaseType={phaseType} />,
        phaseType !== 'votes' && phaseType !== 'choice' && phaseType !== 'truth'
      ),
      // post-players
      render('introFooter', () => <GameIntroFooter game={game} />, phaseType === 'intro'),
      render(
        'lieInput',
        () => <GameLieInput game={game} phaseStatus={phaseStatus} />,
        phaseType === 'lie' && phaseStatus !== 'enter' && phaseStatus !== 'noInput'
      ),
      render(
        'chooseInput',
        () => <GameChooseInput game={game} phaseStatus={phaseStatus} />,
        phaseType === 'choose'
      ),
      render(
        'choiceResults',
        () => <GameChoiceResults game={game} phaseStatus={phaseStatus} phaseType={phaseType} />,
        phaseType === 'votes' || phaseType === 'choice'
      ),
      render(
        'truth',
        () => (
          <GameTruthView
            game={game}
            phaseStatus={phaseStatus}
            sound={game.truthSound}
            image={game.truthImage}
          />
        ),
        phaseType === 'truth' && phaseStatus !== 'enter'
      ),
    ];

    return (
      <ScrollView style={styles.container}>
        <Background image={(isTruthVisible ? game.truthImage : undefined) ?? game.questionImage} />
        {/* <Theme color={(isTruthVisible ? game.truthColor : undefined) ?? game.questionColor} /> */}
        <AnimationContainer style={styles.container} debugName="Game">
          {children}
        </AnimationContainer>
      </ScrollView>
    );
  }
}

export default observer(function GameScreen() {
  const { auth } = store;
  const game = useGame()!;
  const { isLoading, phase } = game;
  const { height } = useWindowDimensions();
  const { headerHeight, headerTopInset } = useHeaderHeight();

  if (auth.isLoading || isLoading) {
    return (
      <View style={styles.loading}>
        <Title>{`${t('Loading')} ...`}</Title>
      </View>
    );
  }

  const isHeaderVisible = phase.type === 'wait' || game.status === 'ended';
  return (
    <View style={styles.container}>
      <GameAnimationContainer
        height={height}
        game={game}
        phaseType={phase.type}
        phaseStatus={phase.status}
        isHeaderVisible={isHeaderVisible}
        headerHeight={headerHeight}
        headerTopInset={headerTopInset}
      />
      <GameStatusView game={game} />
      <GameHeaderContainer game={game} phase={phase} alwaysVisible={isHeaderVisible} />
      {game.isDemo ? <DemoGameSlider game={game} /> : undefined}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
