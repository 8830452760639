import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Title } from './Text';
import { ColorName, useTheme } from './theme/Theme';

export const Badge = (props: { color?: ColorName; style?: any; title?: string }) => {
  const { color = 'accent' } = props;
  const { c } = useTheme();
  return (
    <View style={[styles.container, { backgroundColor: c(color) }, props.style]}>
      {props.title ? (
        <Title small color={c(color, 'text')}>
          {props.title}
        </Title>
      ) : undefined}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 28,
    height: 28,
    borderRadius: 14,
    paddingHorizontal: 10,
  },
});
