import * as React from 'react';
import { StyleSheet, TextInput as RawTextInput } from 'react-native';

import { useShadowNode } from './animation';
import { useTheme, ColorName, Shadows } from './theme';

export const TextInput = React.forwardRef(
  (
    props: React.ComponentProps<typeof RawTextInput> & {
      color?: ColorName;
      outline?: boolean;
      focus?: boolean;
    },
    ref: any
  ) => {
    const { style, color, outline, focus, ...rest } = props;
    const { c } = useTheme();
    const isShadowNode = useShadowNode();
    const [focussed, setFocussed] = React.useState(props.autoFocus);
    const isFocussed = focus ?? focussed;
    return (
      <RawTextInput
        ref={ref}
        style={[
          styles.input,
          {
            backgroundColor: c('surface'),
            color: color ? c(color) : c('surface', 'text'),
            borderColor: color
              ? c(color)
              : c('surface', 'border', isFocussed ? 'pressed' : 'neutral'),
          },
          outline !== false ? styles.outline : undefined,
          outline !== false && isFocussed
            ? {
                ...Shadows.focus,
                shadowColor: color
                  ? c(color)
                  : c('surface', 'border', isFocussed ? 'pressed' : 'neutral'),
              }
            : undefined,
          style,
        ]}
        placeholderTextColor={c('surface', 'text', 'disabled')}
        {...rest}
        autoFocus={isShadowNode ? false : rest.autoFocus}
        onFocus={(event) => {
          setFocussed(() => true);
          props.onFocus?.(event);
        }}
        onBlur={(event) => {
          setFocussed(() => false);
          props.onBlur?.(event);
        }}
      />
    );
  }
);

const styles = StyleSheet.create({
  input: {
    fontSize: 20,
    fontWeight: '600',
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 12,
    outline: 'none',
  },
  outline: {
    borderWidth: 2,
    ...Shadows.regular,
  },
});
