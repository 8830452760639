import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { Button, useMenu } from '../components';
import { observer, store, getContext } from '../store';
import { t, useNavigation } from '../utils';

export default observer(function TemplatesButton(props: {
  editMode: boolean;
  onPress: (action: 'edit' | 'endEdit' | 'delete') => void;
}) {
  const { editMode, onPress } = props;
  const navigation = useNavigation();
  const menu = useMenu();
  if (editMode) {
    return (
      <View style={styles.container}>
        <Button title={t('Done')} header onPress={() => onPress('endEdit')} />
        <Button key="delete" icon="trash" header destructive onPress={() => onPress('delete')} />
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <Button
          header
          icon="menu"
          onPress={() =>
            menu.show([{ title: t('Select templates'), onPress: () => onPress('edit') }])
          }
        />
        <Button
          icon="plus"
          header
          onPress={async () => {
            const template = await store.myTemplates.add({
              title: t('New template'),
              ownerId: store.auth.userId,
              date: getContext().serverTimestamp(),
            });
            navigation.push('template', {
              template,
              isNew: true,
            });
          }}
        />
      </View>
    );
  }
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
});
