import { Entypo, Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { useTheme, ColorName } from './theme';

function IconOverlay(props: any) {
  const { c } = useTheme();
  return (
    <View>
      {props.children}
      <View
        style={[
          styles.imageOverlay,
          { backgroundColor: c(props.color, 'text'), borderColor: c(props.color) },
          props.style,
        ]}>
        <Entypo name={props.name} color={c(props.color)} size={15} />
      </View>
    </View>
  );
}

const icons = {
  back: (props: any) => <Entypo {...props} name="chevron-left" />,
  'chevron-right': Entypo,
  'arrow-down': Feather,
  filter: Feather,
  sort: MaterialCommunityIcons,
  menu: (props: any) => <Feather {...props} name="more-horizontal" />,
  plus: Entypo, // (props: any) => <Feather {...props} name="plus-circle" />,
  trash: Feather,
  edit: (props: any) => <MaterialCommunityIcons {...props} name="pencil" />,
  on: (props: any) => <Feather {...props} name="check-circle" />,
  off: (props: any) => <Feather {...props} name="circle" />,
  check: (props: any) => <MaterialCommunityIcons {...props} name="checkbox-marked" />,
  uncheck: (props: any) => <MaterialCommunityIcons {...props} name="checkbox-blank-outline" />,
  copy: Feather,
  share: (props: any) => <Feather {...props} name="share-2" />,
  upload: (props: any) => <MaterialCommunityIcons {...props} name="file-upload-outline" />,
  invite: (props: any) => <Feather {...props} name="user-plus" />,
  sound: (props: any) => <Feather {...props} name="volume-2" />,
  'sound-disabled': (props: any) => <Entypo {...props} name="sound-mute" />,
  play: (props: any) => <Feather {...props} name="play" />,
  pause: (props: any) => <Feather {...props} name="pause" />,
  stop: (props: any) => <Feather {...props} name="stop-circle" />,
  close: (props: any) => <MaterialCommunityIcons {...props} name="close" />,
  image: (props: any) => <Feather {...props} name="image" />,
  'add-image': (props: any) => (
    <IconOverlay {...props} name="plus">
      <Feather {...props} name="image" />
    </IconOverlay>
  ),
  'delete-image': (props: any) => (
    <IconOverlay {...props} name="minus">
      <Feather {...props} name="image" />
    </IconOverlay>
  ),
  record: (props: any) => <MaterialCommunityIcons {...props} name="microphone-outline" />,
  send: Feather,
};

export type IconName = keyof typeof icons;

type Props = {
  name: IconName;
  color: ColorName;
  size?: 'regular' | 'small' | 'large' | number;
};

export function Icon(props: Props) {
  const { name, color, size = 'regular' } = props;
  const { c } = useTheme();
  const Component = icons[name];
  let iconSize: number;
  switch (size) {
    case 'regular':
      iconSize = 26;
      break;
    case 'small':
      iconSize = 20;
      break;
    case 'large':
      iconSize = 32;
      break;
    default:
      iconSize = size;
  }
  return <Component name={name as any} size={iconSize} color={c(color)} />;
}

const styles = StyleSheet.create({
  imageOverlay: {
    position: 'absolute',
    top: -7,
    right: -7,
    width: 18,
    height: 18,
    borderRadius: 9,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
  },
  trashOverlay: {
    position: 'absolute',
    top: 6,
    right: 0,
    bottom: 0,
    left: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
