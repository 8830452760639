import * as React from 'react';
import { View } from 'react-native';

import { Menu, MenuRenderer } from './Menu';

type Props = React.ComponentProps<typeof View> & {
  children: any;
};

export const MenuContext = React.createContext<Menu>(new Menu());

export const MenuContainer = (props: Props) => {
  const [menu] = React.useState(() => new Menu());
  return (
    <MenuContext.Provider value={menu}>
      {props.children}
      <MenuRenderer menu={menu} />
    </MenuContext.Provider>
  );
};

export function useMenu() {
  return React.useContext(MenuContext);
}
