import { observable, runInAction } from 'mobx';

import { PublicTemplates } from './PublicTemplates';
import { Template } from './Template';
import { Templates } from './Templates';
import type { PublicTemplateListItemData, TemplateType } from './types';

export type PublicTemplateData = {
  id: string;
  data: PublicTemplateListItemData;
};

const MAX_PUBLIC_QUESTION_COUNT = 15;
const DEFAULT_PUBLIC_QUESTION_COUNT = 10;

// TODO serialize, remember selected template

export class SelectedTemplate {
  private readonly privateTemplates: Templates;
  private readonly publishedTemplates: PublicTemplates;
  private readonly mutablePrivateTemplate = observable.box<Template | undefined>();
  private readonly mutablePublishedTemplate = observable.box<PublicTemplateData | undefined>();

  constructor(config: { privateTemplates: Templates; publishedTemplates: PublicTemplates }) {
    this.privateTemplates = config.privateTemplates;
    this.publishedTemplates = config.publishedTemplates;
  }

  get type(): TemplateType {
    return this.privateTemplate ? 'private' : 'published';
  }

  get privateTemplate() {
    return this.mutablePrivateTemplate.get();
  }
  set privateTemplate(template: Template | undefined) {
    runInAction(() => {
      this.mutablePrivateTemplate.set(template);
      this.mutablePublishedTemplate.set(undefined);
    });
  }

  get publishedTemplate() {
    // TODO: select default public template
    return this.mutablePublishedTemplate.get();
  }
  set publishedTemplate(template: PublicTemplateData | undefined) {
    runInAction(() => {
      this.mutablePrivateTemplate.set(undefined);
      this.mutablePublishedTemplate.set(template);
    });
  }

  get id() {
    return this.publishedTemplate?.id ?? this.privateTemplate?.id;
  }
  get title() {
    return this.publishedTemplate?.data.t ?? this.privateTemplate?.title;
  }
  get questionCount() {
    return this.publishedTemplate?.data.q ?? this.privateTemplate?.questions.length ?? 0;
  }
  get defaultQuestionCount() {
    if (this.publishedTemplate) {
      return Math.min(this.publishedTemplate?.data.q ?? 0, DEFAULT_PUBLIC_QUESTION_COUNT);
    } else if (this.privateTemplate) {
      return (this.privateTemplate?.questions.length ?? 0) > 50
        ? DEFAULT_PUBLIC_QUESTION_COUNT
        : this.privateTemplate?.questions.length ?? 0;
    } else {
      return DEFAULT_PUBLIC_QUESTION_COUNT;
    }
  }
  get maxQuestionCount() {
    if (this.publishedTemplate) {
      return Math.min(this.publishedTemplate?.data.q ?? 0, MAX_PUBLIC_QUESTION_COUNT);
    } else if (this.privateTemplate) {
      return this.privateTemplate?.questions.length ?? 0;
    } else {
      return MAX_PUBLIC_QUESTION_COUNT;
    }
  }
  get date() {
    return this.publishedTemplate?.data.d ?? this.privateTemplate?.date;
  }
  get isLoading() {
    return this.publishedTemplates.isLoading || this.privateTemplates.isLoading;
  }
}
