import * as React from 'react';
import { StyleSheet } from 'react-native';

import {
  Title,
  PureComponent,
  AnimationContainer,
  Animation,
  AnimationConfig,
  useShadowNode,
} from '../components';
import { Game, GameClientPhaseType, GameClientPhaseStatus, observer } from '../store';
import { t } from '../utils';

type GameDialogueProps = {
  game: Game;
  phaseType: GameClientPhaseType;
  phaseStatus: GameClientPhaseStatus;
};

function getGameDialogueText(props: GameDialogueProps) {
  const { game, phaseType, phaseStatus } = props;
  switch (phaseType) {
    case 'wait':
    case 'intro':
      return game.players.length === 1
        ? t('$1 player', '1')
        : t('$1 players', game.players.length + '');
    case 'lie':
      switch (phaseStatus) {
        case 'enter':
          return game.players.length === 1
            ? t('$1 player', '1')
            : t('$1 players', game.players.length + '');
        case 'noInput':
          return `${t('Players are lying')} ...`;
        case 'waitForInput':
          return t('Enter your lie');
        case 'waitForInputCritical':
          return t('Hurry up!');
        case 'inputGiven':
          return t('Your lie has been submitted');
      }
      break;
    case 'choose':
      switch (phaseStatus) {
        case 'noInput':
          return t('Players are choosing') + '...';
        case 'waitForInput':
          return t('Find the truth');
        case 'waitForInputCritical':
          return t('Hurry up!');
        case 'inputGiven':
          return t('You have chosen');
      }
      break;
    case 'votes':
      switch (phaseStatus) {
        case 'enter':
          return t('The votes are in');
        case 'noInput':
        case 'exit':
          return t('These are the lies of');
      }
      break;
    case 'choice':
      return t('These are the lies of');
    case 'truth':
      return t('And the truth is!');
    case 'roundScore':
      return t(`Results for this round`);
    case 'totalScore':
      return game.status === 'ended' ? t('Game has ended, final results') : t('Results so far');
  }
  return '';
}

const animConfig: AnimationConfig = {
  enter: { opacity: 0, duration: 0.4, transform: [{ rotateX: '90deg' }] },
  exit: { opacity: 0, duration: 0.4, transform: [{ rotateX: '-90deg' }] },
  layout: { duration: 0.4 },
};

export default observer(function GameDialogueText(props: GameDialogueProps) {
  const xxlarge = props.phaseType === 'intro';
  const text = getGameDialogueText(props);
  if (useShadowNode()) {
    return (
      <Title large={!xxlarge} xxlarge={xxlarge} style={styles.container}>
        {text}
      </Title>
    );
  }
  const isSplitWords = props.phaseType === 'wait' || props.phaseType === 'intro';
  const texts = isSplitWords
    ? text.split(' ').map((text, idx) => (idx ? ` ${text}` : text))
    : [text];
  return (
    <PureComponent props={[xxlarge, text]}>
      {() => (
        <AnimationContainer
          style={isSplitWords ? styles.containerWords : styles.container}
          debugName="DialogueText"
          debug={false}>
          {texts.map((text) => (
            <Animation key={text} id={text} config={animConfig}>
              <Title large={!xxlarge} xxlarge={xxlarge} center>
                {text}
              </Title>
            </Animation>
          ))}
        </AnimationContainer>
      )}
    </PureComponent>
  );
});

const styles = StyleSheet.create({
  container: {
    margin: 16,
    marginTop: 20,
  },
  containerWords: {
    margin: 16,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
