import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import * as React from 'react';
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';

import {
  TextInput,
  Button,
  Spacer,
  Title,
  useTheme,
  Shadows,
  Styles,
  ColorName,
  Image,
} from '../components';
import { SoundRecording, Sounds } from '../sound';
import { t, is } from '../utils';

export default function TemplateQuestionInput(
  props: Partial<React.ComponentProps<typeof TextInput>> & {
    label?: string;
    sound?: string;
    image?: string;
    color?: ColorName;
    onChangeSound?: (sound?: string) => any;
    onChangeImage?: (image?: string) => any;
    onFocus?: () => any;
  }
) {
  const {
    style,
    image,
    sound,
    onChangeSound,
    onChangeImage,
    label,
    color,
    focus,
    onFocus,
    ...otherProps
  } = props;
  const editable = (props.editable ?? true) && props.value;
  const [recording, setRecording] = React.useState<SoundRecording | undefined>();
  const { c } = useTheme();

  // In case the the recording was stopped, unload it explicitely
  React.useEffect(
    () => () => {
      recording?.getStatusAsync().then((status) => {
        if (status.isRecording) {
          recording.stopAndUnloadAsync();
        }
      });
    },
    [recording]
  );

  /*
  TODO request permissions on native
  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []); */

  function grabFocus() {
    if (focus) return;
    onFocus?.();
  }

  async function selectImage() {
    grabFocus();
    if (image) {
      return onChangeImage?.('');
    }
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.canceled) {
      const uri = result.assets[0].uri;
      onChangeImage?.(uri);
    }
  }

  async function selectSound() {
    grabFocus();
    const result = await DocumentPicker.getDocumentAsync({
      type: 'audio/*',
    });
    if (!result.canceled) {
      const uri = result.assets[0].uri;
      onChangeSound?.(uri);
      Sounds.playUri(uri, true);
    }
  }

  async function recordSound() {
    grabFocus();
    if (recording) {
      await recording.stopAndUnloadAsync();
      setRecording(() => undefined);
      const uri = recording.getURI()!;
      onChangeSound?.(uri);
      Sounds.playUri(uri);
    } else if (sound) {
      Sounds.playUri(sound, true);
    } else {
      const newRecording = await SoundRecording.createAsync();
      setRecording(() => newRecording);
    }
  }

  return (
    <TouchableWithoutFeedback onPress={grabFocus}>
      <View style={styles.container}>
        {label ? <Spacer small /> : undefined}
        {label ? <Title small>{label}</Title> : undefined}
        {label ? <Spacer small /> : undefined}
        <View
          style={[
            styles.content,
            {
              backgroundColor: c('surface'),
              borderColor: color ? c(color) : c('surface', 'border', focus ? 'pressed' : 'neutral'),
            },
            focus
              ? {
                  ...Shadows.focus,
                  shadowColor: color
                    ? c(color)
                    : c('surface', 'border', focus ? 'pressed' : 'neutral'),
                }
              : undefined,
          ]}>
          {image ? (
            <View style={styles.imageContainer}>
              <Image style={Styles.flex} source={image} resizeMode="cover" />
              <View style={styles.imageOverlay} />
            </View>
          ) : undefined}
          <View>
            {props.editable === false ? (
              <Title style={[styles.input, style]} color={color}>
                {otherProps.value ?? ''}
              </Title>
            ) : (
              <TextInput
                style={[styles.input, style]}
                outline={false}
                focus={focus}
                {...otherProps}
                onFocus={grabFocus}
              />
            )}
            <View style={styles.audioContainer}>
              {editable || sound ? (
                <Button
                  type="text"
                  icon={recording ? 'stop' : sound ? 'play' : 'record'}
                  color={color ?? c('surface', 'text')}
                  onPress={recordSound}
                />
              ) : undefined}
              {recording && editable ? (
                <Title small color={color ?? c('surface', 'text')}>{`${t('recording')}...`}</Title>
              ) : undefined}
              {!recording && editable && !sound && (is('native') || !is('mobile')) ? (
                <Button
                  header
                  color={color ?? c('surface', 'text')}
                  icon="upload"
                  onPress={() => {
                    selectSound();
                  }}
                />
              ) : undefined}
              {!recording && sound && editable ? (
                <Button
                  header
                  color={color ?? c('surface', 'text')}
                  icon="trash"
                  onPress={() => onChangeSound?.('')}
                />
              ) : undefined}
              <Spacer flex />
              {editable || image ? (
                <Button
                  header
                  color={color ?? c('surface', 'text')}
                  icon={image ? 'delete-image' : 'add-image'}
                  onPress={() => {
                    selectImage();
                  }}
                />
              ) : undefined}
            </View>
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginHorizontal: 16,
    marginBottom: 8,
  },
  content: {
    borderRadius: 8,
    paddingVertical: 4,
    borderWidth: 2,
    ...Shadows.regular,
  },
  imageContainer: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8,
    overflow: 'hidden',
  },
  imageOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#ffffffdd',
  },
  input: {
    backgroundColor: 'transparent',
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginHorizontal: 8,
    marginTop: 4,
  },
  audioContainer: {
    flexDirection: 'row',
    marginLeft: 4,
    alignItems: 'center',
  },
});
