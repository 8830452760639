import * as React from 'react';

import { List } from '../components';
import { store, observer, TemplateRequests, TemplateRequest } from '../store';
import { t, useNavigation, useTemplate, useRoute } from '../utils';
import TemplateRequestListItem from './TemplateRequestListItem';

export default observer(function TemplateRequestsScreen() {
  const route = useRoute();
  const navigation = useNavigation();
  const template = useTemplate();
  const { filter } = route.params;
  let requests: TemplateRequests | undefined;
  let title: string = '';
  switch (filter) {
    case 'pending':
      title = t('Pending requests');
      requests = template?.requests ?? store.adminPendingRequests;
      break;
    case 'history':
      title = t('Request history');
      requests = template?.requests ?? store.adminRequestHistory;
      break;
  }

  React.useEffect(() => {
    navigation.setOptions({ title });
  }, [title]);

  return (
    <List<TemplateRequest>
      placeholder={t('No requests')}
      dataSource={requests}
      renderItem={(request) => <TemplateRequestListItem templateRequest={request} />}
    />
  );
});
