import { useSafeAreaInsets } from 'react-native-safe-area-context';

const Sizes = {
  headerHeight: 50,
};

export function useHeaderHeight() {
  const insets = useSafeAreaInsets();
  return {
    headerHeight: insets.top + Sizes.headerHeight,
    headerTopInset: insets.top,
  };
}
