import { getContext, IContext } from 'firestorter';

import { DemoGame } from './DemoGame';

const context = getContext();
export const demoGameContext: IContext = {
  ...context,
  // @ts-ignore Type '(path: string) => DemoGame | DocumentReference<DocumentData>' is not assignable to type '(path: string) => DocumentReference<DocumentData>'.
  doc: (path: string) => {
    if (path.startsWith('games/demo')) {
      return new DemoGame(path);
    }
    return context.doc(path);
  },
  // @ts-ignore The expected type comes from property 'onSnapshot' which is declared here on type 'IContext'
  onSnapshot: (reference: any, callback: any, errorCallback: any) => {
    if (reference instanceof DemoGame) {
      return reference.onSnapshot(callback, errorCallback);
    }
    return context.onSnapshot(reference, callback);
  },
  updateDoc: (reference: any, fields: any) => {
    if (reference instanceof DemoGame) {
      reference.update(fields);
    }
    return context.updateDoc(reference, fields);
  },
};
