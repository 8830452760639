import * as React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { Title, Badge, Spacer, Icon, useTheme } from '../components';
import { observer, Game } from '../store';
import { t, useNavigation } from '../utils';

export default observer(function ActiveGame(props: { game: Game }) {
  const { game } = props;
  const { title, players } = game;
  const navigation = useNavigation();
  const { c } = useTheme();
  const textColor = c('accent', 'text');
  return (
    <TouchableOpacity
      style={{ backgroundColor: c('accent') }}
      onPress={() => navigation.push('game', { game: game.id })}>
      {/* <ProgressBar
        small
        color='surface'
        pausedValue={0.0} // TODO
        startTime={0}
        endTime={10}
      /> */}
      <View style={styles.content}>
        <Badge title={t('Live')} color="surface" />
        <Spacer />
        <Title flex color={textColor}>
          {title}
        </Title>
        <Title small color={textColor}>
          {players.length === 1 ? t('$1 player', '1') : t('$1 players', players.length + '')}
        </Title>
        <Icon name="chevron-right" color={textColor} />
      </View>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  content: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
