import * as React from 'react';

import { ColorName, useTheme, ColorState } from '../components';
import { GameClientChoice } from '../store';
import { Cell, CellBackground, CellText, CellBorder } from './cell';

export default function GameChoiceItem(
  props: React.ComponentProps<typeof Cell> & {
    choice: GameClientChoice;
    selected?: boolean;
    firstCol?: boolean;
  }
) {
  const { choice, selected, firstCol, ...cellProps } = props;
  const { width, height } = cellProps;
  const { c } = useTheme();
  const color: ColorName = selected ? 'surface' : 'accent';
  // const colorState: ColorState = selected == null ? 'neutral' : selected ? 'neutral' : 'disabled';
  const colorState: ColorState = 'neutral';
  return (
    <Cell {...cellProps} debugName="PlayerItem" clip>
      <CellBackground width={width} height={height} color={c(color, 'back', colorState)} />
      <CellBorder width={width} height={height} edge="top" color={c(color, 'text', colorState)} />
      <CellBorder
        width={width}
        height={height}
        edge="left"
        color={c(color, 'text', colorState)}
        hidden={firstCol}
      />
      <CellBorder
        width={width}
        height={height}
        edge="right"
        color={c(color, 'text', colorState)}
        hidden
      />
      <CellBorder
        width={width}
        height={height}
        edge="bottom"
        color={c(color, 'text', colorState)}
        hidden
      />
      <CellText
        width={width}
        height={height}
        horizontalAlign={0.5}
        color={c(color, 'text', colorState)}
        debugName="ChoiceText">
        {choice.text}
      </CellText>
    </Cell>
  );
}
