import { Document } from 'firestorter';

import { TemplateQuestions } from './TemplateQuestions';
import { TemplateRequests } from './TemplateRequests';
import { functions, httpsCallable, query, where, orderBy, limit } from './firebase';
import { asyncAction, Auth } from './framework';
import { ITemplate } from './interfaces';
import type { TemplateData } from './types';

export class Template extends Document<TemplateData> implements ITemplate {
  public readonly questions = new TemplateQuestions(this, 'questions');

  public readonly requests = new TemplateRequests('templateRequests', {
    query: (ref) =>
      Auth.getInstance().userId && this.id
        ? query(
            ref,
            where('ownerId', '==', Auth.getInstance().userId),
            where('templateId', '==', this.id),
            orderBy('date', 'desc'),
            limit(30)
          )
        : null,
  });

  get title() {
    return this.data.title;
  }

  get date() {
    return this.data.date;
  }

  deletePermanently = asyncAction(async () => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'template',
      action: 'delete',
      templateId: this.id,
    });
  });

  publish = asyncAction(async (config: { agreeToTerms: boolean }) => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'template',
      action: 'publish',
      templateId: this.id,
      agreeToTerms: config.agreeToTerms,
    });
  });

  transfer = asyncAction(async () => {
    const { data } = await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'template',
      action: 'transfer',
      templateId: this.id,
    });
    return (data as any).token as string;
  });

  navigateToQuestion(navigation: any, questionId: string) {
    navigation.push('publishQuestion', {
      template: this,
      questionId,
    });
  }
}
