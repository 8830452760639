let audioContext: AudioContext;
function getAudioContext() {
  if (!audioContext) {
    // @ts-ignore
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    audioContext = new AudioContext();
  }

  if (audioContext.state === 'suspended') {
    audioContext.resume();
  }

  return audioContext;
}

export class Sound {
  private readonly buffer: AudioBuffer;

  constructor(buffer: AudioBuffer) {
    this.buffer = buffer;
  }

  async play(muted?: boolean) {
    const sourceNode = getAudioContext().createBufferSource();
    sourceNode.buffer = this.buffer;
    const gainNode = getAudioContext().createGain();
    gainNode.gain.value = muted ? 0 : 1;
    sourceNode.connect(gainNode);
    gainNode.connect(getAudioContext().destination);
    sourceNode.start(0);
    return new Promise((resolve) => (sourceNode.onended = () => resolve(true)));
  }

  async unload() {
    // TODO
  }

  static async load(uri: string) {
    const audioContext = getAudioContext();
    const response = await fetch(uri);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer: AudioBuffer = await new Promise((resolve, reject) => {
      // Promise result doesn't work in Safari, use fallback to callbacks
      audioContext.decodeAudioData(arrayBuffer, resolve, (err) => {
        console.log('Failed to decode audio', err);
        reject(err);
      });
    });
    return new Sound(audioBuffer);
  }
}
