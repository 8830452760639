import * as React from 'react';

import { PureComponent, useTheme, ColorName } from '../components';
import { Sounds } from '../sound';
import { observer, GameClientPhaseType, Game } from '../store';
import { Cell, CellText, CellBackground, CellProgress, CellBorder } from './cell';

export default observer(function GameQuestionView(
  props: React.ComponentProps<typeof Cell> & {
    game: Game;
    question: string;
    phaseType: GameClientPhaseType;
    sound?: string;
    image?: string;
    color?: ColorName;
  }
) {
  const { game, question, sound, phaseType, image, color = 'base', ...cellProps } = props;
  const { c } = useTheme();
  React.useEffect(() => {
    if (sound && phaseType === 'lie') {
      Sounds.playUri(sound);
    }
  }, [sound, phaseType === 'lie']);
  const { phase } = game;
  const { startTime, endTime, pausedValue } = phase.progress;
  const { width, height } = cellProps;
  const fixedValue = phaseType === 'lie' || phaseType === 'choose' ? pausedValue : 0;
  const bordersHidden = phaseType === 'lie' && phase.status === 'enter';
  return (
    <PureComponent props={[width, height, startTime, endTime, fixedValue, c, color, bordersHidden]}>
      {() => {
        const now = Date.now();
        const totalTime = endTime - startTime;
        const startValue = now >= endTime ? 1 : Math.max((now - startTime) / totalTime, 0);
        const duration = ((1 - startValue) * totalTime) / 1000;
        return (
          <CellProgress
            {...cellProps}
            startValue={fixedValue ?? startValue}
            duration={fixedValue != null ? 0 : duration}
            paused={fixedValue != null}
            debugName="GameQuestion">
            {({ keyframes, duration, width, height, isFilled }) => {
              const backColor = c(color, isFilled ? 'text' : 'back');
              const borderColor = isFilled
                ? // @ts-ignore
                  c(color, 'back') === 'transparent'
                  ? c('accent')
                  : c(color, 'text')
                : c(color, 'text');
              const textColor = borderColor;
              return (
                <Cell keyframes={keyframes} duration={duration} clip>
                  <CellBackground width={width} height={height} color={backColor} />
                  <CellBorder
                    width={width}
                    height={height}
                    edge="left"
                    color={borderColor}
                    hidden={bordersHidden}
                  />
                  <CellBorder
                    width={width}
                    height={height}
                    edge="top"
                    color={borderColor}
                    hidden={bordersHidden}
                  />
                  <CellBorder
                    width={width}
                    height={height}
                    edge="bottom"
                    color={borderColor}
                    hidden={bordersHidden}
                  />
                  <CellBorder
                    width={width}
                    height={height}
                    edge="right"
                    color={borderColor}
                    hidden={bordersHidden}
                  />
                  <CellText width={width} height={height} color={textColor}>
                    {question ?? ''}
                  </CellText>
                </Cell>
              );
            }}
          </CellProgress>
        );
      }}
    </PureComponent>
  );
});
