import * as React from 'react';

import { PureComponent } from '../components';
import { Game, observer, GameClientPhaseType, GameClientPhaseStatus } from '../store';
import GameChoiceItem from './GameChoiceItem';
import { CellContainer, CellBorder, Cell, CellBackground, layoutChoices } from './cell';

export default observer(function GameChoicesView(
  props: Omit<React.ComponentProps<typeof CellContainer>, 'layout'> & {
    game: Game;
    phaseType: GameClientPhaseType;
    phaseStatus: GameClientPhaseStatus;
    highlighted?: string;
    maximized?: boolean;
  }
) {
  const { game, phaseType, phaseStatus, highlighted, maximized, ...cellProps } = props;
  const choices = game.choices;
  let selected: string | undefined;

  return (
    <PureComponent
      props={[choices, selected, highlighted, maximized, cellProps.width, cellProps.height]}>
      {() => (
        <CellContainer {...cellProps} debugName="GameChoices">
          <Cell key="placeholder" clip>
            <CellBackground width={cellProps.width} height={cellProps.height} color="base" />
            <CellBorder width={cellProps.width} height={cellProps.height} edge="left" />
            <CellBorder width={cellProps.width} height={cellProps.height} edge="top" />
          </Cell>
          {layoutChoices(
            {
              width: cellProps.width,
              height: cellProps.height,
            },
            choices.map((choice, idx) => (
              <GameChoiceItem
                key={choice.text}
                enter="slideRight"
                exit="slideLeft"
                choice={choice}
                // selected={selected != null ? selected === choice.text : undefined}
              />
            )),
            highlighted,
            maximized
          )}
        </CellContainer>
      )}
    </PureComponent>
  );
});
