import * as React from 'react';

import { List, useTheme } from '../components';
import { observer, store } from '../store';
import { t, useNavigation } from '../utils';

export default observer(function CreateGameScreen() {
  const { selectedTemplate } = store;
  const { c } = useTheme();
  const navigation = useNavigation();
  const [editedTitle, setEditedTitle] = React.useState('');
  const [editedQuestionCount, setEditedQuestionCount] = React.useState<
    number | Error | undefined
  >();
  const { isLoading, id } = selectedTemplate;
  const title = (editedTitle || selectedTemplate.title) ?? '';
  const questionCount = editedQuestionCount ?? selectedTemplate.defaultQuestionCount;

  return (
    <List>
      <List.Item
        label={t('Template')}
        loading={isLoading}
        title={isLoading ? `${t('Loading')}...` : selectedTemplate.title ?? t('Select template')}
        color={isLoading || !selectedTemplate.title ? c('surface', 'text', 'disabled') : undefined}
        value={
          isLoading
            ? undefined
            : selectedTemplate.id
            ? selectedTemplate.questionCount === 1
              ? t('$1 question', '1')
              : t('$1 questions', selectedTemplate.questionCount + '')
            : undefined
        }
        onPress={() => {
          navigation.push('select', {
            templateId: id,
          });
        }}
      />
      <List.TextInput
        label={t('Name')}
        editable={!!selectedTemplate.id}
        autoFocus
        placeholder={title}
        value={editedTitle}
        onChangeText={setEditedTitle}
      />
      <List.NumberInput
        label={`${t('Number of questions')} (max ${selectedTemplate.maxQuestionCount})`}
        editable={!!selectedTemplate.id}
        placeholder={`${questionCount}`}
        value={editedQuestionCount}
        minValue={1}
        maxValue={selectedTemplate.maxQuestionCount}
        onChangeValue={setEditedQuestionCount}
      />
      <List.Button
        title={t('Create game')}
        disabled={!selectedTemplate.id || !title || editedQuestionCount instanceof Error}
        loading={store.createGame.inProgress}
        onPress={async () => {
          const game = await store.createGame({
            title,
            templateId: selectedTemplate.id!,
            templateType: selectedTemplate.type,
            autoJoinName: store.auth.userName!,
            settings: {
              questionCount: questionCount as number,
              // minPlayers: editedMinPlayers,
              // maxPlayers: editedMaxPlayers,
            },
          });
          navigation.goBack();
          navigation.goBack();
          navigation.push('game', { game });
        }}
      />
    </List>
  );
});
