import * as React from 'react';

import { ProgressBar } from '../components';
import { Sounds } from '../sound';
import { Game, GameClientPhase } from '../store';

export default function GameProgressBar(props: { game: Game; phase: GameClientPhase }) {
  const { phase } = props;
  const { type, progress, status } = phase;
  const isAboutToExpire = status === 'waitForInputCritical';
  React.useEffect(() => {
    if (isAboutToExpire) {
      Sounds.play('drumroll');
    }
  }, [isAboutToExpire]);
  return type === 'lie' || type === 'choose' ? <ProgressBar {...progress} color="accent" /> : null;
}
