import * as React from 'react';
import { StyleSheet } from 'react-native';

import { Button, List, Background, useMenu } from '../components';
import { store, observer, getContext } from '../store';
import { t, useRoute, useTemplate, useNavigation } from '../utils';
import TemplateQuestionInput from './TemplateQuestionInput';

function normalizeQuestion(text: string): string {
  // text = text.replaceAll('  ', ' ');
  for (let i = 3; i < 10; i++) {
    text = text.replace(` ${'.'.repeat(i)} `, ` _____ `);
    text = text.replace(` ${'_'.repeat(i)} `, ` _____ `);
  }
  text = text.trimLeft();
  return text;
}

export default observer(function TemplateQuestionEditScreen() {
  const navigation = useNavigation();
  const { questionId, isNew } = useRoute().params;
  const menu = useMenu();
  const template = useTemplate()!;
  const { q, a, f, qs, qi, as, ai } = template.questions.get(questionId) ?? {};
  const [editedQuestion, setEditedQuestion] = React.useState<string | undefined>();
  const [editedAnswer, setEditedAnswer] = React.useState<string | undefined>();
  const [editedFakeAnswers, setEditedFakeAnswers] = React.useState<string | undefined>();
  const [editedQuestionSound, setEditedQuestionSound] = React.useState<string | undefined>();
  const [editedQuestionImage, setEditedQuestionImage] = React.useState<string | undefined>();
  const [editedAnswerSound, setEditedAnswerSound] = React.useState<string | undefined>();
  const [editedAnswerImage, setEditedAnswerImage] = React.useState<string | undefined>();
  const [focus, setFocus] = React.useState('question');

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          header
          icon="menu"
          onPress={() =>
            menu.show([
              {
                title: t('Delete question'),
                destructive: true,
                onPress: async () => {
                  await template.update({
                    [`questions.${questionId}`]: getContext().deleteField(),
                  });
                  navigation.goBack();
                },
              },
            ])
          }
        />
      ),
    });
  }, [questionId]);

  return (
    <List loading={template.isLoading}>
      <Background
        image={
          focus === 'question'
            ? editedQuestionImage ?? qi
            : focus === 'answer'
            ? editedAnswerImage ?? ai
            : undefined
        }
      />
      <TemplateQuestionInput
        style={styles.questionInput}
        autoFocus={isNew}
        placeholder={t('Question')}
        multiline
        maxLength={500}
        focus={focus === 'question'}
        value={editedQuestion ?? q ?? ''}
        sound={editedQuestionSound ?? qs}
        image={editedQuestionImage ?? qi}
        onChangeText={(text: string) => setEditedQuestion(normalizeQuestion(text))}
        onChangeSound={(sound?: string) => setEditedQuestionSound(sound)}
        onChangeImage={(image?: string) => setEditedQuestionImage(image)}
        onFocus={() => setFocus(() => 'question')}
      />
      <TemplateQuestionInput
        label={t('Answer')}
        autoCapitalize="characters"
        focus={focus === 'answer'}
        value={editedAnswer ?? a ?? ''}
        sound={editedAnswerSound ?? as}
        image={editedAnswerImage ?? ai}
        onChangeText={(text: string) => setEditedAnswer(text)}
        onChangeSound={(sound?: string) => setEditedAnswerSound(sound)}
        onChangeImage={(image?: string) => setEditedAnswerImage(image)}
        onFocus={() => setFocus(() => 'answer')}
      />
      <List.TextInput
        label={t('Fake answers')}
        style={styles.fakeAnswersInput}
        placeholder={t('Dog, Cat, House').toUpperCase()}
        autoCapitalize="characters"
        multiline
        maxLength={200}
        focus={focus === 'fakeAnswers'}
        value={editedFakeAnswers ?? f?.join(', ') ?? ''}
        onChangeText={(text: string) => setEditedFakeAnswers(text)}
        onFocus={() => setFocus(() => 'fakeAnswers')}
      />
      {editedQuestion !== undefined ||
      editedAnswer !== undefined ||
      editedFakeAnswers !== undefined ||
      editedQuestionSound !== undefined ||
      editedQuestionImage !== undefined ||
      editedAnswerSound !== undefined ||
      editedAnswerImage !== undefined ? (
        <List.Button
          title={t('Save question')}
          disabled={!(editedQuestion ?? q) || !(editedAnswer ?? a)}
          onPress={async () => {
            // Upload any assets if needed
            const objectUrls = [
              ...(editedQuestionSound ? [editedQuestionSound] : []),
              ...(editedQuestionImage ? [editedQuestionImage] : []),
              ...(editedAnswerSound ? [editedAnswerSound] : []),
              ...(editedAnswerImage ? [editedAnswerImage] : []),
            ];
            const assetUrls = await store.uploadAssets(objectUrls);

            // Update template
            function f(field: string, val: string | undefined, getValueFn?: (val: string) => any) {
              return val === undefined
                ? {}
                : { [`questions.${questionId}.${field}`]: getValueFn?.(val) ?? val };
            }
            await template.update({
              ...f('q', editedQuestion),
              ...f('a', editedAnswer, (v) => v.toUpperCase()),
              ...f('f', editedFakeAnswers, (v) => v.split(',').map((f) => f.trim().toUpperCase())),
              ...f('qs', editedQuestionSound, (v) => (v ? assetUrls[objectUrls.indexOf(v)] : '')),
              ...f('qi', editedQuestionImage, (v) => (v ? assetUrls[objectUrls.indexOf(v)] : '')),
              ...f('as', editedAnswerSound, (v) => (v ? assetUrls[objectUrls.indexOf(v)] : '')),
              ...f('ai', editedAnswerImage, (v) => (v ? assetUrls[objectUrls.indexOf(v)] : '')),
            });

            // Reset changes
            setEditedQuestion(() => undefined);
            setEditedAnswer(() => undefined);
            setEditedFakeAnswers(() => undefined);
            setEditedQuestionSound(() => undefined);
            setEditedQuestionImage(() => undefined);
            setEditedAnswerSound(() => undefined);
            setEditedAnswerImage(() => undefined);
            navigation.goBack();
          }}
        />
      ) : undefined}
    </List>
  );
});

const styles = StyleSheet.create({
  questionInput: {
    height: 200,
  },
  fakeAnswersInput: {
    height: 90,
  },
});
