import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Spacer } from '../Spacer';
import { Title } from '../Text';
import { TextInput } from '../TextInput';

export const ListTextInput = React.forwardRef(
  (
    props: React.ComponentProps<typeof TextInput> & {
      label?: string;
    },
    ref: any
  ) => {
    const { label, ...otherProps } = props;
    return (
      <View style={styles.container}>
        {label ? <Spacer small /> : undefined}
        {label ? <Title small>{label}</Title> : undefined}
        {label ? <Spacer small /> : undefined}
        <TextInput ref={ref} {...otherProps} />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 8,
  },
});
