import * as React from 'react';

import { List } from '../components';
import { observer, ITemplate } from '../store';
import { useNavigation, t } from '../utils';

export default observer(function TemplateListItem(
  props: Partial<React.ComponentProps<typeof List.Item>> & {
    template: ITemplate;
  }
) {
  const { template, onPress, ...otherProps } = props;
  const { questions, title } = template;
  const navigation = useNavigation();
  return (
    <List.Item
      title={title}
      value={
        questions.length === 1 ? t('$1 question', '1') : t('$1 questions', questions.length + '')
      }
      onPress={onPress === undefined ? () => navigation.push('template', { template }) : onPress}
      {...otherProps}
    />
  );
});
