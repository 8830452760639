import * as React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';

import {
  AnimationContainer,
  Animation,
  AnimationConfig,
  Spacer,
  PureComponent,
  useShadowNode,
} from '../components';
import { Game, observer, GamePlayer, GameClientPhaseType } from '../store';
import GamePlayerItem, { GamePlayerValueType } from './GamePlayerItem';

const animationConfig: AnimationConfig = {
  enter: {
    opacity: 0,
    duration: 0.4,
    transform: [{ translateY: 100 }, { scale: 0.2 }],
  },
  exit: {
    opacity: 0,
    duration: 1,
  },
  layout: {
    duration: 0.4,
  },
};

const badgeWidth = 40;

export default observer(function GamePlayersView(props: {
  game: Game;
  phaseType: GameClientPhaseType;
}) {
  const { game, phaseType } = props;
  const { players } = game;
  const { width } = useWindowDimensions();
  const isSoundAllowed = useShadowNode()!;
  let players1: GamePlayer[];
  let players2: GamePlayer[] | undefined;
  let isBadge = false;
  let players1Sound = false;
  let players2Sound = false;
  let marginLeft = Math.min(Math.floor((width - 32 - 100) / players.length - badgeWidth), -20);
  let valueType: GamePlayerValueType | undefined;
  switch (phaseType) {
    case 'wait':
      isBadge = false;
      valueType = 'ready';
      players1 = players.toArray().sort((a, b) => b.time - a.time);
      players1Sound = isSoundAllowed;
      break;
    case 'intro':
      isBadge = true;
      marginLeft = Math.min(Math.floor((width - 32) / players.length - badgeWidth), 0);
      players1 = players.toArray().sort((a, b) => a.time - b.time);
      break;
    case 'lie':
      isBadge = true;
      players1 = players
        .toArray()
        .sort((a, b) => a.time - b.time)
        .filter((p) => !p.hasLied);
      players2 = players
        .toArray()
        .sort((a, b) => a.time - b.time)
        .filter((p) => p.hasLied);
      players2Sound = isSoundAllowed;
      break;
    case 'choose':
      isBadge = true;
      players1 = players
        .toArray()
        .sort((a, b) => a.time - b.time)
        .filter((p) => !p.hasChosen);
      players2 = players
        .toArray()
        .sort((a, b) => a.time - b.time)
        .filter((p) => p.hasChosen);
      players2Sound = isSoundAllowed;
      break;
    case 'roundScore':
      isBadge = false;
      valueType = 'roundScore';
      players1 = players
        .toArray()
        .sort((a, b) => (a.score === b.score ? b.time - a.time : b.score - a.score));
      break;
    case 'totalScore':
      isBadge = false;
      valueType = 'totalScore';
      players1 = players
        .toArray()
        .sort((a, b) =>
          a.totalScore === b.totalScore ? b.time - a.time : b.totalScore - a.totalScore
        );
      break;
    default:
      players1 = players.toArray();
      break;
  }
  const playerStyle = isBadge ? { marginLeft } : undefined;

  return (
    <PureComponent
      props={[isBadge, marginLeft, players1, players2, valueType, players1Sound, players2Sound]}>
      {() => (
        <AnimationContainer
          debugName="Players"
          style={
            isBadge
              ? [
                  styles.badgeContainer,
                  marginLeft >= 0 ? styles.justifyContent : { marginLeft: 16 - marginLeft },
                ]
              : styles.listContainer
          }>
          {players2?.map((player) => (
            <Animation key={player.id} id={player.id} config={animationConfig} style={playerStyle}>
              <GamePlayerItem
                player={player}
                badge={isBadge}
                valueType={valueType}
                playSound={players2Sound}
              />
            </Animation>
          ))}
          {players2 && isBadge ? <Spacer flex /> : undefined}
          {players1.map((player) => (
            <Animation key={player.id} id={player.id} config={animationConfig} style={playerStyle}>
              <GamePlayerItem
                player={player}
                badge={isBadge}
                valueType={valueType}
                playSound={players1Sound}
              />
            </Animation>
          ))}
        </AnimationContainer>
      )}
    </PureComponent>
  );
});

const styles = StyleSheet.create({
  listContainer: {
    marginHorizontal: 16,
    marginBottom: 16,
    flexDirection: 'column',
  },
  badgeContainer: {
    marginHorizontal: 16,
    marginBottom: 16,
    flexDirection: 'row-reverse',
  },
  justifyContent: {
    justifyContent: 'space-around',
  },
});
