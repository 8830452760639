import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { PureComponent } from '../PureComponent';
import { useTheme } from '../theme';
import type { ProgressBarProps } from './types';

export function ProgressBar(props: ProgressBarProps) {
  const { startTime, endTime, pausedValue, style, small, color = 'accent' } = props;
  const { c } = useTheme();
  const [width, setWidth] = React.useState(0);
  return (
    <View
      style={[
        styles.container,
        small ? styles.small : undefined,
        { backgroundColor: c(color, 'text') },
        style,
      ]}
      onLayout={(event) => setWidth(() => event.nativeEvent.layout.width)}>
      {width ? (
        <PureComponent props={[width, startTime, endTime, pausedValue]}>
          {() => {
            const now = Date.now();
            const totalTime = endTime - startTime;
            const value = now >= endTime ? 1 : Math.max((now - startTime) / totalTime, 0);
            const duration = ((1 - value) * totalTime) / 1000;
            return (
              <View
                style={[
                  {
                    ...StyleSheet.absoluteFillObject,
                    backgroundColor: c(color),
                  },
                  pausedValue !== undefined
                    ? {
                        transform: [{ translateX: -width + width * pausedValue }],
                      }
                    : {
                        // @ts-ignore Web-transitions
                        animationDuration: `${duration}s`,
                        animationTimingFunction: 'linear',
                        animationKeyframes: [
                          {
                            from: { transform: [{ translateX: -width + width * value }] },
                            to: { transform: [{ translateX: 0 }] },
                          },
                        ],
                      },
                ]}
              />
            );
          }}
        </PureComponent>
      ) : undefined}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    height: 5,
  },
  small: {
    height: 2,
  },
});
