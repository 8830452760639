import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { Title } from '../Text';
import { Styles, useTheme } from '../theme';

export type BetaBadgeProps = {
  id?: string;
  hidden?: boolean;
};

let nextItemId = 1;

class BetaBadgeData {
  private setItems(_fn: (items: BetaBadgeProps[]) => BetaBadgeProps[]) {}

  push(props: BetaBadgeProps) {
    const item = props.id ? props : { ...props, id: `__betaBadge${nextItemId++}` };
    this.setItems((items) => [item, ...items]);
    return item.id!;
  }

  pop(id: string) {
    this.setItems((items) => items.filter((t) => t.id !== id));
  }
}

const BetaBadgeContext = React.createContext<BetaBadgeData | undefined>(undefined);

function BetaBadgeView() {
  const context = React.useContext(BetaBadgeContext);
  const [items, setItems] = React.useState<BetaBadgeProps[]>([]);
  const { c } = useTheme();
  // @ts-expect-error Property 'setItems' is private and only accessible within class 'BetaBadgeData'
  context.setItems = setItems;
  const props = items.length ? items[0] : undefined;
  if (props?.hidden) return null;
  return (
    <View style={styles.container} pointerEvents="none">
      <View style={[styles.badge, { backgroundColor: c('accent') }]}>
        <Title color={c('accent', 'text')}>bèta 🤟</Title>
      </View>
    </View>
  );
}

export const BetaBadgeContainer = (
  props: React.ComponentProps<typeof View> & {
    children: any;
  }
) => {
  const [context] = React.useState(() => new BetaBadgeData());
  return (
    <BetaBadgeContext.Provider value={context}>
      {props.children}
      <BetaBadgeView />
    </BetaBadgeContext.Provider>
  );
};

export function BetaBadge(props: BetaBadgeProps) {
  const context = React.useContext(BetaBadgeContext);
  React.useEffect(() => {
    const id = context?.push(props);
    return () => (id ? context?.pop(id) : undefined);
  }, Object.values(props));
  return null;
}

const styles = StyleSheet.create({
  container: {
    ...Styles.fixedFillObject,
    overflow: 'hidden',
  },
  badge: {
    position: 'absolute',
    right: -58,
    bottom: -10,
    width: 160,
    height: 64,
    paddingTop: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
    transform: [{ rotateZ: '-40deg' }],
    // shadow
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 5,
  },
});
