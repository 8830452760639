import map from 'lodash/map';
import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { Title, Button, ListSection, Styles, useTheme } from '../components';
import { ITemplate, TemplateDiff, TemplateQuestionData } from '../store';
import { t, toTime } from '../utils';
import TemplateQuestionListItem from './TemplateQuestionListItem';

export default function useTemplateQuestionsSections(props: {
  template: ITemplate;
  diff?: TemplateDiff;
  showFilter?: boolean;
  selection?: string[];
  edit?: boolean;
  label?: string;
  setSelection?: (callback: (selection: string[]) => string[]) => void;
}): ListSection<{ id: string; question: TemplateQuestionData }>[] {
  const { template, diff, showFilter, edit, selection, setSelection, label } = props;
  const { c } = useTheme();
  const [filtered, setFiltered] = React.useState<boolean | undefined>();
  const isFiltered = filtered ?? (showFilter && !!diff);
  const [alphabetical, setAlphabetical] = React.useState(false);
  const questions = !isFiltered
    ? template.questions
        .map((question, id) => ({ id, question }))
        .sort((a, b) =>
          alphabetical
            ? a.question.q.localeCompare(b.question.q)
            : toTime(b.question.d) - toTime(a.question.d)
        )
    : map(diff?.questions ?? {}, (question, id) => ({
        id,
        question: (question.newQuestion ?? question.oldQuestion)!,
      })).sort((a, b) =>
        alphabetical
          ? a.question.q.localeCompare(b.question.q)
          : toTime(b.question.d) - toTime(a.question.d)
      );

  return [
    {
      dataSource: questions,
      renderSectionHeader: () => (
        <View style={styles.header}>
          <Title small style={Styles.flex}>
            {label ?? `${t('Questions')} (${questions.length})`}
          </Title>
          {showFilter ? (
            <Button
              style={styles.button}
              header
              icon="filter"
              type={isFiltered ? 'filled' : 'text'}
              color={isFiltered ? 'accent' : c('base', 'text', 'disabled')}
              onPress={() => setFiltered((state) => !state)}
            />
          ) : undefined}
          <Button
            style={styles.button}
            header
            icon="sort"
            type={alphabetical ? 'filled' : 'text'}
            color={alphabetical ? 'accent' : c('base', 'text', 'disabled')}
            onPress={() => setAlphabetical((state) => !state)}
          />
        </View>
      ),
      renderItem: (item) => (
        <TemplateQuestionListItem
          template={template}
          questionId={item.id}
          diff={diff}
          edit={edit}
          selected={selection?.includes(item.id)}
          onPress={
            setSelection
              ? () =>
                  setSelection((selection) =>
                    selection.includes(item.id)
                      ? selection.filter((a) => a !== item.id)
                      : [...selection, item.id]
                  )
              : undefined
          }
        />
      ),
    },
  ];
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
    marginBottom: 8,
  },
  button: {
    marginLeft: 8,
    marginRight: 0,
  },
});
