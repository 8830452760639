import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, where, orderBy, limit, query, DocumentReference } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { initFirestorter, getContext } from 'firestorter';
import { makeWebContext } from './firestorter';

export const firebaseConfig = {
  apiKey: 'AIzaSyA1emMIrQv8cE0htHGepZnypwWAZHaA3E4',
  authDomain: 'liegebeest-d2552.firebaseapp.com',
  projectId: 'liegebeest-d2552',
  storageBucket: 'liegebeest-d2552.appspot.com',
  messagingSenderId: '905062954948',
  appId: '1:905062954948:web:fdd97a467ce0796c439691',
  measurementId: 'G-C1R1MVSXTV',
};

export const firebaseRegion = 'europe-west1';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app, firebaseRegion);
const storage = getStorage(app);

/* if (is('development')) {
  firebase.firestore().useEmulator('localhost', 8080);
  functions.useEmulator('localhost', 5001);
} */

initFirestorter(makeWebContext({ firestore }));

export {
  auth,
  functions,
  storage,
  getContext,
  httpsCallable,
  ref,
  getDownloadURL,
  query,
  where,
  orderBy,
  limit,
  DocumentReference,
};
