import * as React from 'react';
import { Image as RawImage } from 'react-native';

import { storage, ref, getDownloadURL } from '../store/firebase';

export type ImageProps = React.ComponentProps<typeof RawImage> & {
  source: number | string;
};

type State = {
  source?: any;
};

export class Image extends React.Component<ImageProps> {
  state = {
    source: undefined,
  };

  static isAsyncSource(source?: any) {
    return (
      source &&
      typeof source === 'string' &&
      !source.startsWith('blob') &&
      !source.startsWith('data:') &&
      !source.startsWith('/static')
    );
  }

  static getDerivedStateFromProps(props: ImageProps, state: State) {
    return Image.isAsyncSource(props.source) ? null : { source: props.source };
  }

  componentDidMount() {
    if (Image.isAsyncSource(this.props.source)) {
      this.resolveAsyncSource();
    }
  }

  componentDidUpdate(prevProps: ImageProps) {
    if (this.props.source !== prevProps.source && Image.isAsyncSource(this.props.source)) {
      this.resolveAsyncSource();
    }
  }

  async resolveAsyncSource() {
    const uri = await getDownloadURL(ref(storage, this.props.source as string));
    this.setState(() => ({
      source: { uri },
    }));
  }

  render() {
    return <RawImage {...this.props} source={this.state.source ?? { uri: '' }} />;
  }
}
