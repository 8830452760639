import type { Document } from 'firestorter';

export class DocumentFragment<T = any> {
  private cachedData: T | null = null;
  public readonly doc: Document;
  public readonly fieldPath: string;
  private readonly pathFields: string[];

  constructor(doc: Document, fieldPath: string = '') {
    this.doc = doc;
    this.fieldPath = fieldPath;
    this.pathFields = fieldPath ? fieldPath.split('.') : [];
  }

  get id(): string {
    return this.pathFields.length ? this.pathFields[this.pathFields.length - 1] : '';
  }

  get data(): T | null {
    let data: any = this.doc.data;
    for (let i = 0; i < this.pathFields.length; i++) {
      data = data?.[this.pathFields[i]];
      if (!data) break;
    }
    this.cachedData = data ?? this.cachedData;
    return data ?? this.cachedData;
  }

  get hasData(): boolean {
    return this.data !== null;
  }

  get isLoading(): boolean {
    return this.doc.isLoading;
  }
}
