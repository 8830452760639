import { setString, getStringAsync } from 'expo-clipboard';
import * as React from 'react';

import { List, useToast } from '../components';
import PublishedTemplateListItem from '../publish/PublishedTemplateListItem';
import TemplateRequestListItem from '../request/TemplateRequestListItem';
import {
  observer,
  TemplateQuestionData,
  TemplateRequests,
  Auth,
  TemplateDiff,
  getContext,
  query,
  where,
  orderBy,
  limit,
} from '../store';
import useTemplateQuestionsSections from '../template/useTemplateQuestionsSections';
import { t, useTemplate, useNavigation, usePublishedTemplate } from '../utils';
import TemplateButton from './TemplateButton';

export default observer(function TemplateScreen(props: { route: any }) {
  const navigation = useNavigation();
  const toast = useToast();
  const { isNew } = props.route.params;
  const template = useTemplate()!;
  const publishedTemplate = usePublishedTemplate()!;
  const [templateRequests] = React.useState(
    () =>
      new TemplateRequests('templateRequests', {
        query: (ref) =>
          query(
            ref,
            where('ownerId', '==', Auth.getInstance().userId),
            where('templateId', '==', template.id),
            orderBy('date', 'desc'),
            limit(10)
          ),
      })
  );
  const nonAcceptedRequests = templateRequests.docs.filter((r) => r.status !== 'accepted');
  const visibleRequests = nonAcceptedRequests.filter(
    (r) => r === nonAcceptedRequests.find((p) => p.type === r.type)
  );
  const { title } = template;
  const [editedTitle, setEditedTitle] = React.useState<string | null>(isNew ? title ?? '' : null);
  const [editMode, setEditMode] = React.useState(false);
  const [selection, setSelection] = React.useState<string[]>([]);
  const isTitleEdited = editedTitle !== null;
  const isLoading = template.isLoading; // || publishedTemplate.isLoading;
  const diff =
    publishedTemplate.hasData && template.hasData && !visibleRequests.length
      ? TemplateDiff.create(publishedTemplate, template)
      : undefined;
  const sections = useTemplateQuestionsSections({
    template,
    edit: true,
    selection: editMode ? selection : undefined,
    setSelection: editMode ? setSelection : undefined,
  });

  React.useEffect(() => {
    navigation.setOptions({
      title: editMode ? t('$1 selected', selection.length + '') : title || t('Template'),
      headerRight: () => (
        <TemplateButton
          template={template}
          publishedTemplate={publishedTemplate}
          editMode={editMode}
          onPress={async (action) => {
            switch (action) {
              case 'name':
                setEditedTitle(title ?? '');
                break;
              case 'edit':
              case 'endEdit':
                setEditMode(action === 'edit');
                setSelection(() => []);
                break;
              case 'delete':
                {
                  const deleteObj: { [key: string]: any } = {};
                  selection.forEach(
                    (questionId) =>
                      (deleteObj[`questions.${questionId}`] = getContext().deleteField())
                  );
                  await template.update(deleteObj);
                  setSelection(() => []);
                  setEditMode(() => false);
                }
                break;
              case 'copy':
                setString(
                  JSON.stringify({
                    type: 'LiegebeestQuestions',
                    questions: selection.map((questionId) => template.questions.get(questionId)),
                  })
                );
                toast.show({ title: t('Copied to clipboard') });
                break;
              case 'paste':
                try {
                  const data = await getStringAsync();
                  const json = JSON.parse(data);
                  if (json.type === 'LiegebeestQuestions' && Array.isArray(json.questions)) {
                    const updateObj: { [key: string]: any } = {};
                    json.questions.forEach((question: TemplateQuestionData, idx: number) => {
                      const id = Date.now() + idx;
                      updateObj[`questions.${id}.q`] = question.q;
                      updateObj[`questions.${id}.a`] = question.a;
                      updateObj[`questions.${id}.f`] = question.f;
                    });
                    await template.update(updateObj);
                  }
                } catch (e) {
                  console.error(`Failed to paste clipboard data, error: ${(e as Error).message}`);
                }
                break;
            }
          }}
        />
      ),
    });
  }, [title, editMode, setEditMode, selection]);

  return (
    <List loading={isLoading} sections={sections} placeholder={t('Add a question ☝️')}>
      {publishedTemplate.hasData || visibleRequests.length ? (
        <List.Text small>{t('Public template')}</List.Text>
      ) : undefined}
      {publishedTemplate.hasData ? (
        <PublishedTemplateListItem publishedTemplate={publishedTemplate} />
      ) : undefined}
      {diff ? (
        <List.Item
          title={`${t('Publish changes')} (${diff.count})`}
          onPress={async () => navigation.push('publish', { template })}
        />
      ) : undefined}
      {visibleRequests.map((request) => (
        <TemplateRequestListItem key={request.id} templateRequest={request} compact />
      ))}
      {editedTitle !== null ? (
        <List.TextInput
          label={t('Name')}
          autoFocus={isNew}
          placeholder={t('Name')}
          value={editedTitle ?? title ?? ''}
          onChangeText={(name: string) => setEditedTitle(name)}
        />
      ) : undefined}
      {isTitleEdited ? (
        <List.Button
          title={t('Update name')}
          disabled={!editedTitle}
          onPress={async () => {
            await template.update({ title: editedTitle });
            setEditedTitle(null);
          }}
        />
      ) : undefined}
    </List>
  );
});
