import { GameSettings, GamePlayerSymbols } from './defaults';
import { DemoGamePlayers, DemoGameQuestions } from './demoData';
import type { DemoGameConfig, DemoGameAction } from './types';
import { shuffleArray } from './utils';

function appendActions(target: DemoGameAction[], actions: DemoGameAction[], duration: number) {
  const intervals = actions.map(() => Math.random() * duration).sort((a, b) => a - b);
  intervals.forEach((interval, idx) => {
    target.push({ wait: idx ? interval - intervals[idx - 1] : interval });
    target.push(actions[idx]);
  });
}

export function createDemoGame(id: string): DemoGameConfig {
  let players = [...DemoGamePlayers];
  shuffleArray(players);
  const playerCount = parseInt(id.substring(4), 10) || 8;
  players = players.slice(0, playerCount);
  const activePlayer = players[0];
  const actions: DemoGameAction[] = [];

  // Join & ready
  const joinActions: any[] = [];
  players.forEach((player) => {
    const joinAction = {
      join: player,
      symbol: GamePlayerSymbols[Math.floor(Math.random() * GamePlayerSymbols.length)],
      interval: (Math.random() * playerCount) / 2,
    };
    joinActions.push(joinAction);
    const readyAction = {
      ready: player,
      interval: joinAction.interval + Math.random() * 2,
    };
    joinActions.push(readyAction);
  });
  joinActions.sort((a, b) => a.interval - b.interval);
  joinActions.forEach((action, idx) => {
    actions.push({ wait: idx ? action.interval - joinActions[idx - 1].interval : action.interval });
    actions.push({
      ...action,
      // @ts-ignore
      interval: undefined,
    });
  });
  actions.push({ wait: 1 });

  // Intro
  actions.push({ status: 'started' });
  actions.push({ phase: 'intro', duration: GameSettings.introDuration / 1000 });
  actions.push({ wait: GameSettings.introDuration / 1000 });

  // Lie phase
  const choices = players.map(
    () =>
      DemoGameQuestions[0].fakeAnswers[
        Math.floor(Math.random() * DemoGameQuestions[0].fakeAnswers.length)
      ]
  );
  actions.push({
    phase: 'lie',
    duration: GameSettings.lieDuration / 1000,
    question: DemoGameQuestions[0].question,
    questionSound: DemoGameQuestions[0].questionSound ?? '',
    questionImage: DemoGameQuestions[0].questionImage ?? '',
    questionColor: DemoGameQuestions[0].questionColor ?? '',
  });
  actions.push({ wait: 4 });
  appendActions(
    actions,
    players.map((player, idx) => ({
      lie: player,
      text: choices[idx],
    })),
    GameSettings.lieDuration / 1000 - 6
  );
  actions.push({ wait: 2 });

  // Choose phase
  actions.push({
    phase: 'choose',
    duration: GameSettings.chooseDuration / 1000,
    truth: DemoGameQuestions[0].truth,
  });
  actions.push({ wait: 4 });
  appendActions(
    actions,
    players.map((player) => ({
      choose: player,
      text: choices[Math.floor(Math.random() * choices.length)],
    })),
    GameSettings.chooseDuration / 1000 - 4
  );

  // Results phase
  actions.push({
    phase: 'results',
    duration: GameSettings.resultsDuration / 1000,
    truth: DemoGameQuestions[0].truth,
    truthSound: DemoGameQuestions[0].truthSound ?? '',
    truthImage: DemoGameQuestions[0].truthImage ?? '',
    truthColor: DemoGameQuestions[0].truthColor ?? '',
  });
  actions.push({ wait: GameSettings.resultsDuration / 1000 });
  actions.push({ status: 'ended' });
  actions.push({ wait: 5 });
  actions.push({ status: 'ended' });

  return {
    title: 'Demo spel',
    activePlayer,
    actions,
  };
}
