import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { Button, Title, useTheme, useHeaderHeight } from '../components';
import { Game, observer } from '../store';
import { useNavigation } from '../utils';
import GameHeaderButton from './GameHeaderButton';

export default observer(function GameHeader(props: { game: Game }) {
  const { game } = props;
  const { title } = game;
  const navigation = useNavigation();
  const { c } = useTheme();
  const { headerHeight, headerTopInset } = useHeaderHeight();
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: c('accent'),
          height: headerHeight,
          paddingTop: headerTopInset,
        },
      ]}>
      <Button header icon="back" onPress={() => navigation.goBack()} />
      <Title center color={c('accent', 'text')}>
        {title}
      </Title>
      <GameHeaderButton game={game} />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    paddingLeft: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
