import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, useToast } from '../components';
import { Game, observer, store } from '../store';
import { t, useNavigation, is } from '../utils';

export default observer(function GameWaitingStatus(props: { game: Game }) {
  const { game } = props;
  const { players, isHost, me } = game;
  const navigation = useNavigation();
  const toast = useToast();

  async function joinGame() {
    try {
      await game.join({});
      toast.show({ title: t('You have joined') });
    } catch (e) {
      toast.error(e as Error);
    }
  }

  let button: any;
  if (isHost) {
    button = (
      <Button
        title={t('Start')}
        disabled={!players.length && is('development')}
        onPress={game.start}
      />
    );
  } else if (me) {
    button = (
      <Button
        title={t("I'm ready") + ' 🚀'}
        type={me.isReady ? 'outline' : 'filled'}
        onPress={me.isReady ? undefined : game.readyToStart}
      />
    );
  } else if (store.auth.userName) {
    button = <Button title={t('Join')} onPress={joinGame} />;
  } else {
    button = (
      <Button
        title={t('Sign in to join')}
        onPress={() => navigation.push('signin', { joinGame })}
      />
    );
  }
  return <View style={styles.container}>{button}</View>;
});

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 16,
  },
});
