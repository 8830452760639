import * as React from 'react';

import { List } from '../components';
import { store, observer, Games, Game } from '../store';
import { t, useRoute } from '../utils';
import GameListItem from './GameListItem';

export default observer(function GamesScreen() {
  const { filter } = useRoute().params;
  let games: Games | undefined;
  switch (filter) {
    case 'created':
      games = store.myCreatedGames;
      break;
    case 'active':
      games = store.myActiveGames as any;
      break;
    case 'played':
      games = store.myPlayedGames;
      break;
  }

  return (
    <List<Game>
      placeholder={t('No games yet')}
      dataSource={games}
      renderItem={(game) => <GameListItem game={game} />}
    />
  );
});
