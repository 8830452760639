import * as React from 'react';
import shortHash from 'shorthash2';

import type { CellSize } from './Cell';

export type CellLayoutDirection = 'column' | 'row';

export function layoutCells(
  props: Partial<CellSize> & {
    direction?: CellLayoutDirection;
  },
  children: any[]
) {
  const { width = 0, height = 0, direction } = props;

  let flexWidth = width ?? 0;
  let flexHeight = height ?? 0;
  let flexRows = 0;
  let flexCols = 0;
  React.Children.forEach(children, (child) => {
    if (!child || child.props.hidden) return;
    flexWidth -= child.props.width ?? 0;
    flexHeight -= (child.props.height ?? 0) + (child.props.marginTop ?? 0);
    flexCols += child.props.width ? 0 : 1;
    flexRows += child.props.height ? 0 : 1;
  });

  let topOffset = 0;
  let leftOffset = 0;
  return React.Children.map(children, (child, idx) => {
    if (!child || child.props.hidden) return;
    topOffset += child.props.marginTop ?? 0;
    const cellLeft = child.props.left ?? leftOffset;
    const cellTop = child.props.top ?? topOffset;
    let cellWidth = child.props.width;
    let cellHeight = child.props.height;
    switch (direction) {
      case 'column':
        cellWidth = cellWidth ?? width;
        if (cellHeight == null) {
          cellHeight = Math.round(flexHeight / flexRows);
          flexRows -= 1;
          flexHeight -= cellHeight + (child.props.marginTop ?? 0);
        }
        topOffset += cellHeight;
        break;
      case 'row':
        cellHeight = cellHeight ?? height;
        if (cellWidth == null) {
          cellWidth = Math.round(flexWidth / flexCols);
          flexCols -= 1;
          flexWidth -= cellWidth;
        }
        leftOffset += cellWidth;
        break;
    }
    return React.cloneElement(child, {
      left: cellLeft,
      top: cellTop,
      width: cellWidth,
      height: cellHeight,
      firstCol: !idx || direction === 'column',
      firstRow: !idx || direction === 'row',
    });
  });
}

export function layoutVertical(props: Partial<CellSize>, children: any[]) {
  return layoutCells(
    {
      ...props,
      direction: 'column',
    },
    children
  );
}

export function layoutChoices(
  props: Partial<CellSize>,
  children: any[],
  highlighted?: string,
  maximized?: boolean
) {
  const { width = 0, height = 0 } = props;
  const rowCount = 8; // TODO
  const rows: { text: string; children: any[] }[] = [];
  children = React.Children.toArray(children).sort(
    (a: any, b: any) => b.props.choice.text.length - a.props.choice.text.length
  );
  while (children.length) {
    const child = children.shift()!;
    if (rows.length < rowCount) {
      rows.unshift({ text: child.props.choice.text, children: [child] });
    } else {
      rows[0].text += `+++${child.props.choice.text}`;
      rows[0].children.push(child);
      rows.sort((a, b) => a.text.length - b.text.length);
    }
  }
  rows.sort((a, b) =>
    shortHash(a.children[0].props.choice.text).localeCompare(
      shortHash(b.children[0].props.choice.text)
    )
  );

  const results: any[] = [];
  let lastResult: any;
  let cellTop = 0;
  rows.forEach((row, rowIdx) => {
    const cellHeight = Math.round((height - cellTop) / (rows.length - rowIdx));
    let cellLeft = 0;
    row.children.forEach((child, colIdx) => {
      const cellWidth = Math.round((width - cellLeft) / (row.children.length - colIdx));
      const newChild = React.cloneElement(child, {
        left: cellLeft,
        top: cellTop,
        width: cellWidth,
        height: cellHeight,
        firstCol: !colIdx,
        firstRow: !rowIdx,
        enter: {
          duration: 1,
          delay: rowIdx * 0.1,
          keyframes: {
            '0%': { left: colIdx ? cellWidth : -cellWidth },
            '100%': { left: 0 },
          },
        },
      });
      if (highlighted && child.props.choice.text === highlighted) {
        lastResult = newChild;
      } else {
        results.push(newChild);
      }
      cellLeft += cellWidth;
    });
    cellTop += cellHeight;
  });
  if (lastResult) {
    if (maximized) {
      results.push(
        React.cloneElement(lastResult, {
          left: 0,
          top: 0,
          width,
          height,
          firstCol: true,
          firstRow: true,
        })
      );
    } else {
      results.push(lastResult);
    }
  }

  return results;
}
