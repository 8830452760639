import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AuthButton from '../account/AuthButton';
import { Title, List, Badge, Styles, useTheme, useHeaderHeight } from '../components';
import DemoButton from '../demo/DemoButton';
import { store, observer } from '../store';
import { t, useNavigation, is } from '../utils';
import ActiveGame from './ActiveGame';

export default observer(function HomeScreen() {
  const { auth, myActiveGames, adminPendingRequests } = store;
  const { c } = useTheme();
  const navigation = useNavigation();
  const isLoading = auth.isLoading || myActiveGames.isLoading || adminPendingRequests.isLoading;
  const { headerHeight, headerTopInset } = useHeaderHeight();
  const safeInsets = useSafeAreaInsets();

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={[styles.header, { height: headerHeight, paddingTop: headerTopInset }]}>
          <DemoButton />
          <AuthButton />
        </View>
        {adminPendingRequests.hasDocs ? (
          <List.Item
            label={t('Game management')}
            title={t('Pending requests')}
            highlight
            value={<Badge color="surface" title={adminPendingRequests.docs.length + ''} />}
            onPress={() => navigation.push('requests', { filter: 'pending' })}
          />
        ) : undefined}
        <View style={styles.topContent}>
          <Title xxlarge center>
            {t('Lie or die').toUpperCase()}
          </Title>
          {/* <Image
            style={styles.logo}
            source={require('../assets/images/logo.png')}
            resizeMode="contain"
          /> */}
        </View>
        <View style={styles.bottomContent}>
          {isLoading ? (
            <Title center small>
              {t('Loading') + '...'}
            </Title>
          ) : (
            <List.Button
              title={store.auth.user ? t('Create game') : t('Sign in to join')}
              onPress={() => navigation.push(store.auth.user ? 'create' : 'signin')}
            />
          )}
        </View>
      </View>
      {myActiveGames.hasDocs ? (
        <View
          style={[
            styles.activeGamesContainer,
            { backgroundColor: c('accent'), paddingBottom: safeInsets.bottom },
          ]}>
          <View style={styles.content}>
            {myActiveGames.docs.map((game) => (
              <ActiveGame key={game.id} game={game} />
            ))}
          </View>
        </View>
      ) : undefined}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    maxWidth: is('mobile') ? undefined : 800,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topContent: {
    flex: 1,
    justifyContent: 'flex-end',
    padding: 24,
  },
  logo: {
    height: 100,
  },
  bottomContent: {
    flex: 1.5,
    justifyContent: 'flex-start',
  },
  activeGamesContainer: {
    ...Styles.fixedObject,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
