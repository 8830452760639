import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { Animation, AnimationContainer, AnimationConfig } from '../animation';
import { Styles, useHeaderHeight } from '../theme';
import { ToastProps, ToastItem } from './ToastItem';

type Props = React.ComponentProps<typeof View> & {
  children: any;
};

let nextToast = 1;

export class ToastContextData {
  private setToasts(_fn: (toasts: ToastProps[]) => ToastProps[]) {}

  show(toast: ToastProps) {
    const duration = toast.duration ?? 3;
    toast = toast.id ? toast : { ...toast, id: `__toast${nextToast++}` };
    if (toast.hasCloseButton && !toast.onDismiss) {
      toast = { ...toast, onDismiss: () => this.hide(toast.id!) };
    }
    this.setToasts((toasts) => [
      ...toasts.filter((t) => t.duration === 0 && t.id !== toast.id),
      toast,
      ...toasts.filter((t) => t.duration !== 0 && t.id !== toast.id),
    ]);
    if (duration > 0) {
      setTimeout(
        () => this.setToasts((toasts) => toasts.filter((t) => t !== toast)),
        Math.round(duration * 1000)
      );
    }
    return toast.id!;
  }

  error(error: Error, toastId?: string) {
    const toast: ToastProps = {
      id: toastId,
      title: error.message,
      color: 'error',
      hasCloseButton: true,
      duration: 0,
    };
    return this.show(toast);
  }

  hide(toastId: string) {
    this.setToasts((toasts) => toasts.filter((t) => t.id !== toastId));
  }
}

const ToastContext = React.createContext<ToastContextData>(new ToastContextData());

const animationConfig: AnimationConfig = {
  enter: {
    duration: 0.2,
    transform: [{ translateY: -100 }],
  },
  exit: {
    duration: 0.3,
    opacity: 0,
  },
  layout: {
    duration: 0.4,
  },
};

function ToastRenderer(props: { toast: ToastContextData }) {
  const { toast } = props;
  const { headerTopInset } = useHeaderHeight();
  const [toasts, setToasts] = React.useState<ToastProps[]>([]);
  // @ts-expect-error Property 'setVisible' is private and only accessible within class 'Menu'
  toast.setToasts = setToasts;
  return (
    <AnimationContainer
      style={[styles.container, { marginTop: headerTopInset - 3 }]}
      pointerEvents="box-none"
      clip
      debugName="Toast">
      {toasts.map((toast) => (
        <Animation key={toast.id!} id={toast.id!} config={animationConfig}>
          <ToastItem {...toast} />
        </Animation>
      ))}
    </AnimationContainer>
  );
}

export const ToastContainer = (props: Props) => {
  const [toast] = React.useState(() => new ToastContextData());
  return (
    <ToastContext.Provider value={toast}>
      {props.children}
      <ToastRenderer toast={toast} />
    </ToastContext.Provider>
  );
};

export function useToast() {
  return React.useContext(ToastContext);
}

export function Toast(props: ToastProps) {
  const toast = useToast();
  React.useEffect(() => {
    const toastId = toast.show({
      ...props,
      duration: 0,
    });
    return () => toast.hide(toastId);
  }, Object.values(props));
  return null;
}

const styles = StyleSheet.create({
  container: {
    ...Styles.fixedFillObject,
    alignItems: 'center',
    // flexDirection: 'column-reverse',
  },
});
