import isEqual from 'lodash/isEqual';
import * as React from 'react';
import { View, ViewStyle, StyleSheet, LayoutRectangle } from 'react-native';

export type AnimationEnterConfig = {
  opacity?: number;
  duration?: number;
  delay?: number;
  transform?: ViewStyle['transform'];
};
export type AnimationExitConfig = AnimationEnterConfig;
export type AnimationLayoutConfig = {
  duration?: number;
};

export type AnimationConfig = {
  enter?: AnimationEnterConfig;
  exit?: AnimationExitConfig;
  layout?: AnimationLayoutConfig;
};

export type AnimationProps = {
  style?: any;
  id: string;
  layout?: LayoutRectangle;
  unmounted?: boolean;
  onLayout?: (id: string, layout: LayoutRectangle) => void;
  onExit?: (id: string, timeout?: number) => void;
  children: any;
  config: AnimationConfig;
};

type Layout = LayoutRectangle & {
  unmounted?: boolean;
};

type Size = {
  width: number;
  height: number;
};

type Props = React.ComponentProps<typeof View> & {
  children: any;
  clip?: boolean;
  debug?: boolean;
  debugName?: string;
};

type State = {
  layouts: { [id: string]: Layout | undefined };
  rafPending: boolean;
  visibleChildren: any[];
  mountedIds: string[];
  size?: Size;
};

export const AnimationShadowNodeContext = React.createContext(false);

export function useShadowNode() {
  return React.useContext(AnimationShadowNodeContext);
}

function isAnimation(element: any) {
  return !!element?.props?.id;
}

export class AnimationContainer extends React.Component<Props, State> {
  static contextType = AnimationShadowNodeContext;

  state: State = {
    layouts: {},
    rafPending: false,
    visibleChildren: React.Children.toArray(this.props.children).filter(isAnimation),
    mountedIds: [],
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { children } = props;
    const { layouts } = state;
    const filteredChildren = React.Children.toArray(children).filter(isAnimation);
    const mountedIds = filteredChildren.map((child: any) => child?.props.id);
    const unmountedChildren = state.visibleChildren.filter(
      (child) =>
        layouts[child.props.id] &&
        child.props.config.exit &&
        child.props.config.exit?.duration !== 0 &&
        !mountedIds.includes(child.props.id)
    );
    const visibleChildren = [...unmountedChildren, ...filteredChildren];
    return isEqual(visibleChildren, state.visibleChildren) && isEqual(state.mountedIds, mountedIds)
      ? null
      : { visibleChildren, mountedIds };
  }

  private logDebug(text: string) {
    console.debug(`${this.props.debugName ?? 'AnimationContainer'} - ${text}`);
  }

  private onContainerLayout = (event: any) => {
    const size = {
      width: event.nativeEvent.layout.width,
      height: event.nativeEvent.layout.height,
    };
    this.setState((state) => {
      if (isEqual(size, state.size)) return null;
      if (this.props.debug)
        this.logDebug(
          `onContainerLayout, size=${JSON.stringify(size)}, oldSize: ${JSON.stringify(state.size)}`
        );
      return { size };
    });
  };

  private onLayout = (id: string, layout: Layout) => {
    this.setState(({ layouts, rafPending }) => {
      if (isEqual(layouts[id], layout)) {
        // console.log(`onLayout ${id}, EQUAL`);
        return null;
      }
      if (this.props.debug)
        this.logDebug(
          `onLayout ${layouts[id] ? 'changed' : 'set'} for "${id}" (${JSON.stringify(layout)}), ${
            rafPending ? 'RAF already requested' : 'request RAF'
          }`
        );
      if (!rafPending) {
        requestAnimationFrame(() => {
          // if (this.props.debug) this.logDebug(`requestAnimationFrame!`);
          this.setState((state) => (state.rafPending ? { rafPending: false } : null));
        });
      }
      return {
        layouts: { ...layouts, [id]: layout },
        rafPending: true,
      };
    });
  };

  private onExit = (id: string) => {
    if (this.props.debug) this.logDebug(`onExit ${id}`);
    this.setState(({ layouts, visibleChildren, rafPending }) => {
      layouts = { ...layouts };
      delete layouts[id];
      if (!rafPending) {
        requestAnimationFrame(() => {
          // if (this.props.debug) this.logDebug(`requestAnimationFrame!`);
          this.setState((state) => (state.rafPending ? { rafPending: false } : null));
        });
      }
      return {
        layouts,
        rafPending: true,
        visibleChildren: visibleChildren.filter((child) => child.props.id !== id),
      };
    });
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      this.props !== nextProps ||
      (this.state.rafPending && !nextState.rafPending) ||
      (this.state.size !== nextState.size && !!this.state.size)
    );
  }

  render() {
    const { children, clip, ...otherProps } = this.props;
    const { visibleChildren, layouts, mountedIds } = this.state;
    const mountedChildren = React.Children.toArray(children);
    if (this.props.debug)
      this.logDebug(`render, cnt: ${visibleChildren.length}, mountedIds ${mountedIds}`);
    /* console.log(
      'RENDER, visible: ',
      visibleChildren.length,
      ', mounted: ',
      mountedChildren.length,
      ', ids: ',
      mountedIds,
      ', layouts: ',
      layouts
    ); */

    // Optimize rendering when inside a shadow node. No animations
    // or layout tracking is needed then. Instead the component only needs to
    // perform layout so the parent can calculate the size.
    if (this.context) {
      return <View {...otherProps}>{mountedChildren}</View>;
    }

    return (
      <View {...otherProps} onLayout={this.onContainerLayout}>
        <AnimationShadowNodeContext.Provider value>
          {mountedChildren.map((child: any) =>
            isAnimation(child) ? React.cloneElement(child, { onLayout: this.onLayout }) : child
          )}
        </AnimationShadowNodeContext.Provider>
        <View style={clip ? styles.clipContent : styles.content} pointerEvents="box-none">
          {visibleChildren.map((child: any) => {
            return React.cloneElement(child, {
              layout: layouts[child.props.id],
              unmounted: !mountedIds.includes(child.props.id),
              onExit: this.onExit,
            });
          })}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    ...StyleSheet.absoluteFillObject,
  },
  clipContent: {
    ...StyleSheet.absoluteFillObject,
    overflow: 'hidden',
  },
});
