import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import { GamePlayer } from '../store';

export default function GamePlayerSymbol(props: { player: GamePlayer; style?: any }) {
  const { player, style } = props;
  const { symbol } = player;
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={player.playSound}>
      <Text style={styles.symbol}>{symbol}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 40,
    alignItems: 'center',
  },
  symbol: {
    fontSize: 22,
  },
});
