import { Document } from 'firestorter';

import { TemplateQuestions } from './TemplateQuestions';
import { functions, httpsCallable } from './firebase';
import { asyncAction, Auth } from './framework';
import { ITemplate } from './interfaces';
import type { TemplateRequestData } from './types';

export class TemplateRequest extends Document<TemplateRequestData> implements ITemplate {
  public readonly questions = new TemplateQuestions(this, 'data.questions');

  get title() {
    return this.data.data?.title ?? '';
  }

  get date() {
    return this.data.date;
  }

  get name() {
    return this.data.name;
  }

  get isPending() {
    return !!this.data.pending;
  }

  get type() {
    return this.data.type;
  }

  get status() {
    return this.data.status;
  }

  get message() {
    return this.data.message;
  }

  get isOwner() {
    return this.data.ownerId === Auth.getInstance().userId;
  }

  deletePermanently = asyncAction(async () => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'templateRequest',
      action: 'delete',
      requestId: this.id,
    });
  });

  /* Admin / Moderator */

  startReview = asyncAction(async (config: { message: string }) => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'templateRequest',
      action: 'startReview',
      requestId: this.id,
      message: config.message,
    });
  });

  accept = asyncAction(async (config: { message: string }) => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'templateRequest',
      action: 'accept',
      requestId: this.id,
      message: config.message,
    });
  });

  reject = asyncAction(async (config: { message: string }) => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'templateRequest',
      action: 'reject',
      requestId: this.id,
      message: config.message,
    });
  });

  navigateToQuestion(navigation: any, questionId: string) {
    navigation.push('requestQuestion', {
      templateRequest: this,
      questionId,
    });
  }
}
