import * as React from 'react';

import { Game, GameClientPhaseType, GameClientPhaseStatus } from '../store';
import { t } from '../utils';
import GameChoicesView from './GameChoicesView';
import GameLieInput from './GameLieInput';
import GamePlayersView from './GamePlayersView';
import GameQuestionView from './GameQuestionView';
import GameText from './GameText';
import { CellSpacer } from './cell';

export function renderGameState(config: {
  game: Game;
  phaseType: GameClientPhaseType;
  phaseStatus: GameClientPhaseStatus;
  height: number;
  headerHeight: number;
}) {
  const { game, phaseType, phaseStatus, height, headerHeight } = config;
  const textHeight = height / 10;
  const questionHeight = height / 3;
  const { players } = game;
  const props = {
    game,
    phaseType,
    phaseStatus,
  };
  let text = players.length === 1 ? t('$1 player', '1') : t('$1 players', players.length + '');
  switch (phaseType) {
    /** ************************
     *  WAITING ROOM
     ************************ */
    case 'wait':
      return [
        <CellSpacer key="headerSpacer" height={headerHeight} />,
        <GameText key="text" {...props} height={textHeight} text={text} />,
        <GamePlayersView key="players" {...props} />,
      ];

    /** ************************
     *  INTRO PHASE
     ************************ */
    case 'intro':
      return [
        <GameText
          key="introHeader"
          height={(height - textHeight) / 4}
          {...props}
          text={t("You'll receive 500 points for every player you fool.")}
          enter="slideRight"
          exit="slideLeft"
        />,
        <GameText
          key="introFooter"
          {...props}
          height={(height - textHeight) / 4}
          text={t('And 1000 points for finding the truth.')}
          enter="slideLeft"
          exit="slideRight"
        />,
        <GameText key="text" {...props} height={textHeight} text={text} />,
        <GamePlayersView key="players" {...props} />,
      ];

    /** ************************
     *  LIE PHASE
     ************************ */
    case 'lie':
      switch (phaseStatus) {
        case 'noInput':
          text = `${t('Players are lying')} ...`;
          break;
        case 'waitForInput':
          text = t('Enter your lie');
          break;
        case 'waitForInputCritical':
          text = t('Hurry up!');
          break;
        case 'inputGiven': {
          const player = game.players
            .toArray()
            .filter((player) => player.hasLied)
            .sort((a, b) => b.time - a.time)
            .shift();
          text = player
            ? t('$1 has lied', `${player?.symbol} ${player?.name}`)
            : `${t('Players are lying')} ...`;
          break;
        }
      }
      return [
        <GameQuestionView
          key="question"
          {...props}
          height={phaseStatus === 'enter' ? height : questionHeight}
          question={game.question!}
          sound={game.questionSound}
          image={game.questionImage}
          enter="slideTop"
          exit="slideTop"
        />,
        <GameLieInput
          key="lieInput"
          {...props}
          height={textHeight}
          enter="slideRight"
          exit="fadeSlow"
          hidden={!(phaseStatus === 'waitForInput' || phaseStatus === 'waitForInputCritical')}
        />,
        <GameText
          key="text"
          {...props}
          height={textHeight}
          text={text}
          hidden={phaseStatus === 'waitForInput' || phaseStatus === 'waitForInputCritical'}
        />,
        <GamePlayersView key="players" {...props} />,
      ];

    /** ************************
     *  CHOOSE PHASE
     ************************ */
    case 'choose':
      switch (phaseStatus) {
        case 'noInput':
          text = `${t('Players are choosing')} ...`;
          break;
        case 'waitForInput':
          text = t('Find the truth');
          break;
        case 'waitForInputCritical':
          text = t('Hurry up!');
          break;
        case 'inputGiven': {
          const player = game.players
            .toArray()
            .filter((player) => player.hasChosen)
            .sort((a, b) => b.time - a.time)
            .shift();
          text = player
            ? t('$1 has chosen', `${player?.symbol} ${player?.name}`)
            : `${t('Players are choosing')} ...`;
          break;
        }
      }
      return [
        <GameQuestionView
          key="question"
          {...props}
          height={questionHeight}
          question={game.question!}
          sound={game.questionSound}
          image={game.questionImage}
        />,
        <GameText key="text" {...props} height={textHeight} text={text} />,
        <GameChoicesView key="choices" {...props} />,
        <GamePlayersView key="players" {...props} height={textHeight} />,
      ];

    /** ************************
     *  RESULT PHASES
     ************************ */
    case 'votes':
    case 'choice':
    case 'truth': {
      const { myLie, myChoice, truth } = game;
      let highlighted = '';
      const maximized = phaseStatus === 'noInput';
      switch (phaseType) {
        case 'votes':
          text = myLie ? t('Your lie!') : t('You forgot to enter a lie');
          highlighted = myLie ?? '';
          break;
        case 'choice':
          text = myChoice ? t('Your choice!') : t('You forgot to pick an answer');
          highlighted = myChoice ?? '';
          break;
        case 'truth':
          text = t('And the truth is!');
          highlighted = truth ?? '';
          break;
      }
      return [
        <GameQuestionView
          key="question"
          {...props}
          height={questionHeight}
          question={game.question!}
          sound={game.questionSound}
          image={game.questionImage}
        />,
        <GameText key="text" {...props} height={textHeight} text={text} />,
        <GameChoicesView
          key="choices"
          {...props}
          highlighted={highlighted}
          maximized={maximized}
        />,
        <GamePlayersView
          key="players"
          {...props}
          height={phaseStatus === 'noInput' ? undefined : height / 20}
        />,
      ];
    }

    /** ************************
     *  ROUND SCORES
     ************************ */
    case 'roundScore':
      text = t(`Results for this round`);
      return [
        <GameText key="text" {...props} height={textHeight} text={text} />,
        <GamePlayersView key="players" {...props} />,
      ];

    /** ************************
     *  END RESULT
     ************************ */
    case 'totalScore':
      text = game.status === 'ended' ? t('Game has ended, final results') : t('Results so far');
      return [
        <GameText key="text" {...props} height={textHeight} text={text} />,
        <GamePlayersView key="players" {...props} />,
      ];

    default:
      return [];
  }
}
