import { getLanguageCode } from '../utils';

export type DemoGameQuestion = {
  question: string;
  questionSound?: string;
  questionImage?: string;
  questionColor?: string;
  truth: string;
  truthSound?: string;
  truthImage?: string;
  truthColor?: string;
  fakeAnswers: string[];
};

const isNL = getLanguageCode() === 'nl';

export const DemoGameQuestions: DemoGameQuestion[] = [
  {
    question: isNL
      ? 'In 2002 werd in een onderzoek vastgesteld dat de mens 50% van zijn DNA deelt met een _____ .'
      : 'In 2002 research concluded that a human shares 50% of its DNA with a _____',
    // questionImage: 'https://www.stripbeeldshop.nl/images/productimages/big/KL04.jpg',
    // questionColor: '#ff0000',
    truth: isNL ? 'banaan' : 'banana',
    // truthImage: require('../assets/images/banana.jpeg'),
    // truthColor: '#fcc24b',
    fakeAnswers: isNL
      ? [
          'politicus',
          'dinosaurus',
          'eend',
          'maarten van rossum',
          'plant',
          'mug',
          'reptiel',
          'aap',
          'appel',
          'kaas',
          'hond',
          'kat',
          'beuk',
        ]
      : [
          'politician',
          'dinosaur',
          'duck',
          'tony danza',
          'plant',
          'mosquito',
          'reptile',
          'tardigrade',
          'great ape',
          'coca plant',
          'apple',
          'mango',
          'dog',
          'cat',
        ],
  },
  {
    question:
      'Een eetcafe in Groningen heeft een uithangbord met de tekst: "We have beer as cold as your ex girlfriend, and burgers fatter than your _____"',
    questionSound: require('../sound/effects/ghost.m4a'),
    truth: 'mama',
    fakeAnswers: ['mother', 'arm', 'daddies tummy', 'portemonnee', 'moeke'],
  },
];

export const DemoGamePlayers = [
  'Theo',
  'Herman',
  'Jörgen',
  'Katinka',
  'Joep',
  'Brigitte',
  'Hans',
  'Jochem',
  'Roué',
  'Jan Jaap',
  'Kasper',
  'Janneke',
  'Henry',
  'Lenette',
  'Murth',
  'Najib',
  'Owen',
  'Peter',
  'Jandino',
  'Dolf',
  'Kim',
  'Tex',
  'Sanne',
  'Tim',
  'Ronald',
  'Martijn',
  'Patrick',
  'Thijs',
  'Rob',
  'Pieter',
  'Micha',
  'Nico',
  'Marc-Marie',
  'Kees',
  'Jochen',
  'Jeroen',
  'Howard',
  'Eric',
  'Daniël',
  'Alex',
  'Arthur',
  'Bas',
  'Bob',
  'Cindy',
  'Emilio',
  'Johan',
  'Jorn',
  'Hein',
  'Fleur',
];
