import { Collection, CollectionSource, ICollectionOptions } from 'firestorter';

import { Game } from './Game';

export class Games extends Collection<Game> {
  constructor(source: CollectionSource, options?: ICollectionOptions<Game>) {
    super(source, {
      ...(options ?? {}),
      createDocument: (source, options) => new Game(source, options),
    });
  }
}
