import { observable, runInAction } from 'mobx';

import { storage, ref, getDownloadURL } from '../store/firebase';
import { Sound } from './Sound';
import { sounds, SoundName } from './effects';

export class Sounds {
  private mutableIsNotAllowed = observable.box<boolean | undefined>();
  private sounds: { [name: string]: Promise<Sound> } = {};
  private static instance?: Sounds;

  // Static API

  static getInstance(): Sounds {
    this.instance = this.instance ?? new Sounds();
    return this.instance;
  }

  static play(name: SoundName, isTrustedUserEvent?: boolean) {
    return this.getInstance().play(name, isTrustedUserEvent);
  }

  static playUri(uri: string, isTrustedUserEvent?: boolean) {
    return this.getInstance().playUri(uri, isTrustedUserEvent);
  }

  static get isNotAllowed() {
    return this.getInstance().isNotAllowed;
  }

  static requestPermissions = () => {
    return Sounds.getInstance().init(true);
  };

  // Class API

  get isNotAllowed() {
    return this.mutableIsNotAllowed.get();
  }

  async init(isTrustedUserEvent?: boolean, muted?: boolean) {
    return runInAction(async () => {
      const notAllowed = this.mutableIsNotAllowed.get();
      if (notAllowed === undefined || (notAllowed && isTrustedUserEvent)) {
        this.mutableIsNotAllowed.set(!isTrustedUserEvent);
        if (isTrustedUserEvent) {
          const sound = await this.load('bell');
          sound.play(muted);
        }
      }
      return notAllowed === false || isTrustedUserEvent;
    });
  }

  load(name: SoundName) {
    // @ts-ignore This condition will always return true
    if (this.sounds[name]) return this.sounds[name];
    this.sounds[name] = Sound.load(sounds[name]);
    return this.sounds[name];
  }

  async play(name: SoundName, isTrustedUserEvent?: boolean) {
    const enabled = await this.init(isTrustedUserEvent, true);
    const sound = await this.load(name);
    if (enabled) {
      await sound.play();
    }
  }

  async playUri(uri: string, isTrustedUserEvent?: boolean) {
    const enabled = await this.init(isTrustedUserEvent, true);
    if (!enabled) return;
    if (!uri.startsWith('blob') && !uri.startsWith('data:') && !uri.startsWith('/static')) {
      uri = await getDownloadURL(ref(storage, uri));
    }
    const sound = await Sound.load(uri);
    await sound.play();
    // TODO - free sound after playback
  }
}

// Pre-load bell sound, so it can be played immediately
// and enable Audio when the game plays its first sound.
Sounds.getInstance().load('bell');
