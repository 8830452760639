import * as React from 'react';
import { View } from 'react-native';

import { BackgroundContext, BackgroundData } from './Background';
import { BackgroundView } from './BackgroundView';

export const BackgroundContainer = (
  props: React.ComponentProps<typeof View> & {
    children: any;
  }
) => {
  const [background] = React.useState(() => new BackgroundData());
  return (
    <BackgroundContext.Provider value={background}>
      <BackgroundView />
      {props.children}
    </BackgroundContext.Provider>
  );
};
