import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Title, useTheme, ColorName } from '../components';

export default function GamePlayerScore(props: { score: string; color?: ColorName }) {
  const { score, color } = props;
  const { c } = useTheme();
  return (
    <View style={styles.container}>
      <Title color={color ?? c('surface', 'text')}>{score}</Title>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 12,
  },
});
