import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { useTheme, Shadows } from '../components';

export default function GamePlayerSurface(props: { children: any; style?: any }) {
  const { children, style } = props;
  const { c } = useTheme();
  return (
    <View style={[styles.container, { backgroundColor: c('surface') }, style]}>{children}</View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 40,
    height: 40,
    borderRadius: 20,
    ...Shadows.regular,
  },
});
