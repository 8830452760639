import { View } from 'react-native';

import { Button, useTheme } from '../components';
import { useNavigation } from '../utils';

export default function DemoButton() {
  const navigation = useNavigation();
  const { c } = useTheme();
  // TODO: Demo no longer working after firebase upgrade
  // needs to be investigated
  return <View />;
  /* return (
    <Button
      header="left"
      color={c('base', 'text')}
      title="Start demo"
      onPress={() => navigation.push('game', { game: 'demo' })}
    />
  ); */
}
