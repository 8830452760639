import * as React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

import { Image } from '../Image';
import { Styles } from '../theme';
import { BackgroundContext, BackgroundProps } from './Background';

export function BackgroundView() {
  const background = React.useContext(BackgroundContext);
  const [items, setItems] = React.useState<BackgroundProps[]>([]);
  // @ts-expect-error Property 'setItems' is private and only accessible within class 'BackgroundData'
  background.setItems = setItems;
  const props = items.length ? items[0] : undefined;
  const { image, rotate = false } = props ?? {};
  return (
    <View style={styles.container}>
      <Image
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        source={image || require('../../assets/images/background.jpg')}
        style={rotate ? styles.rotate : styles.regular}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Styles.fixedFillObject,
    overflow: 'hidden',
    // backgroundColor: '#FCEBC4',
  },
  regular: {
    ...StyleSheet.absoluteFillObject,
    resizeMode: 'cover',
  },
  rotate: Platform.select({
    web: {
      flex: 1,
      resizeMode: 'cover',
      // @ts-ignore
      animationDuration: '60s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationKeyframes: [
        {
          from: { transform: [{ rotateZ: '0deg' }, { scale: 2.5 }] },
          to: { transform: [{ rotateZ: '360deg' }, { scale: 2.5 }] },
        },
      ],
    },
    default: {
      flex: 1,
      resizeMode: 'cover',
    },
  }),
});
