// import { toJS } from 'mobx';

import { Games } from './Games';
import { query, where, orderBy, limit } from './firebase';
import { Auth } from './framework';

export class MyActiveGames {
  public readonly created = new Games('games', {
    query: (ref) =>
      Auth.getInstance().user
        ? query(
            ref,
            where('ownerId', '==', Auth.getInstance().userId),
            where('status', '!=', 'ended'),
            limit(30)
          )
        : null,
  });

  public readonly joined = new Games('games', {
    query: (ref) =>
      Auth.getInstance().user
        ? query(
            ref,
            where('playerIds', 'array-contains', Auth.getInstance().userId),
            orderBy('date', 'desc'),
            limit(30)
          )
        : null,
  });

  get hasDocs() {
    return this.created.hasDocs || !!this.joined.docs.find((doc) => doc.status !== 'ended');
  }

  get docs() {
    // return toJS(this.created.docs)
    const { docs } = this.created;
    return [
      ...docs,
      ...this.joined.docs.filter(
        (doc) => doc.status !== 'ended' && !docs.find((d) => d.id === doc.id)
      ),
    ];
  }

  get isLoading() {
    return this.created.isLoading || this.joined.isLoading;
  }
}
