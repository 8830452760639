import * as React from 'react';

import { List, useToast } from '../components';
import { store, observer } from '../store';
import { t, useNavigation, useRoute } from '../utils';

export default observer(function TransferTemplateScreen() {
  const { collectTemplate } = store;
  const [error, setError] = React.useState<Error | undefined>();
  const route = useRoute();
  const navigation = useNavigation();
  const { token } = route.params;
  const toast = useToast();
  React.useEffect(() => {
    collectTemplate({ token }).then(
      (template) => {
        navigation.replace(`template`, {
          template,
        });
        toast.show({ title: t('Template successfully transfered') });
      },
      (err) => {
        setError(new Error(`${t('Transfer failed')}: ${err.message}`));
      }
    );
  }, []);
  return <List loading={!error} error={error} />;
});
