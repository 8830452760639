import * as React from 'react';
import { View, ScrollView, useWindowDimensions, StyleSheet } from 'react-native';

import { List, Styles, useTheme, useHeaderHeight, Theme, Background } from '../components';
import DemoGameSlider from '../demo/DemoGameSlider';
import { store, observer } from '../store';
import { useGame } from '../utils';
import GameHeaderContainer from './GameHeaderContainer';
import GameStatusView from './GameStatusView';
import { CellContainer, layoutCells } from './cell';
import { renderGameState } from './gameStates';

export default observer(function GameScreen() {
  const { auth } = store;
  const { c } = useTheme();
  const game = useGame()!;
  const { isLoading, phase } = game;
  const { width, height } = useWindowDimensions();
  const { headerHeight } = useHeaderHeight();

  if (auth.isLoading || isLoading) {
    return <List loading />;
  }
  const isHeaderVisible = phase.type === 'wait' || game.status === 'ended';
  return (
    <View style={[styles.container, { backgroundColor: c('base') }]}>
      <Theme accentColor="#D9213C" baseColor="#ffffff" />
      <Background image={require('../assets/images/modal-background.jpg')} />
      <ScrollView style={Styles.flex}>
        <CellContainer width={width} height={height} debugName="GameScreen" clip>
          {layoutCells(
            { width, height, direction: 'column' },
            renderGameState({
              game,
              phaseType: phase.type,
              phaseStatus: phase.status,
              height,
              headerHeight,
            })
          )}
        </CellContainer>
      </ScrollView>
      <GameStatusView game={game} />
      <GameHeaderContainer game={game} phase={phase} alwaysVisible={isHeaderVisible} />
      {game.isDemo ? <DemoGameSlider game={game} /> : undefined}
      {/* <View style={[styles.borders, { borderColor: c('base', 'border') }]} pointerEvents="none" /> */}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    boxSizing: 'border-box',
  },
  /* borders: {
    ...StyleSheet.absoluteFillObject,
    borderBottomWidth: 2,
    borderRightWidth: 2,
  }, */
});
