import * as React from 'react';

import { Sounds } from './Sounds';
import type { SoundName } from './effects';

export function useSound(sound?: SoundName, initial?: boolean) {
  React.useEffect(() => {
    if (sound && !initial) {
      Sounds.play(sound);
    }
  }, [sound]);
}
