import { Game } from './Game';
import { Games } from './Games';
import { MyActiveGames } from './MyActiveGames';
import { PublicTemplates } from './PublicTemplates';
import { SelectedTemplate } from './SelectedTemplate';
import { Template } from './Template';
import { TemplateRequests } from './TemplateRequests';
import { Templates } from './Templates';
import {
  functions,
  firebaseRegion,
  firebaseConfig,
  httpsCallable,
  query,
  where,
  orderBy,
  limit,
} from './firebase';
import { Auth, asyncAction } from './framework';
import { TemplateType, GameSettingsData } from './types';

export class Store {
  public readonly auth = Auth.getInstance();
  public readonly myTemplates = new Templates('templates', {
    query: (ref) =>
      this.auth.user ? query(ref, where('ownerId', '==', this.auth.user.uid)) : null,
  });
  public readonly games = new Games('games', {
    query: (ref) => query(ref, orderBy('date', 'desc'), limit(10)),
  });
  public readonly myPlayedGames = new Games('games', {
    query: (ref) =>
      this.auth.user
        ? query(
            ref,
            where('playerIds', 'array-contains', this.auth.userId),
            orderBy('date', 'desc'),
            limit(10)
          )
        : null,
  });
  public readonly myActiveGames = new MyActiveGames();
  public readonly adminPendingRequests = new TemplateRequests('templateRequests', {
    query: (ref) =>
      this.auth.isAdmin
        ? query(ref, where('pending', '==', true), orderBy('date', 'desc'), limit(10))
        : null,
  });
  public readonly adminRequestHistory = new TemplateRequests('templateRequests', {
    query: (ref) =>
      this.auth.isAdmin
        ? query(ref, where('pending', '==', false), orderBy('date', 'desc'), limit(30))
        : null,
  });
  public readonly publishedTemplates = new PublicTemplates('publicTemplateLists', {
    // TODO query bylocale
  });
  public readonly selectedTemplate = new SelectedTemplate({
    privateTemplates: this.myTemplates,
    publishedTemplates: this.publishedTemplates,
  });
  public readonly myCreatedGames = new Games('games', {
    query: (ref) =>
      this.auth.user
        ? query(ref, where('ownerId', '==', this.auth.userId), orderBy('date', 'desc'), limit(10))
        : null,
  });

  createGame = asyncAction(
    async (config: {
      title: string;
      templateId: string;
      templateType: TemplateType;
      autoJoinName?: string;
      settings?: Partial<GameSettingsData>;
    }) => {
      const { data } = await httpsCallable(
        functions,
        'apiv1'
      )({
        api: 'game',
        action: 'create',
        ...config,
      });
      return new Game(`games/${(data as any).id}`);
    }
  );

  collectTemplate = asyncAction(async (config: { token: string }) => {
    const { data } = await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'template',
      action: 'collect',
      ...config,
    });
    return new Template(`templates/${(data as any).id}`);
  });

  uploadAssets = asyncAction(async (objectUrls: string[]) => {
    if (!objectUrls.length) return [];
    const formData = new FormData();
    const blobs = await Promise.all(
      objectUrls.map(async (objectUrl) => {
        const response = await fetch(objectUrl);
        return await response.blob();
      })
    );
    blobs.forEach((blob, idx) => formData.append(`file${idx + 1}`, blob));
    const firebaseIdToken = await Auth.getInstance().getIdToken();
    const response = await fetch(
      `https://${firebaseRegion}-${firebaseConfig.projectId}.cloudfunctions.net/assets`,
      {
        method: 'post',
        headers: { Authorization: `Bearer ${firebaseIdToken}` },
        body: formData,
      }
    );
    const json: string[] = await response.json();
    return json;
  });
}
