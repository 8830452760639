import { Timestamp } from '../store/types';
import { t } from './i18n';

type DateType = number | Date | Timestamp;

const WEEKS_PER_DURATION = 1000 * 60 * 60 * 24 * 7;
const DAYS_PER_DURATION = 1000 * 60 * 60 * 24;
const HOURS_PER_DURATION = 1000 * 60 * 60;
const MINUTES_PER_DURATION = 1000 * 60;

export function toDate(date: DateType): Date {
  if (typeof date === 'number') {
    return new Date(date);
  } else if (!date) {
    return new Date();
    // @ts-ignore Property 'toDate' does not exist on type 'Date | Timestamp'.
  } else if (date.toDate) {
    // @ts-ignore Property 'toDate' does not exist on type 'Date | Timestamp'.
    return date.toDate();
  } else {
    return date as Date;
  }
}

export function toTime(date?: DateType): number {
  if (typeof date === 'number') {
    return date;
  } else if (!date) {
    return 0;
  } else {
    return toDate(date).getTime();
  }
}

export function durationToDisplayString(duration: number): string {
  const weeks = duration / WEEKS_PER_DURATION;
  const days = duration / DAYS_PER_DURATION;
  const hours = duration / HOURS_PER_DURATION;
  const minutes = duration / MINUTES_PER_DURATION;
  if (weeks >= 1) {
    return `${t('$1 w', String(Math.floor(weeks)))}`;
  } else if (days >= 1) {
    return `${t('$1 d', String(Math.floor(days)))}`;
  } else if (hours >= 1) {
    return `${t('$1 h', String(Math.floor(hours)))}`;
  } else {
    return `${t('$1 m', String(Math.floor(minutes)))}`;
  }
}

export function formatDate(date: DateType, full?: boolean): string {
  if (full) {
    return toDate(date).toLocaleString();
  } else {
    return durationToDisplayString(Math.max(Date.now() - toDate(date).getTime(), 0));
  }
}
