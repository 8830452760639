import { StyleSheet, Platform } from 'react-native';

const fixedPosition: 'absolute' = Platform.OS === 'web' ? ('fixed' as any) : 'absolute';
const fixed = { position: fixedPosition };
const fixedFill = { ...fixed, left: 0, top: 0, right: 0, bottom: 0 };

export const Styles = StyleSheet.create({
  flex: { flex: 1 },
  fixed,
  fixedObject: fixed,
  fixedFill,
  fixedFillObject: fixedFill,
});
Styles.fixedObject = fixed;
Styles.fixedFillObject = fixedFill;
