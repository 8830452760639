import * as React from 'react';
import { StyleSheet, Text } from 'react-native';

import { ColorName, Styles, useTheme } from './theme';

export function Title(props: {
  children: string;
  color?: ColorName;
  style?: any;
  numberOfLines?: number;
  small?: boolean;
  large?: boolean;
  xlarge?: boolean;
  xxlarge?: boolean;
  center?: boolean;
  flex?: boolean;
}) {
  const { children, color, small, large, xlarge, xxlarge, style, center, flex, ...otherProps } =
    props;
  const { c } = useTheme();
  return (
    <Text
      style={[
        styles.title,
        { color: c('base', 'text') },
        small ? styles.smallTitle : undefined,
        large ? styles.largeTitle : undefined,
        xlarge ? styles.hugeTitle : undefined,
        xxlarge ? styles.massiveTitle : undefined,
        color ? { color: c(color) } : undefined,
        center ? styles.center : undefined,
        flex ? Styles.flex : undefined,
        style,
      ]}
      {...otherProps}>
      {children}
    </Text>
  );
}

const styles = StyleSheet.create({
  center: {
    textAlign: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: '600',
  },
  smallTitle: {
    fontSize: 18,
    fontWeight: '600',
  },
  largeTitle: {
    fontFamily: 'Special Elite',
    lineHeight: 32,
    fontSize: 26,
    fontWeight: '700',
  },
  hugeTitle: {
    fontFamily: 'Special Elite',
    fontSize: 36,
    fontWeight: '700',
  },
  massiveTitle: {
    fontFamily: 'Special Elite',
    fontSize: 50,
    fontWeight: '700',
  },
});
