import * as React from 'react';
import { useWindowDimensions } from 'react-native';

import { useTheme, ColorName } from '../../components';
import { Cell } from './Cell';

export function CellBorder(
  props: Omit<React.ComponentProps<typeof Cell>, 'style'> & {
    thickness?: number;
    color?: ColorName;
    edge: 'top' | 'left' | 'right' | 'bottom';
  }
) {
  const { width = 0, height = 0, thickness = 2, edge, color, debugName, ...otherProps } = props;
  const { c } = useTheme();
  const size = useWindowDimensions();
  const horizontal = edge === 'top' || edge === 'bottom';
  let left = props.left ?? 0;
  let top = props.top ?? 0;
  switch (edge) {
    case 'left':
      top = height - size.height;
      break;
    case 'top':
      left = width - size.width;
      break;
    case 'right':
      top = height - size.height;
      left = width - thickness;
      break;
    case 'bottom':
      top = height - thickness;
      left = width - size.width;
      break;
  }
  return (
    <Cell
      {...otherProps}
      style={{
        backgroundColor: color ? c(color) : c('base', 'border'),
        width: horizontal ? size.width : thickness,
        height: !horizontal ? size.height : thickness,
      }}
      left={left}
      top={top}
      debugName={debugName ?? 'CellBackground'}
    />
  );
}
