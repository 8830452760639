import * as React from 'react';
import { StyleSheet } from 'react-native';

import { List, useToast } from '../components';
import { observer } from '../store';
import { t, usePublishedTemplate, useNavigation } from '../utils';

export default observer(function UnpublishTemplateScreen() {
  const navigation = useNavigation();
  const toast = useToast();
  const publishedTemplate = usePublishedTemplate()!;
  const [message, setMessage] = React.useState('');
  const { isLoading } = publishedTemplate;

  return (
    <List>
      <List.TextInput
        style={styles.messageInput}
        placeholder={t('What is the reason for revoking the publication?')}
        autoFocus
        multiline
        maxLength={1000}
        value={message}
        onChangeText={setMessage}
      />
      <List.Button
        title={t('Revoke publication')}
        destructive
        onPress={async () => {
          await publishedTemplate.unpublish({
            message,
          });
          toast.show({ title: t('Request sent') });
          navigation.goBack();
        }}
        disabled={isLoading || !message.length}
      />
    </List>
  );
});

const styles = StyleSheet.create({
  messageInput: {
    height: 200,
  },
});
