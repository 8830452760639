import type { Document } from 'firestorter';
import map from 'lodash/map';
import { computed, IComputedValue, toJS } from 'mobx';

import { DocumentFragment } from './DocumentFragment';

export type DocumentFragmentArraySortFn<T> = (a: T, b: T) => number;

export class DocumentFragmentArray<
  T extends DocumentFragment = DocumentFragment
> extends DocumentFragment<{
  [key: string]: T;
}> {
  private readonly lookup: {
    [id: string]: T;
  } = {};
  private prevArray: T[];
  private readonly computedArray: IComputedValue<T[]>;
  private readonly createFragment: (key: string) => T;
  private readonly sortFn?: DocumentFragmentArraySortFn<T>;

  constructor(
    doc: Document,
    fieldPath: string,
    createFragment: (key: string) => T,
    sortFn?: DocumentFragmentArraySortFn<T>
  ) {
    super(doc, fieldPath);
    this.createFragment = createFragment;
    // createFragment ?? ((key: string) => new DocumentFragment(doc, `${fieldPath}.${key}`));
    this.prevArray = [];
    this.sortFn = sortFn;
    this.computedArray = computed(() => {
      const newArray = map(this.data, (_value, key) => {
        const fragment = this.lookup[key] ?? this.createFragment(key);
        this.lookup[key] = fragment;
        return fragment;
      });

      this.prevArray.forEach((fragment) => {
        if (newArray.indexOf(fragment) < 0) {
          delete this.lookup[fragment.id];
        }
      });

      if (this.sortFn) {
        newArray.sort(this.sortFn);
      }

      this.prevArray = newArray;
      return newArray;
    });
  }

  toArray() {
    return toJS(this.computedArray.get());
  }

  map<R>(callbackfn: (value: T, index: number) => R) {
    return this.toArray().map(callbackfn);
  }

  get length() {
    return this.computedArray.get().length;
  }

  getAt(index: number) {
    return this.computedArray.get()[index];
  }

  has(id: string) {
    return !!this.get(id);
  }

  get(id: string): T | undefined {
    this.computedArray.get();
    return this.lookup[id];
  }
}
