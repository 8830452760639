import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import type { AnimationProps } from './AnimationContainer';

export function Animation(props: AnimationProps) {
  const { id, layout, style, onLayout, onExit, children, config, unmounted } = props;

  // When neither onLayout or onExit is specified, this node is considered an
  // invisible shadow node and does not need to evaluate anything.
  if (!onLayout && !onExit) {
    return <View style={style}>{children}</View>;
  }

  // When onLayout is specified, render an invisible (shadow) node that is used
  // to calculate the position and size of the element.
  if (onLayout) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = React.useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      if (onLayout) {
        // @ts-ignore
        const rect = ref.current?.getBoundingClientRect();
        onLayout(id, {
          // @ts-ignore
          x: ref.current?.offsetLeft,
          // @ts-ignore
          y: ref.current?.offsetTop,
          width: rect.width,
          height: rect.height,
        });
      }
    });
    return (
      <View ref={ref} style={[style, styles.layoutNode]}>
        {children}
      </View>
    );
  }

  const { enter, exit, layout: layoutConfig } = config;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (unmounted) {
      const timer = setTimeout(
        () => onExit?.(id),
        ((exit?.duration ?? 1) + (exit?.delay ?? 0)) * 1000
      );
      return () => clearTimeout(timer);
    }
  }, [unmounted]);
  return (
    <View
      style={{
        ...StyleSheet.absoluteFillObject,
        opacity: layout ? (unmounted ? exit?.opacity ?? 1 : 1) : enter?.opacity ?? 0,
        transform: layout
          ? unmounted
            ? exit?.transform ?? undefined
            : undefined
          : enter?.transform ?? undefined,
        // @ts-ignore: Web transitions
        transitionProperty: 'transform, opacity',
        transitionDuration: `${(unmounted ? exit : enter)?.duration ?? 1}s`,
        transitionDelay: `${(unmounted ? exit : enter)?.delay ?? 0}s`,
        // transitionTimingFunction: 'ease',
      }}
      pointerEvents="box-none">
      {layout ? (
        <View
          pointerEvents="auto"
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: layout.width,
            height: layout.height,
            transform: [{ translateX: layout.x }, { translateY: layout.y }],
            // @ts-ignore: Web transitions
            transitionProperty: 'transform',
            transitionDuration: `${layoutConfig?.duration ?? 1}s`,
            // transitionTimingFunction: 'ease-out',
          }}>
          {children}
        </View>
      ) : undefined}
    </View>
  );
}

const styles = StyleSheet.create({
  layoutNode: {
    opacity: 0,
  },
});
