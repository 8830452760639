export const sounds = {
  alien: require('./alien.mp3'),
  baby: require('./baby.m4a'),
  bell: require('./bell.mp3'),
  bomb: require('./bomb.m4a'),
  // bomb: require('./bomb.mp3'),
  cat: require('./cat.mp3'),
  cowboy: require('./cowboy.m4a'),
  duck: require('./duck.m4a'),
  droid: require('./droid.mp3'),
  droplet: require('./droplet.mp3'),
  drumroll: require('./drumroll.mp3'),
  drums: require('./drums.mp3'),
  fart: require('./fart.m4a'),
  frog: require('./frog.mp3'),
  // ghost: require('./ghost.mp3'),
  ghost: require('./ghost.m4a'),
  guitar: require('./guitar.mp3'),
  horn: require('./horn.mp3'),
  human: require('./human.mp3'),
  laugh: require('./laugh.mp3'),
  laser: require('./laser.mp3'),
  poodle: require('./poodle.mp3'),
  trumpet: require('./trumpet.mp3'),
  piano: require('./piano.m4a'),
  owl: require('./owl.m4a'),
  bee: require('./bee.m4a'),
  scooter: require('./scooter.m4a'),
  horse: require('./horse.m4a'),
  elephant: require('./elephant.m4a'),
  ogre: require('./ogre.m4a'),
  tongue: require('./tongue.m4a'),
  motorcycle: require('./motorcycle.m4a'),
  surfer: require('./surfer.m4a'),
};

export type SoundName = keyof typeof sounds;
