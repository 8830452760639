import * as React from 'react';
import { StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';

import { IAsyncAction, observer } from '../store';
import { Icon, IconName } from './Icon';
import { Title } from './Text';
import { ColorName, useTheme, ColorState, Shadows } from './theme';
import { useToast } from './toast';

export type ButtonType = 'filled' | 'outline' | 'text';

export const Button = observer(
  (props: {
    title?: string;
    icon?: IconName;
    color?: ColorName;
    onPress?: (() => any) | IAsyncAction<any, any>;
    disabled?: boolean;
    loading?: boolean;
    destructive?: boolean;
    confirmed?: boolean;
    separator?: boolean;
    header?: boolean | 'left' | 'right';
    pressed?: boolean;
    small?: boolean;
    style?: any;
    type?: 'filled' | 'outline' | 'text';
  }) => {
    const { small, title, icon, disabled, header, pressed, separator, onPress, style } = props;
    const toast = useToast();
    const { c } = useTheme();
    const [inProgress, setInProgress] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(props.confirmed ?? false);
    const [layout, setLayout] = React.useState<any>(() => ({}));
    const destructive = props.destructive && (!header || confirmed);

    let type: ButtonType = 'filled';
    if (destructive && confirmed) {
      type = 'filled';
    } else if (props.type) {
      type = props.type;
    } else if (header) {
      type = 'text';
    } else if (destructive && !confirmed) {
      type = 'outline';
    } else {
      type = 'filled';
    }
    const isFilled = type === 'filled';

    const colorState: ColorState = disabled ? 'disabled' : pressed ? 'pressed' : 'neutral';
    let backgroundColor;
    let textColor;
    let borderColor;
    // Special case for header buttons, use text-color for header
    if (header && !props.color && !destructive && !confirmed) {
      backgroundColor = 'transparent';
      textColor = c('accent', 'text', colorState);
      borderColor = 'transparent';
    } else {
      const color = props.color ?? (destructive ? 'error' : 'accent');
      backgroundColor = isFilled ? c(color, 'back', colorState) : 'transparent';
      textColor = isFilled ? c(color, 'text', colorState) : c(color, 'back', colorState);
      borderColor =
        type === 'text'
          ? 'transparent'
          : type === 'outline'
          ? textColor
          : c(color, 'border', colorState);
    }

    // @ts-ignore TODO
    const loading = props.loading ?? onPress?.inProgress ?? inProgress;

    return (
      <TouchableOpacity
        style={[
          styles.container,
          icon && !title ? styles.icon : undefined,
          small ? styles.small : undefined,
          small && icon && !title ? styles.smallIcon : undefined,
          header ? styles.header : undefined,
          header === 'left' ? styles.headerLeft : undefined,
          separator
            ? {
                borderTopColor: c('surface', 'back', 'disabled'),
                borderTopWidth: StyleSheet.hairlineWidth,
                borderRadius: 0,
              }
            : undefined,
          isFilled ? Shadows.regular : undefined,
          { backgroundColor, borderColor, borderWidth: type === 'text' ? 0 : 2 },
          loading && layout ? { width: layout.width, height: layout.height } : undefined,
          style,
        ]}
        activeOpacity={0.5}
        onPress={
          onPress
            ? async () => {
                if (props.destructive && !confirmed) {
                  return setConfirmed(true);
                }
                try {
                  setInProgress(true);
                  // @ts-ignore TODO
                  await onPress();
                  setConfirmed(() => false);
                  setInProgress(() => false);
                } catch (err) {
                  toast.error(err as Error);
                  setInProgress(() => false);
                }
              }
            : undefined
        }
        onLayout={(event) => setLayout(() => event.nativeEvent.layout)}
        // eslint-disable-next-line
        disabled={disabled || loading || !onPress}>
        {loading ? (
          <ActivityIndicator size="small" color={textColor} />
        ) : icon && (!title || !confirmed) ? (
          <Icon name={icon} size={small ? 'small' : 'regular'} color={textColor} />
        ) : (
          <Title small={small} color={textColor}>
            {title ?? ''}
          </Title>
        )}
      </TouchableOpacity>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    paddingHorizontal: 10,
  },
  small: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 4,
  },
  smallIcon: {
    paddingHorizontal: 4,
  },
  header: {
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 6,
    marginRight: 8,
    height: 42,
  },
  headerLeft: {
    marginLeft: 8,
    marginRight: 0,
  },
});
