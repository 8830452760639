import { Platform } from 'react-native';

type IsType = 'mobile' | 'web' | 'native' | 'development';

const userAgent = Platform.OS === 'web' ? navigator.userAgent : '';

export function is(type: IsType): boolean {
  switch (type) {
    case 'mobile':
      return Platform.OS !== 'web' || userAgent.includes('Mobi');
    case 'web':
      return Platform.OS === 'web';
    case 'native':
      return Platform.OS !== 'web';
    case 'development':
      return process.env.NODE_ENV === 'development';
    default:
      return false;
  }
}
