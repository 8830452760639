import * as React from 'react';

import { List, Badge } from '../components';
import { observer, Game } from '../store';
import { t, useNavigation, formatDate } from '../utils';

export default observer(function GameListItem(
  props: Partial<React.ComponentProps<typeof List.Item>> & {
    game: Game;
  }
) {
  const { game, ...otherProps } = props;
  const { title, status, date } = game;
  const navigation = useNavigation();
  return (
    <List.Item
      title={title}
      highlight={status !== 'ended'}
      value={status === 'ended' ? formatDate(date) : <Badge title={t('Live')} color="surface" />}
      onPress={() => navigation.push('game', { game: game.status !== 'ended' ? game.id : game })}
      {...otherProps}
    />
  );
});
