import { Document, DocumentSource, IDocumentOptions } from 'firestorter';
import { computed, observable, runInAction } from 'mobx';

import { demoGameContext } from '../demo/DemoGameContext';
import { toTime } from '../utils';
import { GamePlayer } from './GamePlayer';
import { functions, httpsCallable } from './firebase';
import { asyncAction, Auth, DocumentFragmentArray } from './framework';
import type { GamePublicData } from './types';

export type GameClientPhaseType =
  | 'wait'
  | 'intro'
  | 'lie'
  | 'choose'
  | 'votes'
  | 'choice'
  | 'truth'
  | 'roundScore'
  | 'totalScore';
export type GameClientPhaseStatus =
  | 'enter'
  | 'waitForInput'
  | 'waitForInputCritical'
  | 'inputGiven'
  | 'noInput'
  | 'exit';
export type GameClientPhaseProgress = {
  value: number;
  startTime: number;
  endTime: number;
  pausedValue?: number;
};
export type GameClientPhase = {
  type: GameClientPhaseType;
  status: GameClientPhaseStatus;
  progress: GameClientPhaseProgress;
};
type GameClientPhaseConfig = {
  time: number;
  type: GameClientPhaseType;
  status: GameClientPhaseStatus;
  startOfProgress?: boolean;
};
export type GameClientChoice = {
  text: string;
  liedBy: GamePlayer[];
  chosenBy: GamePlayer[];
  score: number;
};

export class Game extends Document<GamePublicData> {
  public readonly players = new DocumentFragmentArray<GamePlayer>(
    this,
    'players',
    (id: string) => new GamePlayer(this, `players.${id}`)
  );
  private timeCorrection = 0;
  private scheduledTickTimer: number | undefined;
  private scheduledTickTime = 0;
  private readonly scheduledTickObservable = observable.box(0);

  constructor(source: DocumentSource, options?: IDocumentOptions) {
    super(source, {
      ...(options ?? {}),
      context: demoGameContext,
    });
  }

  get title() {
    return this.data.title;
  }

  get status() {
    return this.data.status;
  }

  get date() {
    return this.data.date;
  }

  private updateTimeCorrection() {
    const now = Date.now();
    const { date, phaseStartAt, phasePausedAt } = this.data;
    let timeCorrection = Math.max(toTime(date) - now, 0);
    timeCorrection = Math.max(toTime(phaseStartAt) - now, timeCorrection);
    timeCorrection = Math.max(toTime(phasePausedAt) - now, timeCorrection);
    this.timeCorrection = Math.max(timeCorrection, this.timeCorrection);
  }

  private getPhase(configs: GameClientPhaseConfig[]): GameClientPhase {
    this.updateTimeCorrection();
    const now = Date.now();
    const startTime = (toTime(this.data.phaseStartAt) || now) - this.timeCorrection;
    const duration = this.data.phaseDuration ?? 1;
    const endTime = startTime + duration;
    const pausedTime = this.data.phasePausedAt
      ? toTime(this.data.phasePausedAt) - this.timeCorrection
      : undefined;
    const relativeTime = pausedTime ? pausedTime - startTime : now - startTime;
    let config = configs[0];
    const player = this.me;
    const playerIndex = player ? this.players.toArray().indexOf(player) : -1;
    let nextConfigTime =
      this.status === 'started' && playerIndex >= 0
        ? duration + (this.isHost ? 300 : 1000 + playerIndex * 1000)
        : 0;
    const startOfProgressTime = configs.find((c) => c.startOfProgress)?.time ?? config.time;
    for (let i = 1; i < configs.length; i++) {
      if (relativeTime < configs[i].time) {
        nextConfigTime = configs[i].time;
        break;
      }
      config = configs[i];
    }
    if (nextConfigTime) {
      this.scheduledTickObservable.get();
      const scheduledTime = startTime + nextConfigTime;
      if (this.scheduledTickTime !== scheduledTime) {
        this.scheduledTickTime = scheduledTime;
        if (this.scheduledTickTimer) {
          clearTimeout(this.scheduledTickTimer);
          this.scheduledTickTimer = undefined;
        }
        this.scheduledTickTimer = setTimeout(() => {
          this.scheduledTickTimer = undefined;
          runInAction(() => {
            this.scheduledTickObservable.set(scheduledTime);
          });
          if (Date.now() > endTime && !this.tick.inProgress) {
            console.log('Game phase has expired, ticking...');
            this.tick({});
          }
        }, Math.max(scheduledTime - now, 100)) as any;
      }
    }
    return {
      type: config.type,
      status: config.status,
      progress: {
        value: (relativeTime - startOfProgressTime) / (duration - startOfProgressTime),
        startTime: relativeTime < startOfProgressTime ? 0 : startTime + startOfProgressTime,
        endTime,
        pausedValue:
          relativeTime < startOfProgressTime
            ? 0
            : pausedTime
            ? (pausedTime - startTime - startOfProgressTime) / (duration - startOfProgressTime)
            : undefined,
      },
    };
  }

  get phase(): GameClientPhase {
    const { phase } = this.data;
    const { status, question, me, lie, choose } = this;
    switch (status) {
      case 'notStarted':
        return this.getPhase([{ time: 0, type: 'wait', status: 'noInput' }]);
      case 'ended':
        return this.getPhase([{ time: 0, type: 'totalScore', status: 'noInput' }]);
    }
    const duration = this.data.phaseDuration ?? 0;
    switch (phase) {
      // TODO remove intro phase server-side and integrate with initial lie phase?
      case 'intro':
        return this.getPhase([{ time: 0, type: 'intro', status: 'noInput' }]);
      case 'lie':
        return this.getPhase([
          { time: 0, type: 'lie', status: 'enter' },
          {
            time: 4000,
            type: 'lie',
            status: me
              ? me.hasLied || (lie.request?.question === question && lie.response)
                ? 'inputGiven'
                : 'waitForInput'
              : 'noInput',
            startOfProgress: true,
          },
          {
            time: duration * 0.7,
            type: 'lie',
            status: me
              ? me.hasLied || (lie.request?.question === question && lie.response)
                ? 'inputGiven'
                : 'waitForInputCritical'
              : 'noInput',
          },
        ]);
      case 'choose':
        return this.getPhase([
          {
            time: 0,
            type: 'choose',
            status: me
              ? choose.inProgress ||
                me?.hasChosen ||
                (choose.request?.question === question && choose.response)
                ? 'inputGiven'
                : 'waitForInput'
              : 'noInput',
          },
          {
            time: duration * 0.7,
            type: 'choose',
            status: me
              ? choose.inProgress ||
                me?.hasChosen ||
                (choose.request?.question === question && choose.response)
                ? 'inputGiven'
                : 'waitForInputCritical'
              : 'noInput',
          },
        ]);
      case 'results':
        return this.getPhase([
          { time: 0, type: 'votes', status: 'enter' },
          { time: duration * 0.03, type: 'votes', status: 'noInput' },
          { time: duration * 0.18, type: 'votes', status: 'exit' },
          { time: duration * 0.2, type: 'choice', status: 'enter' },
          { time: duration * 0.23, type: 'choice', status: 'noInput' },
          { time: duration * 0.38, type: 'choice', status: 'exit' },
          { time: duration * 0.4, type: 'truth', status: 'enter' },
          { time: duration * 0.43, type: 'truth', status: 'noInput' },
          { time: duration * 0.58, type: 'truth', status: 'exit' },
          { time: duration * 0.6, type: 'roundScore', status: 'noInput' },
          { time: duration * 0.8, type: 'totalScore', status: 'noInput' },
        ]);
      default:
        return this.getPhase([{ time: 0, type: 'totalScore', status: 'noInput' }]);
    }
  }

  get question() {
    return this.data.question;
  }

  get questionSound() {
    return this.data.questionSound;
  }

  get questionImage() {
    return this.data.questionImage;
  }

  get questionColor() {
    return this.data.questionColor;
  }

  computedChoices = computed(() => {
    const { choices } = this.data;
    const players = this.players.toArray().sort((a, b) => a.time - b.time);
    return (
      choices?.map((choice) => {
        const chosenBy = players.filter((p) => p.choice === choice);
        return {
          text: choice,
          liedBy: players.filter((p) => p.lie === choice),
          chosenBy,
          score: chosenBy.length * 500,
        };
      }) ?? []
    );
  });

  get choices(): GameClientChoice[] {
    return this.computedChoices.get();
  }

  get myLie(): string | undefined {
    const { me, lie, question } = this;
    return lie.request?.question === question && lie.request?.lie && !lie.error
      ? lie.request?.lie
      : me?.hasLied
      ? me?.lie
      : undefined;
  }

  get myChoice(): string | undefined {
    const { me, choose, question } = this;
    return choose.request?.question === question && choose.request?.choice && !choose.error
      ? choose.request?.choice
      : me?.hasChosen
      ? me?.choice
      : undefined;
  }

  get truth() {
    return this.data.truth;
  }

  get truthSound() {
    return this.data.truthSound;
  }

  get truthImage() {
    return this.data.truthImage;
  }

  get truthColor() {
    return this.data.truthColor;
  }

  get me() {
    return this.players.get(Auth.getInstance().userId);
  }

  get isHost(): boolean {
    return this.data.ownerId === Auth.getInstance().userId;
  }

  /* Demo */

  get isDemo() {
    return this.data.ownerId === 'demo';
  }

  get demoProgress(): number {
    // @ts-ignore
    return this.data.demoProgress ?? 0;
  }
  set demoProgress(demoProgress: number) {
    this.update({ demoProgress });
  }

  get isDemoPaused(): boolean {
    // @ts-ignore
    return this.data.isDemoPaused;
  }
  set isDemoPaused(isDemoPaused: boolean) {
    this.update({ isDemoPaused });
  }

  /* Player API */

  join = asyncAction(async () => {
    if (this.isDemo) return;
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'player',
      action: 'join',
      gameId: this.id,
      name: Auth.getInstance().userName,
    });
  });

  leave = asyncAction(async () => {
    if (this.isDemo) return;
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'player',
      action: 'leave',
      gameId: this.id,
    });
  });

  readyToStart = asyncAction(async () => {
    if (this.isDemo) return;
    this.me?.playTrustedSound();
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'player',
      action: 'ready',
      gameId: this.id,
    });
  });

  lie = asyncAction(async (config: { lie: string; question: string }) => {
    if (this.isDemo) return;
    this.me?.playTrustedSound();
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'player',
      action: 'lie',
      gameId: this.id,
      lie: config.lie.toUpperCase().trim(),
    });
  });

  choose = asyncAction(async (config: { choice: string; question: string }) => {
    if (this.isDemo) return;
    this.me?.playTrustedSound();
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'player',
      action: 'choose',
      gameId: this.id,
      choice: config.choice,
    });
  });

  /* Host API */

  start = asyncAction(async () => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'game',
      action: 'start',
      gameId: this.id,
    });
  });

  pause = asyncAction(async () => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'game',
      action: 'pause',
      gameId: this.id,
    });
  });

  resume = asyncAction(async () => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'game',
      action: 'resume',
      gameId: this.id,
    });
  });

  end = asyncAction(async () => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'game',
      action: 'end',
      gameId: this.id,
    });
  });

  tick = asyncAction(async () => {
    if (this.isDemo) return;
    const { status, phase } = this.data;
    const { data } = await httpsCallable(
      functions,
      'apiv1'
    )({
      api: this.isHost ? 'game' : 'player',
      action: 'tick',
      gameId: this.id,
    });
    if (status === (data as any).status && phase === (data as any).phase) {
      console.log('Tick did not yield any changes, retrying...');
      setTimeout(() => {
        if (this.data.status === (data as any).status && this.data.phase === (data as any).phase) {
          this.tick({});
        }
      }, 200);
    }
  });

  deletePermanently = asyncAction(async () => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'game',
      action: 'delete',
      gameId: this.id,
    });
  });
}
