export type SoundRecordingStatus = {
  canRecord: boolean;
  isRecording: boolean;
  isDoneRecording: boolean;
  durationMillis: number;
  metering?: number;
};

export class SoundRecording {
  private stream: MediaStream | undefined = undefined;
  private mediaRecorder: any;
  private data: any[] = [];
  private blob: Blob | undefined;
  private uri: string | undefined;
  private status: SoundRecordingStatus = {
    canRecord: false,
    isRecording: false,
    isDoneRecording: false,
    durationMillis: 0,
  };

  static async createAsync() {
    const recording = new SoundRecording();
    await recording.prepareToRecordAsync();
    await recording.startAsync();
    return recording;
  }

  private onDataAvailable(event: any) {
    if (event.data?.size > 0) {
      this.data.push(event.data);
      this.status.durationMillis += 10;
    }
  }

  async prepareToRecordAsync() {
    this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    // @ts-ignore
    this.mediaRecorder = new MediaRecorder(this.stream);
    this.mediaRecorder.ondataavailable = (event: any) => this.onDataAvailable(event);
    this.status.canRecord = true;
    return this.getStatusAsync();
  }

  async startAsync() {
    this.mediaRecorder.start(10);
    this.status.isRecording = true;
    return this.getStatusAsync();
  }

  async pauseAsync() {
    this.mediaRecorder.pause();
    this.status.isRecording = false;
    return this.getStatusAsync();
  }

  stopAndUnloadAsync() {
    this.mediaRecorder.stop();
    this.status.isRecording = false;
    this.status.isDoneRecording = true;
    this.status.canRecord = false;
    this.blob = new Blob(this.data, { type: 'audio/ogg; codecs=opus' });
    this.uri =
      window.webkitURL?.createObjectURL(this.blob) ?? window.URL?.createObjectURL?.(this.blob);
    return this.getStatusAsync();
  }

  async getStatusAsync(): Promise<SoundRecordingStatus> {
    return Promise.resolve({ ...this.status });
  }

  getURI() {
    return this.uri;
  }
}
