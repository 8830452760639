import * as React from 'react';

import { PureComponent, useTheme, ColorName } from '../components';
import { Cell, CellText, CellBackground, CellBorder } from './cell';

export default function GameText(
  props: Omit<React.ComponentProps<typeof Cell>, 'children'> & {
    text: string;
    color?: ColorName;
    border?: boolean;
  }
) {
  const { color = 'base', text, border, ...cellProps } = props;
  const { c } = useTheme();
  const { width, height } = cellProps;
  return (
    <PureComponent props={[text, cellProps.left, cellProps.top, width, height, c, color, border]}>
      {() => (
        <Cell {...cellProps} clip debugName="DialogueText">
          <CellBackground width={width} height={height} color={color} />
          <CellBorder width={width} height={height} edge="left" color={c(color, 'border')} hidden />
          <CellBorder
            width={width}
            height={height}
            edge="top"
            color={c(color, 'border')}
            hidden={!border}
          />
          <CellBorder
            width={width}
            height={height}
            edge="right"
            color={c(color, 'border')}
            hidden
          />
          <CellBorder
            width={width}
            height={height}
            edge="bottom"
            color={c(color, 'border')}
            hidden
          />
          <CellText width={width} height={height} color={c(color, 'text')} small>
            {text}
          </CellText>
        </Cell>
      )}
    </PureComponent>
  );
}
