import {
  NavigationContainer,
  DefaultTheme,
  LinkingOptions,
  useNavigationState,
} from '@react-navigation/native';
import { createStackNavigator, StackCardInterpolationProps } from '@react-navigation/stack';
import * as React from 'react';
import { StatusBar, StyleSheet, View } from 'react-native';

import AccountScreen from './account/AccountScreen';
import SignInScreen from './account/SignInScreen';
import AdminActionScreen from './admin/AdminActionScreen';
import AdminActionsScreen from './admin/AdminActionsScreen';
import { Icon, useTheme, Shadows, useHeaderHeight } from './components';
import DesignLanguageScreen from './demo/DesignLanguageScreen';
import GameScreen from './game/GameScreen';
import Game3Screen from './game3/GameScreen';
import CreateGameScreen from './home/CreateGameScreen';
import GamesScreen from './home/GamesScreen';
import HomeScreen from './home/HomeScreen';
import SelectTemplateScreen from './home/SelectTemplateScreen';
import PublishTemplateScreen from './publish/PublishTemplateScreen';
import PublishedTemplateScreen from './publish/PublishedTemplateScreen';
import TransferTemplateScreen from './publish/TransferTemplateScreen';
import UnpublishTemplateScreen from './publish/UnpublishTemplateScreen';
import TemplateRequestScreen from './request/TemplateRequestScreen';
import TemplateRequestsScreen from './request/TemplateRequestsScreen';
import TemplateQuestionEditScreen from './template/TemplateQuestionEditScreen';
import TemplateQuestionScreen from './template/TemplateQuestionScreen';
import TemplateScreen from './template/TemplateScreen';
import TemplatesScreen from './template/TemplatesScreen';
import { is, t } from './utils';

const Stack = createStackNavigator();
const ModalStack = createStackNavigator();

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

export type RootStackParamList = {
  home: undefined;
  game: undefined;
  game3: undefined;
  modal: undefined;
};

const linking: LinkingOptions<RootStackParamList> = {
  enabled: true,
  prefixes: [],
  config: {
    initialRouteName: 'home',
    screens: {
      home: '',
      game: {
        path: 'games/:game',
        stringify: {
          game: (game: any) => (typeof game === 'string' ? game : game.id),
        },
      },
      game3: {
        path: 'games3/:game',
        stringify: {
          game: (game: any) => (typeof game === 'string' ? game : game.id),
        },
      },
      modal: {
        screens: {
          account: 'account',
          signin: 'signin',
          games: 'games',
          templates: 'templates',
          template: {
            path: 'templates/:template',
            stringify: {
              template: (template: any) => (typeof template === 'string' ? template : template.id),
            },
          },
          question: {
            path: 'templates/:template/:questionId',
            stringify: {
              template: (template: any) => (typeof template === 'string' ? template : template.id),
            },
          },
          create: 'create',
          select: 'select',
          requests: 'requests',
          request: {
            path: 'requests/:templateRequest',
            stringify: {
              templateRequest: (request: any) =>
                typeof request === 'string' ? request : request.id,
            },
          },
          requestQuestion: {
            path: 'requests/:templateRequest/:questionId',
            stringify: {
              templateRequest: (request: any) =>
                typeof request === 'string' ? request : request.id,
            },
          },
          publishedTemplates: 'published',
          publishedTemplate: {
            path: 'published/:publishedTemplate',
            stringify: {
              publishedTemplate: (template: any) =>
                typeof template === 'string' ? template : template.id,
            },
          },
          publishedQuestion: {
            path: 'published/:publishedTemplate/:questionId',
            stringify: {
              publishedTemplate: (template: any) =>
                typeof template === 'string' ? template : template.id,
            },
          },
          publish: {
            path: 'publish/:template',
            stringify: {
              template: (template: any) => (typeof template === 'string' ? template : template.id),
            },
          },
          publishQuestion: {
            path: 'publish/:template/:questionId',
            stringify: {
              template: (template: any) => (typeof template === 'string' ? template : template.id),
            },
          },
          unpublish: {
            path: 'unpublish/:publishedTemplate',
            stringify: {
              publishedTemplate: (template: any) =>
                typeof template === 'string' ? template : template.id,
            },
          },
          transfer: 'transfer',
          design: 'design',
          adminActions: 'adminActions',
          adminAction: 'adminAction',
        },
      },
    },
  },
};

function cardStyleInterpolator(props: StackCardInterpolationProps) {
  return { cardStyle: { opacity: props.next ? 0 : 1 } };
}

function BackButton() {
  const { c } = useTheme();
  const state = useNavigationState((state) => state);
  return <Icon name={state?.index === 0 ? 'close' : 'back'} color={c('accent', 'text')} />;
}

function ModalStackScreen() {
  const { c } = useTheme();
  const { headerHeight } = useHeaderHeight();
  return (
    <View style={styles.modalContainer}>
      <View style={styles.modalContent}>
        <ModalStack.Navigator
          screenOptions={{
            cardStyle: { backgroundColor: c('base'), flex: 1 },
            headerStyle: {
              backgroundColor: c('accent'),
              borderBottomWidth: 0,
              height: headerHeight,
              ...Shadows.regular,
            },
            headerTintColor: c('accent', 'text'),
            headerTitleStyle: { fontWeight: 'bold' },
            headerBackImage: BackButton,
            cardStyleInterpolator,
          }}>
          <ModalStack.Screen
            name="signin"
            component={SignInScreen}
            options={{ title: t('Sign in') }}
          />
          <ModalStack.Screen
            name="account"
            component={AccountScreen}
            options={{ title: t('Account') }}
          />
          <ModalStack.Screen
            name="templates"
            component={TemplatesScreen}
            options={{ title: t('My templates') }}
          />
          <ModalStack.Screen
            name="template"
            component={TemplateScreen}
            options={{ title: t('Template') }}
          />
          <ModalStack.Screen
            name="question"
            component={TemplateQuestionEditScreen}
            options={{ title: t('Question') }}
          />
          <ModalStack.Screen
            name="select"
            component={SelectTemplateScreen}
            options={{ title: t('Select template') }}
          />
          <ModalStack.Screen
            name="publish"
            component={PublishTemplateScreen}
            options={{ title: t('Make template public') }}
          />
          <ModalStack.Screen
            name="publishQuestion"
            component={TemplateQuestionScreen}
            options={{ title: t('Question') }}
          />
          <ModalStack.Screen
            name="unpublish"
            component={UnpublishTemplateScreen}
            options={{ title: t('Revoke publication') }}
          />
          <ModalStack.Screen
            name="transfer"
            component={TransferTemplateScreen}
            options={{ title: t('Transfer ownership') }}
          />
          <ModalStack.Screen
            name="request"
            component={TemplateRequestScreen}
            options={{ title: t('Request') }}
          />
          <ModalStack.Screen
            name="requestQuestion"
            component={TemplateQuestionScreen}
            options={{ title: t('Question') }}
          />
          <ModalStack.Screen
            name="requests"
            component={TemplateRequestsScreen}
            options={{ title: t('Requests') }}
          />
          <ModalStack.Screen
            name="publishedTemplate"
            component={PublishedTemplateScreen}
            options={{ title: t('Public template') }}
          />
          <ModalStack.Screen
            name="publishedQuestion"
            component={TemplateQuestionScreen}
            options={{ title: t('Question') }}
          />
          <ModalStack.Screen
            name="create"
            component={CreateGameScreen}
            options={{ title: t('Create game') }}
          />
          <ModalStack.Screen name="games" component={GamesScreen} options={{ title: t('Games') }} />
          <ModalStack.Screen
            name="adminActions"
            component={AdminActionsScreen}
            options={{ title: 'Administrative actions' }}
          />
          <ModalStack.Screen
            name="adminAction"
            component={AdminActionScreen}
            options={{ title: 'Administrative action' }}
          />
          <ModalStack.Screen
            name="design"
            component={DesignLanguageScreen}
            options={{ title: 'Ontwerp taal' }}
          />
        </ModalStack.Navigator>
      </View>
    </View>
  );
}

export default () => (
  <NavigationContainer theme={navTheme} linking={linking}>
    <StatusBar barStyle="dark-content" />
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: 'transparent', flex: 1 },
        cardStyleInterpolator,
      }}>
      <Stack.Screen name="home" component={HomeScreen} options={{ title: t('Lie or die') }} />
      <Stack.Screen name="game" component={GameScreen} options={{ title: t('Game') }} />
      <Stack.Screen name="game3" component={Game3Screen} options={{ title: t('Game') }} />
      <Stack.Screen name="modal" component={ModalStackScreen} />
    </Stack.Navigator>
  </NavigationContainer>
);

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  modalContent: is('mobile')
    ? {
        flex: 1,
      }
    : {
        flex: 1,
        maxWidth: 800,
        marginVertical: 64,
        overflow: 'hidden',
        borderRadius: 20,
        backgroundColor: '#ffffff33',
        ...Shadows.regular,
      },
});
