import * as React from 'react';
import { View, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';

import type { IAsyncAction } from '../../store';
import { Icon, IconName } from '../Icon';
import { Spacer } from '../Spacer';
import { Title } from '../Text';
import { ColorName, Shadows, useTheme } from '../theme';

export function ListItem(props: {
  small?: boolean;
  title?: string | React.ReactElement<any>;
  color?: ColorName;
  value?: string | React.ReactElement<any>;
  label?: string;
  highlight?: boolean;
  loading?: boolean;
  selected?: boolean;
  icon?: IconName;
  chevron?: boolean;
  numberOfLines?: number;
  onPress?: (() => any) | IAsyncAction<any, any> | null;
  children?: (config: { color: string }) => React.ReactElement<any>;
}) {
  const { title, small, value, selected, numberOfLines, onPress, loading, label, children } = props;
  const { c } = useTheme();
  const highlight = !!(props.highlight ?? selected);
  const color = highlight ? props.color ?? 'accent' : 'surface';
  const textColor =
    highlight && props.color
      ? c('accent', 'text')
      : props.color ?? c('surface', highlight ? 'back' : 'text');
  const chevron = onPress && selected === undefined && !loading && props.chevron !== false;
  const icon = props.icon ?? (props.selected !== undefined ? (selected ? 'on' : 'off') : undefined);
  return (
    <View style={styles.container}>
      {label ? <Spacer small /> : undefined}
      {label ? <Title small>{label}</Title> : undefined}
      {label ? <Spacer small /> : undefined}
      <TouchableOpacity
        style={[
          styles.content,
          Shadows.regular,
          { backgroundColor: c(highlight ? color : 'surface') },
        ]}
        // @ts-ignore TODO
        onPress={onPress ? () => onPress() : undefined}>
        {icon ? <Icon name={icon} color={textColor} /> : undefined}
        {icon !== undefined ? <Spacer small /> : undefined}
        {title && typeof title === 'string' ? (
          <Title style={styles.title} small={small} color={textColor} numberOfLines={numberOfLines}>
            {title}
          </Title>
        ) : undefined}
        {title && typeof title !== 'string' ? title : undefined}
        {children ? children({ color: textColor }) : <Spacer flex />}
        {value && typeof value === 'string' ? (
          <Title small color={highlight ? textColor : undefined} numberOfLines={1}>
            {value}
          </Title>
        ) : undefined}
        {value && typeof value !== 'string' ? value : undefined}
        {chevron || loading ? <Spacer tiny /> : undefined}
        {chevron ? <Icon name="chevron-right" color={textColor} /> : undefined}
        {loading ? (
          <ActivityIndicator style={styles.loader} size="small" color={textColor} />
        ) : undefined}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 8,
  },
  content: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 6,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
  },
  title: {
    marginVertical: 4,
  },
  loader: {
    marginHorizontal: 8,
  },
});
