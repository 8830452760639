import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Spacer, TextInput, Button } from '../components';
import { Game, GameClientPhaseStatus, observer } from '../store';
import { t } from '../utils';

export default observer(function GameLieInput(props: {
  game: Game;
  phaseStatus: GameClientPhaseStatus;
}) {
  const { game, phaseStatus } = props;
  const { me, lie, question, isDemo } = game;
  const isWaitForInput = phaseStatus === 'waitForInput' || phaseStatus === 'waitForInputCritical';
  const [text, setText] = React.useState('');
  return (
    <View style={styles.container}>
      <TextInput
        autoFocus
        autoCorrect={false}
        editable={isWaitForInput}
        value={me?.lie || text} // eslint-disable-line
        autoCapitalize="characters"
        enablesReturnKeyAutomatically
        returnKeyType="send"
        onChangeText={(text: string) => setText(() => text.toUpperCase())}
      />
      <Spacer />
      {(text || isDemo) && isWaitForInput ? (
        <Button
          title={t('Submit')}
          disabled={!text || lie.inProgress}
          onPress={() =>
            game.lie({
              lie: text.trim(),
              question: question!,
            })
          }
        />
      ) : undefined}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 16,
  },
});
