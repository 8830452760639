import * as React from 'react';
import { View, StyleSheet, StatusBar } from 'react-native';

import { Styles, Shadows, useHeaderHeight } from '../components';
import { Game, GameClientPhase } from '../store';
import GameHeader from './GameHeader';
import GameProgressBar from './GameProgressBar';

export default function GameHeaderContainer(props: {
  game: Game;
  phase: GameClientPhase;
  alwaysVisible: boolean;
}) {
  const { game, phase, alwaysVisible } = props;
  const { headerHeight } = useHeaderHeight();
  const [hover, setHover] = React.useState(false);
  const [visibleTimer, setVisibleTimer] = React.useState<number | undefined>(undefined);
  const isVisible = !!visibleTimer || alwaysVisible || hover || game.status === 'paused';
  return (
    <View
      style={[styles.container, { height: headerHeight }]}
      // @ts-ignore: Web event handlers
      onMouseEnter={() => setHover(() => true)}
      onMouseLeave={() => setHover(() => false)}
      onClick={() => {
        if (visibleTimer) {
          // clearTimeout(visibleTimer);
          // setVisibleTimer(() => undefined);
          // nop
        } else {
          const timer = setTimeout(() => setVisibleTimer(() => undefined), 5000);
          setVisibleTimer(() => timer as any);
        }
      }}>
      <View
        style={[
          styles.header,
          !isVisible ? { transform: [{ translateY: -headerHeight }] } : undefined,
        ]}>
        <GameHeader game={game} />
        <GameProgressBar game={game} phase={phase} />
        <StatusBar hidden={!isVisible} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Styles.fixedObject,
    left: 0,
    top: 0,
    right: 0,
  },
  header: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    ...Shadows.regular,
    // @ts-ignore: Web transitions
    transitionProperty: 'transform',
    transitionDuration: '0.4s',
  },
});
