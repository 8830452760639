export const cellTransitions = {
  fadeTop: {
    top: (props: any) => props.height ?? 1000,
    opacity: 0,
  },
  slideLeft: { left: (props: any) => -props.width ?? -1000 },
  slideTop: { top: (props: any) => -props.height ?? -1000 },
  slideTopRight: {
    top: (props: any) => -props.height ?? -1000,
    left: (props: any) => props.width ?? 1000,
  },
  slideBottomRight: {
    top: (props: any) => props.height ?? 1000,
    left: (props: any) => props.width ?? 1000,
  },
  slideBottom: { top: (props: any) => props.height ?? 1000 },
  slideRight: { left: (props: any) => props.width ?? 1000 },
  fadeSlow: {
    duration: 1,
    opacity: 0,
  },
};

export type CellTransitionName = keyof typeof cellTransitions;
