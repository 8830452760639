import * as React from 'react';
import { View, useWindowDimensions, Text, TextStyle } from 'react-native';

import { useTheme, ColorName } from '../../components';
import { Cell } from './Cell';
import { CellSize } from './types';

export function CellText(
  props: Omit<React.ComponentProps<typeof Cell>, 'style'> & {
    children: string;
    vertical?: boolean;
    horizontalAlign?: number; // 0.5 = center, 0 = left, 1 = right
    verticalAlign?: number; // 0.5 = center, 0 = top, 1 = bottom
    small?: boolean;

    // Text style
    color?: ColorName;
    fontFamily?: string;
    fontSize?: number;
    fontStyle?: 'normal' | 'italic';
    fontWeight?:
      | 'normal'
      | 'bold'
      | '100'
      | '200'
      | '300'
      | '400'
      | '500'
      | '600'
      | '700'
      | '800'
      | '900';
    letterSpacing?: number;
    lineHeight?: number;
    textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
  }
) {
  const {
    left,
    top,
    width = 0,
    height = 0,
    color,
    children,
    debugName,
    vertical,
    horizontalAlign = 0.5,
    verticalAlign = 0.5,
    small,
    ...otherProps
  } = props;
  const [intrinsicSize, setIntrinsicSize] = React.useState<CellSize | undefined>();
  const { c } = useTheme();
  const size = useWindowDimensions();
  const isHorizontal = vertical !== null ? !vertical : width >= size.width || width >= height;

  const textStyle: TextStyle = {
    fontSize: Math.min(size.width / 18, size.height / 30) / (small ? 1.2 : 1),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: color ? c(color) : c('base', 'text'),
  };

  // TODO scaling

  const horizontalMargin = Math.max(Math.min(size.width, size.height) / 20, 16);
  const verticalMargin = horizontalMargin;
  const translateX = (width - (intrinsicSize?.width ?? 0)) * horizontalAlign;
  const translateY = (height - (intrinsicSize?.height ?? 0)) * verticalAlign;

  return (
    <Cell
      key={intrinsicSize ? 'sizeKnown' : 'sizeUnknown'}
      {...otherProps}
      left={(left ?? 0) + translateX}
      top={(top ?? 0) + translateY}
      width={width}
      height={height}
      debugName={debugName ?? 'CellText'}>
      <View
        key="measureNode"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: 0,
          width: isHorizontal ? width - horizontalMargin * 2 : height,
          height: isHorizontal ? height - verticalMargin * 2 : width,
        }}
        pointerEvents="none">
        <Text
          style={textStyle}
          onLayout={(event) => {
            if (
              intrinsicSize?.width !== event.nativeEvent.layout.width ||
              intrinsicSize?.height !== event.nativeEvent.layout.height
            ) {
              setIntrinsicSize({
                width: event.nativeEvent.layout.width,
                height: event.nativeEvent.layout.height,
              });
            }
          }}>
          {children}
        </Text>
      </View>
      {intrinsicSize ? (
        <Text
          style={[
            textStyle,
            {
              width: intrinsicSize.width,
              transform: [{ rotateZ: isHorizontal ? '0deg' : '-90deg' }],
              // TODO animations
            },
          ]}>
          {children}
        </Text>
      ) : undefined}
    </Cell>
  );
}
