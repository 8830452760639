import * as React from 'react';
import { View } from 'react-native';

import { useShadowNode } from './AnimationContainer';

export function StaggerAnimation(props: {
  children: any;
  delay: number;
  duration: number;
  enterStyle: any;
  style?: any;
}) {
  const { children, style, enterStyle, duration, delay } = props;
  const [initial, setInitial] = React.useState(true);
  const isShadowNode = useShadowNode();
  React.useEffect(() => {
    if (isShadowNode) return;
    const rafTimer = requestAnimationFrame(() => setInitial(() => false));
    return () => cancelAnimationFrame(rafTimer);
  }, []);
  if (isShadowNode) {
    // Skip animations when this is a shadow node used only for layouting
    return <View style={style}>{children}</View>;
  }
  return (
    <View style={style}>
      {React.Children.map(children, (child, idx) => (
        <View
          style={[
            {
              // @ts-ignore: Web transitions
              transitionDuration: `${duration}s`,
              transitionDelay: `${idx * delay}s`,
              transitionProperty: 'transform, opacity',
            },
            initial ? enterStyle : undefined,
          ]}>
          {child}
        </View>
      ))}
    </View>
  );
}
