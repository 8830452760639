import QRCode from 'qrcode.react';
import * as React from 'react';
import { TouchableOpacity, View, StyleSheet, useWindowDimensions } from 'react-native';

import { Icon, IconName } from '../Icon';
import { Spacer } from '../Spacer';
import { Title } from '../Text';
import { useTheme, ColorName, Shadows } from '../theme';

export type ToastProps = {
  title: string;
  message?: string;
  color?: ColorName;
  id?: string;
  qrCode?: string;
  icon?: IconName;
  onPress?: () => any;
  onDismiss?: () => any;
  hasCloseButton?: boolean;
  duration?: number;
};

export function ToastItem(props: ToastProps) {
  const { title, qrCode, onPress, icon, message, onDismiss, color = 'surface' } = props;
  const { c } = useTheme();
  const { width } = useWindowDimensions();
  return (
    <TouchableOpacity
      style={[styles.container, { backgroundColor: c(color) }]}
      onPress={onPress}
      disabled={!onPress}>
      <View style={styles.row}>
        <Title center flex color={c(color, 'text')}>
          {title}
        </Title>
        {icon ? <Spacer small /> : undefined}
        {icon ? <Icon name={icon} color={c(color, 'text')} /> : undefined}
        {onDismiss ? (
          <TouchableOpacity style={styles.dismissButton} onPress={onDismiss}>
            <Icon name="close" color={c(color, 'text')} />
          </TouchableOpacity>
        ) : undefined}
      </View>
      {qrCode ? (
        <View style={styles.qrCodeContainer}>
          <QRCode
            value={qrCode}
            size={Math.min(width - 40, 200)}
            bgColor={c(color)}
            fgColor={c(color, 'text')}
          />
        </View>
      ) : undefined}
      {message ? <Title small>{message}</Title> : undefined}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 8,
    marginTop: 8,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    ...Shadows.regular,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  qrCodeContainer: {
    marginHorizontal: 20,
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
  },
  dismissButton: {
    width: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
    opacity: 0.5,
  },
});
