import * as React from 'react';

import { Cell } from './Cell';
import { CellContainerNode } from './CellContainerNode';

type Props = React.ComponentProps<typeof Cell>;

type State = {
  children: any;
  unmountedChildren: any;
  onExit: (child: any) => any;
};

export class CellContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      children: this.props.children,
      unmountedChildren: [],
      onExit: (child: any) => {
        this.setState((state) => {
          const unmountedChildren = state.unmountedChildren.filter(
            (c: any) => c !== child && c.key !== child.key
          );
          return { unmountedChildren };
        });
      },
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { children } = props;

    const childKeys: { [key: string]: any } = {};
    React.Children.forEach(children, (child) => {
      if (child?.key) childKeys[child.key] = child;
    });

    const unmountedChildren = state.unmountedChildren.filter((child: any) => !childKeys[child.key]);
    React.Children.forEach(state.children, (child) => {
      if (child?.key && !childKeys[child.key] && child.props.exit) {
        unmountedChildren.push(
          <CellContainerNode
            key={child.key}
            debug={props.debug}
            width={child.props.width}
            height={child.props.height}
            exit={child.props.exit}
            onExit={() => state.onExit(child)}>
            {child}
          </CellContainerNode>
        );
      }
    });

    return {
      children,
      unmountedChildren,
    };
  }

  render() {
    const { children, ...otherProps } = this.props;
    const allChildren = [...this.state.unmountedChildren];

    // Quirks-note: It is essential that `React.Children.forEach` is
    // used here and not `.map` or `.toArray`. The map/array functions
    // return a different kind of child with a prefixed key, which caused
    // the child to recreate when moved to the unmountedChildren array.
    React.Children.forEach(children, (child) => {
      if (child) {
        allChildren.push(
          <CellContainerNode
            key={child.key}
            debug={this.props.debug}
            width={child.props.width}
            height={child.props.height}
            enter={child.props.enter}>
            {child}
          </CellContainerNode>
        );
      }
    });

    return <Cell {...otherProps}>{allChildren}</Cell>;
  }
}
