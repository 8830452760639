import * as React from 'react';
import { StyleSheet, View } from 'react-native';

export function Spacer(props: {
  small?: boolean;
  large?: boolean;
  tiny?: boolean;
  flex?: boolean;
}) {
  return (
    <View
      style={[
        props.flex ? styles.flex : styles.regular,
        props.small ? styles.small : undefined,
        props.large ? styles.large : undefined,
        props.tiny ? styles.tiny : undefined,
      ]}
    />
  );
}

const styles = StyleSheet.create({
  regular: {
    width: 16,
    height: 16,
  },
  large: {
    width: 20,
    height: 20,
  },
  small: {
    width: 8,
    height: 8,
  },
  flex: {
    flex: 1,
  },
  tiny: {
    width: 4,
    height: 4,
  },
});
