import * as React from 'react';

import { List } from '../components';
import { observer, PublishedTemplate } from '../store';
import { t, useNavigation, formatDate } from '../utils';

export default observer(function PublicTemplateListItem(
  props: Partial<React.ComponentProps<typeof List.Item>> & {
    publishedTemplate: PublishedTemplate;
  }
) {
  const { publishedTemplate, onPress, ...otherProps } = props;
  const navigation = useNavigation();
  const { date } = publishedTemplate;
  return (
    <List.Item
      title={t('Published', publishedTemplate.data.version + '')}
      value={formatDate(date)}
      highlight
      onPress={
        onPress === undefined
          ? () =>
              navigation.push('publishedTemplate', {
                publishedTemplate,
              })
          : onPress
      }
      {...otherProps}
    />
  );
});

/**

- Niet gepubliceerd of verzocht
- Verzoek verzonden
- Verzoek geannuleerd
- Verzoek verwopen
- Verzoek geaccepteerd + gepubliceerd
- Gepubliceerd + nieuw verzoek


 */
