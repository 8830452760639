import * as React from 'react';

import { Button, Badge, List, useMenu, useToast } from '../components';
import { store, observer } from '../store';
import { t, useNavigation, useRoute, is } from '../utils';

export default observer(function AccountScreen() {
  const { auth, adminPendingRequests } = store;
  const { isAdmin, user, userName, isLoading } = auth;
  const navigation = useNavigation();
  const route = useRoute();
  const menu = useMenu();
  const toast = useToast();
  const [editedName, setEditedName] = React.useState<string | null>(null);
  const [inProgress, setInProgress] = React.useState(false);

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          header
          icon="menu"
          onPress={() =>
            menu.show([
              {
                title: t('Sign out'),
                destructive: true,
                onPress: async () => {
                  await auth.signOut();
                  navigation.goBack();
                },
              },
            ])
          }
        />
      ),
    });
  }, [menu]);

  return (
    <List loading={isLoading}>
      <List.TextInput
        label={user?.phoneNumber ?? undefined}
        value={editedName ?? userName ?? ''}
        autoCorrect={false}
        placeholder={t('Name')}
        autoFocus={!userName}
        editable={!inProgress}
        onChangeText={(name: string) => setEditedName(name)}
      />
      {editedName !== null && userName !== editedName ? (
        <List.Button
          disabled={!editedName || userName === editedName || inProgress}
          title={userName ? t('Update name') : t('Set name')}
          onPress={async () => {
            try {
              setInProgress(true);
              await auth.updateProfile({
                displayName: editedName,
              });
              setEditedName(null);
              setInProgress(false);
              if (route.params?.isNew) {
                navigation.goBack();
                route.params?.joinGame?.();
              }
            } catch (e) {
              setInProgress(false);
              toast.error(e as Error);
            }
          }}
        />
      ) : undefined}
      {userName ? (
        <>
          <List.Item
            label={t('Game management')}
            title={t('My templates')}
            onPress={() => navigation.push('templates')}
          />
          <List.Item
            title={t('Game history')}
            onPress={() => navigation.push('games', { filter: 'played' })}
          />
        </>
      ) : undefined}
      {isAdmin ? (
        <>
          <List.Item
            label={t('Administrator')}
            title={t('Pending requests')}
            highlight={adminPendingRequests.hasDocs}
            value={
              adminPendingRequests.hasDocs ? (
                <Badge color="surface" title={adminPendingRequests.docs.length + ''} />
              ) : undefined
            }
            onPress={() => navigation.push('requests', { filter: 'pending' })}
          />
          <List.Item
            title={t('Request history')}
            onPress={() => navigation.push('requests', { filter: 'history' })}
          />
          <List.Item
            title="Administrative actions"
            onPress={() => navigation.push('adminActions')}
          />
        </>
      ) : undefined}
      {isAdmin || is('development') ? (
        <List.Item label="Overig" title="Ontwerp taal" onPress={() => navigation.push('design')} />
      ) : undefined}
    </List>
  );
});
