import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Slider, Button, Styles } from '../components';
import { observer, Game } from '../store';

export default observer(function DemoGameSlider(props: { game: Game }) {
  const { game } = props;
  const { demoProgress, isDemoPaused } = game;
  const [isSliding, setSliding] = React.useState(false);
  const safeInsets = useSafeAreaInsets();

  return (
    <View style={[styles.container, { paddingBottom: safeInsets.bottom }]}>
      <View style={styles.row}>
        <Button
          icon={isDemoPaused ? 'play' : 'pause'}
          onPress={() => (game.isDemoPaused = !isDemoPaused)}
          type="text"
        />
        <Slider
          value={demoProgress}
          onSlidingStart={() => setSliding(true)}
          onSlidingComplete={() => setSliding(false)}
          onValueChange={(value: number) => {
            if (isSliding && game.demoProgress !== value) game.demoProgress = value;
          }}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    ...Styles.fixedObject,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#00000088',
    paddingRight: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
