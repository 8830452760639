import { Collection, CollectionSource, ICollectionOptions } from 'firestorter';

import { Template } from './Template';

export class Templates extends Collection<Template> {
  constructor(source: CollectionSource, options?: ICollectionOptions<Template>) {
    super(source, {
      ...(options ?? {}),
      createDocument: (source, options) => new Template(source, options),
    });
  }
}
