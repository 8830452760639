import * as React from 'react';

import { t } from '../utils/i18n';
import { TextInput } from './TextInput';

export class NumberInputError extends Error {
  public readonly value: string;
  constructor(message: string, value: string) {
    super(message);
    this.name = 'NumberInputError';
    this.value = value;
  }
}

export const NumberInput = React.forwardRef(
  (
    props: Omit<React.ComponentProps<typeof TextInput>, 'value' | 'onChangeText'> & {
      value?: number | Error;
      minValue?: number;
      maxValue?: number;
      onChangeValue?: (value?: number | Error) => any;
    },
    ref: any
  ) => {
    const { value, minValue, maxValue, onChangeValue, ...rest } = props;
    return (
      <TextInput
        ref={ref}
        value={value == null ? '' : value instanceof NumberInputError ? value.value : value + ''}
        keyboardType="numeric"
        onChangeText={
          onChangeValue
            ? (value) => {
                if (!value) {
                  onChangeValue(undefined);
                } else {
                  const num = Number(value);
                  if (!isNaN(num)) {
                    if (minValue != null && num < minValue) {
                      onChangeValue(new NumberInputError(t('Invalid value'), value));
                    } else if (maxValue != null && num > maxValue) {
                      onChangeValue(new NumberInputError(t('Invalid value'), value));
                    } else {
                      onChangeValue(num);
                    }
                  } else {
                    onChangeValue(new NumberInputError(t('Invalid value'), value));
                  }
                }
              }
            : undefined
        }
        {...rest}
      />
    );
  }
);
