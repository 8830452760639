import map from 'lodash/map';
import { Platform } from 'react-native';

export function formatLink(path?: string, params?: object, origin?: string): string {
  origin = origin ?? (Platform.OS === 'web' ? location.origin : 'https://liegebeest.nl');
  const queryArgs = map(params, (value, key) => ({ value, key }))
    .filter((a) => a.value !== undefined)
    .map(({ value, key }) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return `${origin}${path ? `/${path}` : ''}${queryArgs ? `?${queryArgs}` : ''}`;
}
