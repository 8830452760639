import * as React from 'react';

export type BackgroundProps = {
  id?: string;
  image?: string;
  rotate?: boolean;
};

let nextItemId = 1;

export class BackgroundData {
  private setItems(_fn: (items: BackgroundProps[]) => BackgroundProps[]) {}

  push(props: BackgroundProps) {
    const item = props.id ? props : { ...props, id: `__background${nextItemId++}` };
    this.setItems((items) => [item, ...items]);
    return item.id!;
  }

  pop(id: string) {
    this.setItems((items) => items.filter((t) => t.id !== id));
  }
}

export const BackgroundContext = React.createContext<BackgroundData | undefined>(undefined);

export function Background(props: BackgroundProps) {
  const background = React.useContext(BackgroundContext);
  React.useEffect(() => {
    const id = background?.push(props);
    return () => (id ? background?.pop(id) : undefined);
  }, Object.values(props));
  return null;
}
