import {
  useNavigation as useNavigationRaw,
  useRoute as useRouteRaw,
} from '@react-navigation/native';
import * as React from 'react';

import { TemplateRequests } from '../store';
import { Game } from '../store/Game';
import { PublishedTemplate } from '../store/PublishedTemplate';
import { Template } from '../store/Template';
import { TemplateRequest } from '../store/TemplateRequest';
import { Auth } from '../store/framework';

export function useInitial(hasData: boolean) {
  const [initial, setInitial] = React.useState(true);
  React.useEffect(() => {
    if (hasData && initial) {
      setInitial(() => false);
    }
  }, [hasData]);
  return initial;
}

export function useDate(interval: number = 1000) {
  const [date, setDate] = React.useState(new Date());
  React.useEffect(() => {
    const timer = setInterval(() => setDate(() => new Date()), interval);
    return () => clearInterval(timer);
  }, [interval]);
  return date;
}

export class Navigation {
  nav: any;
  constructor(navigation: any) {
    this.nav = navigation;
  }
  push(routeName: string, params?: any) {
    if (routeName === 'home' || routeName === 'game') {
      return this.nav.push(routeName, params);
    } else {
      return this.nav.navigate('modal', {
        screen: routeName,
        params,
      });
    }
  }
  replace(routeName: string, params?: any) {
    return this.nav.replace(routeName, params);
  }
  goBack() {
    return this.nav.goBack();
  }
  setOptions(config: { title?: string; headerRight?: any }) {
    return this.nav.setOptions(config);
  }
}

// TODO add route typings
export function useNavigation(): Navigation {
  return new Navigation(useNavigationRaw());
}

// TODO add route typings
export function useRoute(): { params?: any } {
  return useRouteRaw() as any;
}

export function useTitle(title: string) {
  const navigation = useNavigation();
  React.useEffect(() => navigation.setOptions({ title }), [title]);
}

export function useGame() {
  const route = useRoute();
  const routeGame = route.params?.game as Game | string | undefined;
  const [game] = React.useState(() =>
    routeGame
      ? typeof routeGame === 'string'
        ? new Game(`games/${routeGame}`)
        : routeGame
      : undefined
  );
  return game;
}

export function useTemplate() {
  const route = useRoute();
  const routeTemplate = route.params?.template as Template | string | undefined;
  const [template] = React.useState(() =>
    routeTemplate
      ? typeof routeTemplate === 'string'
        ? new Template(`templates/${routeTemplate}`)
        : routeTemplate
      : undefined
  );
  return template;
}

export function useTemplateRequest() {
  const route = useRoute();
  const routeRequest = route.params?.templateRequest as TemplateRequest | string | undefined;
  const [templateRequest] = React.useState(() =>
    routeRequest
      ? typeof routeRequest === 'string'
        ? new TemplateRequest(`templateRequests/${routeRequest}`)
        : routeRequest
      : undefined
  );
  return templateRequest;
}

export function useLastTemplateRequest() {
  const route = useRoute();
  const templateId =
    typeof route.params?.template === 'string'
      ? route.params?.template
      : route.params?.template?.id;
  const [templateRequests] = React.useState(
    () =>
      new TemplateRequests('templateRequests', {
        query: (ref: any) =>
          templateId
            ? ref
                .where('ownerId', '==', Auth.getInstance().userId)
                .where('templateId', '==', templateId)
                .orderBy('date', 'desc')
                .limit(1)
            : null,
      })
  );
  return templateRequests.hasDocs ? templateRequests.docs[0] : undefined;
}

export function usePublishedTemplate() {
  const route = useRoute();
  const routePublishedTemplate = route.params?.publishedTemplate as
    | PublishedTemplate
    | string
    | undefined;
  const routeTemplate = route.params?.template as Template | string | undefined;
  const templateRequest = useTemplateRequest();
  const [publishedTemplate] = React.useState(() => {
    if (routePublishedTemplate && typeof routePublishedTemplate !== 'string') {
      return routePublishedTemplate;
    } else if (routeTemplate || routePublishedTemplate) {
      const templateId = typeof routeTemplate === 'string' ? routeTemplate : routeTemplate?.id;
      return new PublishedTemplate(`publishedTemplates/${routePublishedTemplate ?? templateId}`);
    } else if (templateRequest) {
      return new PublishedTemplate(() =>
        templateRequest.data.templateId
          ? `publishedTemplates/${templateRequest.data.templateId}`
          : undefined
      );
    } else {
      return undefined;
    }
  });
  return publishedTemplate;
}
