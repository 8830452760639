import sortBy from 'lodash/sortBy';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import shortHash from 'shorthash2';

import { StaggerAnimation, PureComponent } from '../components';
import { Game, GameClientPhaseStatus } from '../store';
import GameChoiceItem from './GameChoiceItem';

function layoutChoices(choices: string[], rowCount: number): string[][] {
  const rows: string[][] = [];
  choices = sortBy(choices, ['length']).reverse();
  while (choices.length) {
    const choice = choices.shift()!;
    if (rows.length < rowCount) {
      rows.unshift([choice]);
    } else {
      rows[0].push(choice);
      rows.sort((a, b) => a.join('+++').length - b.join('+++').length);
    }
  }
  rows.sort((a, b) => shortHash(a.join('+++')).localeCompare(shortHash(b.join('+++'))));
  return rows;
}

const staggerAnimation = {
  transform: [{ translateY: 100 }, { scale: 0.2 }],
  opacity: 0,
};

export default function GameChooseInput(props: { game: Game; phaseStatus: GameClientPhaseStatus }) {
  const { game, phaseStatus } = props;
  const { me, choose, question } = game;
  const choices = game.data.choices ?? [];
  const isSubmitted = phaseStatus === 'inputGiven';
  const submittedChoice =
    isSubmitted && choose.request?.question === question && choose.request?.choice
      ? choose.request?.choice
      : isSubmitted && me?.choice
      ? me?.choice
      : undefined;
  return (
    <PureComponent props={[choices, isSubmitted, submittedChoice]}>
      {() => (
        <StaggerAnimation duration={0.4} delay={0.15} enterStyle={staggerAnimation}>
          {layoutChoices(choices, 10).map((row, rowIdx) => (
            <View key={`row${rowIdx}`} style={styles.row}>
              {row.map((choice, colIdx) => (
                <View key={`col${colIdx}`} style={colIdx ? styles.cell : styles.cellShrink}>
                  <GameChoiceItem
                    text={choice}
                    disabled={!!submittedChoice && submittedChoice !== choice}
                    onPress={
                      me
                        ? () => (!isSubmitted ? choose({ choice, question: question! }) : undefined)
                        : undefined
                    }
                  />
                </View>
              ))}
            </View>
          ))}
        </StaggerAnimation>
      )}
    </PureComponent>
  );
}

const styles = StyleSheet.create({
  stagger: {
    transform: [{ translateY: 100 }],
  },
  row: {
    flexDirection: 'row',
    marginLeft: 16,
    marginRight: 12,
    marginBottom: 8,
  },
  cell: {
    flexGrow: 1,
    marginRight: 4,
    overflow: 'hidden',
  },
  cellShrink: {
    flexGrow: 1,
    marginRight: 4,
    overflow: 'hidden',
    flexShrink: 1,
  },
});
