import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Title, Spacer } from '../components';
import { Game, observer } from '../store';
import { t } from '../utils';

export default observer(function GameIntroFooter(props: { game: Game }) {
  return (
    <View style={styles.container}>
      <Spacer />
      <Title large center>
        {t("You'll receive 500 points for every player you fool.")}
      </Title>
      <Spacer large />
      <Title large center>
        {t('And 1000 points for finding the truth.')}
      </Title>
      <Spacer />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
  },
});
