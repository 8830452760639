import * as React from 'react';
import { StyleSheet } from 'react-native';

import { Check } from '../Check';

export function ListCheck(props: React.ComponentProps<typeof Check>) {
  return <Check {...props} style={[styles.container, props.style]} />;
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginTop: 8,
    marginBottom: 16,
  },
});
