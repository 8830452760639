import * as React from 'react';
import { useWindowDimensions, View, TouchableOpacity } from 'react-native';

import { TextInput, useTheme, ColorName, Icon, Shadows } from '../components';
import { Game, GameClientPhaseStatus, observer } from '../store';
import { t } from '../utils';
import { Cell, CellBorder } from './cell';

export default observer(function GameLieInput(
  props: React.ComponentProps<typeof Cell> & {
    game: Game;
    phaseStatus: GameClientPhaseStatus;
    color?: ColorName;
  }
) {
  const { game, phaseStatus, color = 'surface', ...cellProps } = props;
  const { me, question } = game;
  const isWaitForInput = phaseStatus === 'waitForInput' || phaseStatus === 'waitForInputCritical';
  const [text, setText] = React.useState('');
  const [focus, setFocus] = React.useState(true);
  const size = useWindowDimensions();
  const { c } = useTheme();
  const ref = React.useRef(null);
  React.useEffect(() => {
    // @ts-ignore
    const timer = setTimeout(() => ref.current?.focus(), 1000);
    return () => clearTimeout(timer);
  }, []);

  function submitLie() {
    const lie = text.trim();
    if (lie && !game.lie.inProgress) {
      game.lie({
        lie,
        question: question!,
      });
    }
  }

  return (
    <Cell {...cellProps} clip>
      <CellBorder
        width={cellProps.width}
        height={cellProps.height}
        edge="left"
        color={c(color, 'border')}
        hidden
      />
      <CellBorder
        width={cellProps.width}
        height={cellProps.height}
        edge="top"
        color={c(color, 'border')}
        hidden
      />
      <View
        style={[
          {
            width: cellProps.width,
            height: cellProps.height,
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: Math.min(size.width / 18, size.height / 30),
            backgroundColor: c('surface', 'back'),
            borderWidth: 2,
            borderColor: c('surface', 'border', focus ? 'pressed' : 'neutral'),
          },
          focus
            ? {
                ...Shadows.focus,
                shadowColor: color
                  ? c(color)
                  : c('surface', 'border', focus ? 'pressed' : 'neutral'),
              }
            : undefined,
        ]}>
        <TextInput
          ref={ref}
          style={{
            flex: 1,
            fontSize: Math.min(size.width / 18, size.height / 30),
            height: cellProps.height,
            backgroundColor: 'transparent',
            borderRadius: 0,
            marginRight: Math.min(size.width / 18, size.height / 30),
          }}
          // autoFocus
          outline={false}
          autoCorrect={false}
          editable={isWaitForInput}
          value={me?.lie || text} // eslint-disable-line
          placeholder={t('Enter your lie').toUpperCase()}
          autoCapitalize="characters"
          enablesReturnKeyAutomatically
          returnKeyType="send"
          onChangeText={(text: string) => setText(() => text.toUpperCase())}
          onFocus={() => setFocus(() => true)}
          onBlur={() => setFocus(() => false)}
          onSubmitEditing={submitLie}
        />
        <TouchableOpacity disabled={!text} onPress={submitLie}>
          <Icon
            name="send"
            color={c('accent', 'back', text ? 'neutral' : 'disabled')}
            size={Math.min(size.width / 18, size.height / 30)}
          />
        </TouchableOpacity>
      </View>
    </Cell>
  );
});
