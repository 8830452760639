import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { Spacer, Title } from '../components';
import { GamePlayer, observer } from '../store';
import GamePlayerName from './GamePlayerName';
import GamePlayerScore from './GamePlayerScore';
import GamePlayerSurface from './GamePlayerSurface';
import GamePlayerSymbol from './GamePlayerSymbol';

export type GamePlayerValueType = 'ready' | 'roundScore' | 'totalScore';

export default observer(function GamePlayerItem(props: {
  player: GamePlayer;
  badge?: boolean;
  valueType?: GamePlayerValueType;
  playSound?: boolean;
}) {
  const { player, badge, playSound, valueType } = props;
  React.useEffect(() => {
    if (playSound) {
      player.playSound();
    }
  }, [playSound]);
  return (
    <GamePlayerSurface style={[!badge ? styles.listContainer : undefined]}>
      <GamePlayerSymbol player={player} />
      {!badge ? <GamePlayerName player={player} /> : undefined}
      <Spacer flex />
      {valueType === 'roundScore' || valueType === 'totalScore' ? (
        <GamePlayerScore
          score={
            valueType === 'roundScore'
              ? `${player.score ? '+ ' : ''}${player.score}`
              : `${player.totalScore}`
          }
          color={player.color}
        />
      ) : undefined}
      {valueType === 'ready' && player.isReady ? (
        <View style={styles.ready}>
          <Title>🚀</Title>
        </View>
      ) : undefined}
    </GamePlayerSurface>
  );
});

const styles = StyleSheet.create({
  listContainer: {
    marginBottom: 8,
  },
  ready: {
    marginHorizontal: 16,
  },
});
