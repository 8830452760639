import { setString } from 'expo-clipboard';
import * as React from 'react';
import { Share, View, StyleSheet } from 'react-native';

import { Button, MenuOption, ToastContextData, useMenu, useToast } from '../components';
import { observer, store, Game } from '../store';
import { t, useNavigation, formatLink, is } from '../utils';

async function shareGame(game: Game, toast: ToastContextData) {
  const linkConfig = {
    action: status === 'notStarted' ? 'join' : undefined,
    referrer: store.auth.userId,
  };
  const url = formatLink(`games/${game.id}`, linkConfig);
  let toastId: string;
  try {
    setString(url);
    const qrCode = is('development')
      ? formatLink(`games/${game.id}`, linkConfig, 'https://liegebeest.nl')
      : url;
    toastId = toast.show({
      title: t('Scan code'),
      qrCode,
      hasCloseButton: true,
      duration: 0,
    });
    await Share.share({
      title: t('Lie or die'),
      message: t('Hi, join my $1 game "$2"', t('Lie or die'), game.title),
      url,
    });
    toast.hide(toastId);
  } catch (e) {
    const err = e as Error;
    const message = err.message.toLowerCase();
    if (message.includes('abort')) {
      toast.hide(toastId!);
      return;
    }
    if (!message.includes('not supported')) {
      toast.error(err);
    }
    setString(url);
    toast.show({ title: t('Copied to clipboard') });
  }
}

export default observer(function GameHeaderButton(props: { game: Game }) {
  const { game } = props;
  const navigation = useNavigation();
  const menu = useMenu();
  const toast = useToast();
  const { isHost, status, me, players } = game;
  const options: MenuOption[] = [];

  options.push({
    title: status === 'notStarted' ? t('Invite players') : t('Share game'),
    onPress: () => shareGame(game, toast),
  });

  if (isHost) {
    if (status === 'ended' || (status === 'notStarted' && !players.length)) {
      options.push({
        title: t('Delete game'),
        destructive: true,
        onPress: async () => {
          await game.deletePermanently({});
          navigation.goBack();
          toast.show({ title: t('Game deleted') });
        },
      });
    } else {
      if (status === 'paused') {
        options.push({ title: t('Resume game'), onPress: game.resume });
      } else if (status === 'started') {
        options.push({ title: t('Pause game'), onPress: game.pause });
      } else if (status === 'notStarted') {
        options.push({ title: t('Start game'), onPress: game.start });
      }
      options.push({
        title: t('End game'),
        destructive: true,
        onPress: game.end,
      });
    }
  } else if (me) {
    options.push({
      title: t('Leave game'),
      destructive: true,
      onPress: game.leave,
    });
  }

  return (
    <View style={styles.container}>
      <Button icon="menu" header onPress={() => menu.show(options)} />
      {status === 'notStarted' ? (
        <Button icon="invite" header onPress={() => shareGame(game, toast)} />
      ) : undefined}
      {status === 'started' && isHost ? (
        <Button icon="pause" header onPress={game.pause} />
      ) : undefined}
      {status === 'paused' && isHost ? (
        <Button icon="play" header onPress={game.resume} />
      ) : undefined}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
});
