import filter from 'lodash/filter';
import map from 'lodash/map';
import size from 'lodash/size';
import { toJS } from 'mobx';

import { DocumentFragment } from './DocumentFragment';

export class DocumentFragmentCollection<T = object> extends DocumentFragment<{ [key: string]: T }> {
  get(key: string): T | undefined {
    return this.data?.[key];
  }

  get length(): number {
    return size(toJS(this.data ?? {}));
  }

  map<R>(callback: (value: T, key: string) => R): R[] {
    return map(toJS(this.data ?? {}), callback);
  }

  forEach<R>(callback: (value: T, key: string) => R) {
    map(toJS(this.data ?? {}), callback);
  }

  filter(callback: (value: T, key: string) => boolean): T[] {
    return filter(toJS(this.data ?? {}), callback);
  }

  toArray(): T[] {
    return this.map((v) => v);
  }
}
