import * as React from 'react';

import { Button, List, useMenu } from '../components';
import { observer } from '../store';
import useTemplateQuestionsSections from '../template/useTemplateQuestionsSections';
import { t, useNavigation, usePublishedTemplate, formatDate } from '../utils';

export default observer(function PublishedTemplateScreen() {
  const navigation = useNavigation();
  const menu = useMenu();
  const publishedTemplate = usePublishedTemplate()!;
  const { date } = publishedTemplate;
  const sections = useTemplateQuestionsSections({
    template: publishedTemplate,
    label: `${t('Published')}: ${formatDate(date, true)}`,
  });

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          header
          icon="menu"
          onPress={() =>
            menu.show([
              {
                title: t('Revoke publication'),
                onPress: async () =>
                  navigation.push('unpublish', {
                    publishedTemplate,
                  }),
              },
            ])
          }
        />
      ),
    });
  }, [date, publishedTemplate]);

  return <List sections={sections} />;
});
