import * as React from 'react';

import { Cell } from './Cell';

export function CellProgress(
  props: Omit<React.ComponentProps<typeof Cell>, 'children'> & {
    startValue: number;
    paused?: boolean;
    children: (
      props: Omit<React.ComponentProps<typeof Cell>, 'children'> & {
        isFilled: boolean;
      }
    ) => any;
  }
) {
  const {
    width = 0,
    height = 0,
    startValue,
    duration,
    paused,
    children,
    debugName,
    ...cellProps
  } = props;
  return (
    <Cell {...cellProps} width={width} height={height} debugName={debugName ?? 'CellProgress'}>
      {children({ width, height, isFilled: false })}
      <Cell
        clip
        duration={duration}
        keyframes={{
          '0%': { left: startValue * width - width },
          '100%': { left: paused ? startValue * width - width : 0 },
        }}>
        {children({
          duration,
          keyframes: {
            '0%': { left: width - startValue * width },
            '100%': { left: paused ? width - startValue * width : 0 },
          },
          width,
          height,
          isFilled: true,
        })}
      </Cell>
    </Cell>
  );
}
