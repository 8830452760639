import * as React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';

import { Spacer, AnimationContainer, Animation, AnimationConfig } from '../components';
import { GameClientChoice } from '../store';
import GameChoiceItem from './GameChoiceItem';
import GamePlayerCount from './GamePlayerCount';
import GamePlayerItem from './GamePlayerItem';
import GamePlayerScore from './GamePlayerScore';
import GamePlayerSurface from './GamePlayerSurface';

export type GameChoiceResultPhase = 'none' | 'chosenBy' | 'score';

const voteAnim: AnimationConfig = {
  enter: { opacity: 0, transform: [{ translateX: -100 }], duration: 0.4 },
  exit: { opacity: 0, duration: 0.4 },
};

const scoreAnim: AnimationConfig = {
  enter: { opacity: 0, transform: [{ rotateX: '90deg' }], duration: 0.4 },
  exit: { opacity: 0, duration: 0.4 },
};

const whomAnim: AnimationConfig = {
  enter: { opacity: 0, transform: [{ translateX: 100 }], duration: 0.4 },
  exit: { opacity: 0, duration: 0.4 },
};

const badgeWidth = 40;

export default function GameChoiceResultItem(props: {
  choiceResult: GameClientChoice;
  phase: GameChoiceResultPhase;
}) {
  const { phase, choiceResult } = props;
  const { text, liedBy, chosenBy, score } = choiceResult;
  const { width } = useWindowDimensions();
  const playerCount = phase === 'chosenBy' ? chosenBy.length : liedBy.length;
  const playerDelay = 2 / playerCount;
  const badgeOffset = Math.min(Math.floor((width - 32 - 100) / playerCount - badgeWidth), -16);
  const playerStyle = { marginLeft: badgeOffset };
  return (
    <View style={styles.container}>
      <GameChoiceItem text={text} />
      <AnimationContainer
        style={[styles.playersContainer, { paddingLeft: -badgeOffset }]}
        debugName={`Choice:${text}`}>
        {phase === 'chosenBy'
          ? chosenBy.map((player, idx) => (
              <Animation
                key={player.id}
                id={player.id}
                style={playerStyle}
                config={{
                  ...voteAnim,
                  enter: {
                    ...voteAnim.enter,
                    delay: idx * playerDelay,
                  },
                }}>
                <GamePlayerItem key={player.id} player={player} badge />
              </Animation>
            ))
          : undefined}
        {phase === 'chosenBy' ? (
          <Animation
            key="chooseCount"
            id="chooseCount"
            style={playerStyle}
            config={{
              ...voteAnim,
              enter: {
                ...voteAnim.enter,
                delay: chosenBy.length * playerDelay,
              },
            }}>
            <GamePlayerSurface>
              <GamePlayerCount count={chosenBy.length} />
            </GamePlayerSurface>
          </Animation>
        ) : undefined}
        {phase === 'score' ? (
          <Animation
            key="choiceScore"
            id="choiceScore"
            style={playerStyle}
            config={{
              ...scoreAnim,
              enter: {
                ...scoreAnim.enter,
              },
            }}>
            <GamePlayerSurface>
              <GamePlayerScore score={score ? `+${score}` : '0'} />
            </GamePlayerSurface>
          </Animation>
        ) : undefined}
        <Spacer flex />
        {phase === 'score' && liedBy.length
          ? liedBy.map((player, idx) => (
              <Animation
                key={`liedBy:${player.id}`}
                id={`liedBy:${player.id}`}
                style={playerStyle}
                config={whomAnim}>
                <GamePlayerItem key={player.id} player={player} badge />
              </Animation>
            ))
          : undefined}
      </AnimationContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 16,
  },
  playersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -4,
    height: 40,
  },
});
