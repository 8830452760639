import uniqolor from 'uniqolor';

import type { ColorName } from '../components/theme';
import { Sounds } from '../sound';
import { toTime } from '../utils';
import { GamePlayerSymbols } from './GamePlayerSymbols';
import { DocumentFragment } from './framework';
import { GamePublicPlayerData } from './types';

export class GamePlayer extends DocumentFragment<GamePublicPlayerData> {
  private _prevQuestionIndex?: number; // = 100;
  private _prevTotalScore?: number; // = Math.random() * 10000;

  get name() {
    return this.data!.name;
  }

  get symbol() {
    return this.data?.symbol ?? '';
  }

  get date() {
    return this.data?.date;
  }

  get time() {
    return toTime(this.data?.date);
  }

  get score() {
    return this.data!.score ?? 0;
  }

  get totalScore() {
    return this.data!.totalScore ?? 0;
  }

  get prevTotalScore() {
    const { questionIndex } = this.doc.data as any;
    const { totalScore } = this;
    if (this._prevQuestionIndex == null || questionIndex > this._prevQuestionIndex + 1) {
      this._prevQuestionIndex = questionIndex;
      this._prevTotalScore = totalScore;
    }
    return this._prevTotalScore!;
  }

  get hasChosen() {
    return this.data!.chosen ?? false;
  }

  get hasLied() {
    return this.data!.lied ?? false;
  }

  get isReady() {
    return this.data?.ready ?? false;
  }

  get choice() {
    return this.data!.choice;
  }

  get lie() {
    return this.data!.lie;
  }

  get color(): ColorName {
    return uniqolor(`${this.doc.id}.${this.id}`).color as ColorName;
  }

  get sound() {
    return GamePlayerSymbols[this.symbol].sound ?? 'droid';
  }

  playSound = () => {
    Sounds.play(this.sound);
  };

  playTrustedSound() {
    Sounds.play(this.sound, true);
  }
}
