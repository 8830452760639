import * as React from 'react';

import { useTheme, transparentColor, ColorName } from '../components';
import { GamePlayer } from '../store';
import { Cell, CellBackground, CellText, CellBorder } from './cell';

export type GamePlayerValueType = 'roundScore' | 'totalScore';

export default function GamePlayerItem(
  props: React.ComponentProps<typeof Cell> & {
    player: GamePlayer;
    valueType?: GamePlayerValueType;
    playSound?: boolean;
    vertical?: boolean;
    hideName?: boolean;
    firstCol?: boolean;
  }
) {
  const { player, playSound, valueType, vertical, hideName, firstCol, ...cellProps } = props;
  React.useEffect(() => {
    if (playSound) player.playSound();
  }, [playSound]);
  const { width, height } = cellProps;
  const { c } = useTheme();
  /* const playerColors = getTheme().colors.players;
  const themeColor = playerColors.length ? playerColors[0] : undefined;
  const color = (themeColor ?? player.color) as ColorName;
  const contrastColor = themeColor ? c(player.color) : undefined; */
  const backColor = transparentColor(player.color, 0.5) as ColorName;
  const borderColor = c('base', 'border');
  const textColor = c('base', 'text');
  let suffix = '';
  switch (valueType) {
    case 'roundScore':
      suffix = `${player.score ? '+ ' : ''}${player.score}`;
      break;
    case 'totalScore':
      suffix = `${player.totalScore}`;
      break;
  }
  const text = `${player.symbol}${hideName ? '' : ' ' + player.name}${suffix ? ' ' + suffix : ''}`;
  return (
    <Cell {...cellProps} debugName="PlayerItem" clip>
      <CellBackground width={width} height={height} color={backColor} />
      <CellBorder width={width} height={height} edge="top" color={borderColor} hidden={vertical} />
      <CellBorder
        width={width}
        height={height}
        edge="left"
        color={borderColor}
        hidden={!vertical || firstCol}
      />
      <CellBorder width={width} height={height} edge="right" color={borderColor} hidden />
      <CellBorder width={width} height={height} edge="bottom" color={borderColor} hidden />
      {Boolean(text) && (
        <CellText
          width={width}
          height={height}
          vertical={hideName ? false : vertical}
          horizontalAlign={0.5}
          color={textColor}
          debugName="PlayerName">
          {text}
        </CellText>
      )}
    </Cell>
  );
}
