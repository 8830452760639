import type { SoundName } from '../sound';

type GamePlayerSymbolData = {
  sound?: SoundName;
};

const sound: SoundName = 'droid';

/*
export default {
  drumroll: require('./drumroll.mp3'),
};
*/

export const GamePlayerSymbols: { [symbol: string]: GamePlayerSymbolData } = {
  '🤡': { sound: 'laugh' },
  '💩': { sound: 'fart' },
  '👻': { sound: 'ghost' },
  '👽': { sound: 'alien' },
  '🤖': { sound: 'droid' },
  '👾': { sound: 'laser' },
  '🤠': { sound: 'cowboy' },
  '💧': { sound: 'droplet' },
  '💣': { sound: 'bomb' },
  '🐸': { sound: 'frog' },
  '🦆': { sound: 'duck' },
  '👶': { sound: 'baby' },
  '🛎️': { sound: 'bell' },
  '🎺': { sound: 'trumpet' },
  '🥁': { sound: 'drums' },
  '🐈': { sound: 'cat' },
  '🐩': { sound: 'poodle' },
  '🎸': { sound: 'guitar' },
  '🚗': { sound: 'horn' },
  '🥸': { sound: 'human' },
  '🎹': { sound: 'piano' },
  '🦉': { sound: 'owl' },
  '🐝': { sound: 'bee' },
  '🛵': { sound: 'scooter' },
  '🐴': { sound: 'horse' },
  '🐘': { sound: 'elephant' },
  '👹': { sound: 'ogre' },
  '👅': { sound: 'tongue' },
  '🏍️': { sound: 'motorcycle' },
  '🏄': { sound: 'surfer' },
  '🚀': { sound },
  '🦗': { sound },
  '🦟': { sound },
  '🦀': { sound },
  '🎃': { sound },
};
