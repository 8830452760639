import * as React from 'react';
import { StyleSheet } from 'react-native';

import { StaggerAnimation, PureComponent } from '../components';
import { Game, GameClientPhaseStatus, GameClientPhaseType, observer } from '../store';
import GameChoiceResultItem from './GameChoiceResultItem';

const staggerAnimation = {
  transform: [{ translateY: 100 }, { scale: 0.2 }],
  opacity: 0,
};

export default observer(function GameChoiceResults(props: {
  game: Game;
  phaseStatus: GameClientPhaseStatus;
  phaseType: GameClientPhaseType;
}) {
  const { game, phaseStatus, phaseType } = props;
  const { choices, truth } = game;
  return (
    <PureComponent props={[phaseStatus, choices, truth]}>
      {() => (
        <StaggerAnimation
          style={styles.container}
          duration={0.4}
          delay={0.15}
          enterStyle={staggerAnimation}>
          {choices
            .filter((r) => r.liedBy.length || r.text === truth)
            .sort((a, b) => b.chosenBy.length - a.chosenBy.length)
            .map((choice) => (
              <GameChoiceResultItem
                key={choice.text}
                phase={
                  phaseType === 'votes'
                    ? phaseStatus === 'enter'
                      ? 'none'
                      : 'chosenBy'
                    : phaseStatus === 'enter'
                    ? 'chosenBy'
                    : 'score'
                }
                choiceResult={choice}
              />
            ))}
        </StaggerAnimation>
      )}
    </PureComponent>
  );
});

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
  },
});
