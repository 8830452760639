import * as React from 'react';
import { StyleSheet } from 'react-native';

import { TabBar } from '../TabBar';

export function ListTabBar(props: React.ComponentProps<typeof TabBar>) {
  return <TabBar {...props} style={[styles.container, props.style]} />;
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginTop: 8,
    marginBottom: 16,
  },
});
