// @ts-nocheck
import { autorun } from 'mobx';

import type { DocumentReference } from '../store/firebase';
import { Auth, fastClock } from '../store/framework';
import type { GamePublicData } from '../store/types';
import { createDemoGame } from './createDemoGame';
import type { DemoGameConfig } from './types';

class DemoGameSnapshot {
  private _data: object;
  constructor(data: object) {
    this._data = data;
  }
  data() {
    return this._data;
  }
  get exists() {
    return true;
  }
}

function timestamp(time: number): any {
  return time;
}

export class DemoGame implements DocumentReference {
  public readonly id: string;
  public readonly path: string;
  public readonly config: DemoGameConfig;
  private totalTime: number;
  private startTime: number;
  private timeOffset: number;
  private isPaused: boolean = false;

  constructor(path: string) {
    this.path = path;
    this.id = path.split('/').pop()!;
    this.config = createDemoGame(this.id);
    this.startTime = Date.now();
    this.timeOffset = 0;
    this.totalTime = 0;
    this.config.actions.forEach(
      (action) => (this.totalTime += typeof action.wait === 'number' ? action.wait * 1000 : 0)
    );
  }

  onSnapshot(callback?: (snapshot: any) => void, _error?: (error: any) => void) {
    return autorun(() => {
      this.timeOffset = this.isPaused
        ? this.timeOffset
        : Math.max(fastClock.time - this.startTime, 0);
      this.startTime = this.isPaused ? fastClock.time - this.timeOffset : this.startTime;
      const data = this.getData(this.timeOffset);
      callback?.(new DemoGameSnapshot(data));
    });
  }

  async update(fields: object) {
    if (fields.isDemoPaused !== undefined) {
      this.isPaused = fields.isDemoPaused;
    }
    if (typeof fields.demoProgress === 'number') {
      if (this.isPaused) {
        this.timeOffset = fields.demoProgress * this.totalTime;
      } else {
        this.startTime = fastClock.time - fields.demoProgress * this.totalTime;
      }
    }
  }

  private getPlayerId(player: string) {
    return player === this.config.activePlayer ? Auth.getInstance().userId ?? player : player;
  }

  private getData(timeOffset: number): GamePublicData {
    const { title, actions } = this.config;
    const data: GamePublicData = {
      title,
      ownerId: 'demo',
      status: 'notStarted',
      players: {},
      playerIds: [],
      questionCount: 1,
      questionIndex: 0,
      date: timestamp(this.startTime),
      choices: [],
    };
    let time = 0;
    let endReached = true;
    actions.forEach((action) => {
      if (time >= timeOffset) {
        endReached = false;
        return;
      }
      if (typeof action.wait === 'number') {
        time += action.wait * 1000;
      } else if (action.join) {
        const playerId = this.getPlayerId(action.join);
        data.players[playerId] = {
          name: action.join,
          symbol: action.symbol,
          score: 0,
          totalScore: 0,
        };
        data.playerIds.push(playerId);
      } else if (action.ready) {
        const playerId = this.getPlayerId(action.ready);
        data.players[playerId].ready = true;
      } else if (action.status) {
        data.status = action.status;
      } else if (action.phase) {
        data.phase = action.phase;
        data.phaseDuration = action.duration * 1000;
        data.phaseStartAt = timestamp(this.startTime + time);
        if (action.question) data.question = action.question;
        if (action.questionSound) data.questionSound = action.questionSound;
        if (action.questionImage) data.questionImage = action.questionImage;
        if (action.questionColor) data.questionColor = action.questionColor;
        if (action.truth) {
          const truth = action.truth.toUpperCase();
          data.truth = truth;
          if (!data.choices.includes(truth)) data.choices.push(truth);
        }
        if (action.truthSound) data.truthSound = action.truthSound;
        if (action.truthImage) data.truthImage = action.truthImage;
        if (action.truthColor) data.truthColor = action.truthColor;
      } else if (action.lie) {
        const playerId = this.getPlayerId(action.lie);
        const choice = action.text.toUpperCase();
        data.players[playerId].lie = choice;
        data.players[playerId].lied = true;
        data.players[playerId].date = timestamp(this.startTime + time);
        if (!data.choices.includes(choice)) data.choices.push(choice);
      } else if (action.choose) {
        const playerId = this.getPlayerId(action.choose);
        const choice = action.text.toUpperCase();
        data.players[playerId].choice = choice;
        data.players[playerId].chosen = true;
        data.players[playerId].date = timestamp(this.startTime + time);
        if (choice === data.truth) {
          data.players[playerId].score += 1000;
          data.players[playerId].totalScore += 1000;
        }
        for (const key in data.players) {
          if (key !== playerId && data.players[key].lie === choice) {
            data.players[key].score += 500;
            data.players[key].totalScore += 500;
          }
        }
      }
    });
    if (endReached) {
      this.startTime = Date.now();
    }
    data.demoProgress = timeOffset / this.totalTime;
    data.isDemoPaused = this.isPaused;
    return data;
  }
}
