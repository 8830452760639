import * as React from 'react';

import { Button } from '../Button';
import type { Menu } from './Menu';

export type MenuOption = React.ComponentProps<typeof Button>;

export function MenuItem(props: { menu: Menu; option: MenuOption; separator?: boolean }) {
  const { menu, option, separator } = props;
  const { onPress, ...buttonProps } = option;
  return (
    <Button
      type="text"
      separator={separator}
      {...buttonProps}
      onPress={
        onPress
          ? async () => {
              await onPress({});
              menu.hide();
            }
          : undefined
      }
    />
  );
}
