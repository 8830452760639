import isEqual from 'lodash/isEqual';
import * as React from 'react';
import { View, Dimensions } from 'react-native';

import { cellTransitions, CellTransitionName } from './transitions';

export type CellNumberValue = number;

export type CellAnimatableProps = {
  opacity: number;
  left: number;
  top: number;
  scaleX: number;
  scaleY: number;
};

export type CellKeyframes = {
  [key: string]: Partial<CellAnimatableProps>;
};

export type CellTransition = Partial<CellAnimatableProps> & {
  keyframes?: CellKeyframes;
  duration?: number;
  delay?: number;
};

export type CellSize = {
  width: number;
  height: number;
};

type CellStateKeyframe = CellAnimatableProps & {
  timestamp: number;
  actualized?: boolean;
};

type Props = CellTransition &
  Partial<CellSize> & {
    style?: any;
    children?: any;
    debug?: boolean;
    debugName?: string;
    rotate?: string;
    clip?: boolean;
    enter?: CellTransition | CellTransitionName;
    exit?: CellTransition | CellTransitionName;
    hidden?: boolean;
    marginTop?: number;
  };

type State = {
  props: Partial<CellAnimatableProps> & {
    keyframes?: CellKeyframes;
  };
  keyframes: CellStateKeyframe[];
};

function getCurrentTime(): number {
  return Date.now();
}

function createKeyframe(
  timestamp: number,
  source: Partial<CellAnimatableProps>,
  fallback?: Partial<CellAnimatableProps>
): CellStateKeyframe {
  return {
    timestamp,
    opacity: source.opacity ?? fallback?.opacity ?? 1,
    left: source.left ?? fallback?.left ?? 0,
    top: source.top ?? fallback?.top ?? 0,
    scaleX: source.scaleX ?? fallback?.scaleX ?? 1,
    scaleY: source.scaleY ?? fallback?.scaleY ?? 1,
  };
}

function createKeyframes(
  props: Props,
  isInitial?: boolean,
  now?: number,
  baseKeyframe?: CellStateKeyframe
): CellStateKeyframe[] {
  const delay = props.delay ?? 0;
  now = (now ?? getCurrentTime()) + delay * 1000;
  if (props.keyframes) {
    const duration = (props.duration ?? 0.5) * 1000;
    const result: CellStateKeyframe[] = [];
    for (const key in props.keyframes) {
      const val = props.keyframes[key];
      let timestamp: number = now;
      if (typeof key === 'number') {
        timestamp = key;
      } else if (typeof key === 'string') {
        timestamp = now + (Number(key.substring(0, key.length - 1)) / 100) * duration;
      }
      baseKeyframe = createKeyframe(timestamp, val, baseKeyframe);
      result.push(baseKeyframe);
    }
    return result;
  } else {
    const duration = (props.duration ?? (isInitial ? 0 : 0.5)) * 1000;
    return [createKeyframe(now + duration, props, baseKeyframe)];
  }
}

function interpolateKeyframe(
  prevKeyframe: CellStateKeyframe,
  nextKeyframe: CellStateKeyframe,
  timestamp: number
): CellStateKeyframe {
  const ratio =
    (timestamp - prevKeyframe.timestamp) / nextKeyframe.timestamp - prevKeyframe.timestamp;
  return {
    timestamp,
    opacity: prevKeyframe.opacity + (nextKeyframe.opacity - prevKeyframe.opacity) * ratio,
    left: prevKeyframe.left + (nextKeyframe.left - prevKeyframe.left) * ratio,
    top: prevKeyframe.top + (nextKeyframe.top - prevKeyframe.top) * ratio,
    scaleX: prevKeyframe.scaleX + (nextKeyframe.scaleX - prevKeyframe.scaleX) * ratio,
    scaleY: prevKeyframe.scaleY + (nextKeyframe.scaleY - prevKeyframe.scaleY) * ratio,
  };
}

export class Cell extends React.Component<Props, State> {
  private nextTransitionTimestamp: number = 0;
  private nextTransitionTimer?: number;
  private renderedKeyframe?: CellStateKeyframe;

  state = Cell.createState(this.props, createKeyframes(this.props, true));

  static createState(props: Props, keyframes: CellStateKeyframe[]): State {
    return {
      props: {
        left: props.left,
        top: props.top,
        opacity: props.opacity,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        keyframes: props.keyframes,
      },
      keyframes,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (
      props.left === state.props.left &&
      props.top === state.props.top &&
      props.opacity === state.props.opacity &&
      props.scaleX === state.props.scaleX &&
      props.scaleY === state.props.scaleY &&
      isEqual(props.keyframes, state.props.keyframes)
    ) {
      return null;
    }

    const now = getCurrentTime();
    const nextIndex = state.keyframes.findIndex((keyframe) => keyframe.timestamp > now);
    const prevIndex = nextIndex > 0 ? nextIndex - 1 : state.keyframes.length - 1;

    let baseKeyframe: CellStateKeyframe | undefined;
    if (nextIndex >= 0 && prevIndex >= 0) {
      baseKeyframe = interpolateKeyframe(
        state.keyframes[prevIndex],
        state.keyframes[nextIndex],
        now
      );
    } else if (prevIndex >= 0) {
      baseKeyframe = state.keyframes[prevIndex];
    }

    const oldKeyframes = state.keyframes.filter(
      (keyframe, index) => !(index < prevIndex || keyframe.timestamp >= now)
    );
    const newKeyframes = createKeyframes(props, false, now, baseKeyframe);
    return Cell.createState(props, [...oldKeyframes, ...newKeyframes]);
  }

  static getCurrentKeyframe(timestamp: number, state: State): CellStateKeyframe {
    const nextIndex = state.keyframes.findIndex(
      (keyframe) => keyframe.timestamp > timestamp || !keyframe.actualized
    );
    if (nextIndex < 0) {
      return state.keyframes[state.keyframes.length - 1];
    }
    return state.keyframes[nextIndex];
  }

  componentDidMount() {
    this.scheduleNextTransition(this.state);
    this.renderedKeyframe!.actualized = true;
  }

  componentWillUnmount() {
    if (this.nextTransitionTimer !== undefined) {
      clearTimeout(this.nextTransitionTimer);
      this.nextTransitionTimer = undefined;
    }
  }

  componentDidUpdate() {
    this.scheduleNextTransition(this.state);
    this.renderedKeyframe!.actualized = true;
  }

  scheduleNextTransition(state: State) {
    const timestamp = getCurrentTime();
    const nextIndex = state.keyframes.findIndex(
      (keyframe) => keyframe.timestamp > timestamp || !keyframe.actualized
    );
    if (nextIndex >= 0 && nextIndex < state.keyframes.length - 1) {
      const nextTimestamp = state.keyframes[nextIndex].timestamp;
      if (nextTimestamp && this.nextTransitionTimestamp !== nextTimestamp) {
        if (this.nextTransitionTimer !== undefined) {
          clearTimeout(this.nextTransitionTimer);
          this.nextTransitionTimer = undefined;
        }
        const duration = Math.max(nextTimestamp - timestamp, 0);
        if (this.props.debug) {
          console.log('SCHEDULED NEXT TRANSITION IN', duration, state.keyframes[nextIndex]);
        }
        this.nextTransitionTimestamp = nextTimestamp;
        this.nextTransitionTimer = setTimeout(() => {
          this.nextTransitionTimer = undefined;
          this.forceUpdate();
        }, duration) as any;
      }
    }
  }

  render() {
    const { style, children, debug, debugName, clip } = this.props;

    const size = Dimensions.get('window');
    // TODO re-render when window-size changes

    // Get transitions
    const timestamp = getCurrentTime();
    const keyframe = Cell.getCurrentKeyframe(timestamp, this.state);
    this.renderedKeyframe = keyframe;
    const { left, top, opacity, scaleX, scaleY } = keyframe;
    const duration = Math.max(keyframe.timestamp - timestamp, 0) / 1000;

    if (this.props.hidden) {
      return null;
    }

    if (debug) {
      console.log(
        `${debugName ?? 'Cell'}.render(${left}, ${top}, ${this.props.width}, ${
          this.props.height
        }), opacity: ${opacity}, duration: ${duration}, keyframes: ${JSON.stringify(
          this.state.keyframes
        )}`
      );
    }

    return (
      <View
        pointerEvents="box-none"
        style={[
          {
            overflow: clip ? 'hidden' : 'visible',
            position: 'absolute',
            left: 0,
            top: 0,
            width: size.width,
            height: size.height,
            opacity,
            transform: [
              { translateX: left },
              { translateY: top },
              { rotateZ: this.props.rotate ?? '0deg' },
              { scaleX },
              { scaleY },
            ],
            // @ts-ignore: Web transitions
            transitionProperty: 'transform, opacity',
            transitionDuration: `${duration}s`,
            // transitionDelay: `${this.props.delay ?? 0}s`,
            // transitionTimingFunction: 'ease-out',
          },
          style,
        ]}>
        {children}
      </View>
    );
  }
}

export function cellTransition(
  transition?: CellTransition | CellTransitionName
): CellTransition | undefined {
  if (!transition) {
    return undefined;
  } else if (typeof transition === 'string') {
    // @ts-ignore
    return cellTransitions[transition];
  } else {
    return transition;
  }
}
