import { setString } from 'expo-clipboard';
import { toJS } from 'mobx';
import * as React from 'react';
import { View, StyleSheet, Share } from 'react-native';

import { Button, ToastContextData, useToast, useMenu } from '../components';
import { observer, store, Template, PublishedTemplate, getContext } from '../store';
import { t, useNavigation, formatLink, is } from '../utils';

async function shareTransferTemplate(template: Template, token: string, toast: ToastContextData) {
  const linkConfig = {
    token,
    referrer: store.auth.userId,
  };
  const url = formatLink('transfer', linkConfig);
  let toastId: string;
  try {
    setString(url);
    const qrCode = is('development')
      ? formatLink('transfer', linkConfig, 'https://liegebeest.nl')
      : url;
    toastId = toast.show({
      title: t('Share this transfer link with the new owner of the template'),
      qrCode,
      hasCloseButton: true,
      duration: 0,
    });
    await Share.share({
      title: t('Lie or die'),
      message: t(
        'Hi, I want you to become owner of my $1 template "$2"',
        t('Lie or die'),
        template.title
      ),
      url,
    });
    toast.hide(toastId);
  } catch (e) {
    const err = e as Error;
    const message = err.message.toLowerCase();
    if (message.includes('abort')) {
      toast.hide(toastId!);
      return;
    }
    if (!message.includes('not supported')) {
      toast.error(err);
    } else {
    }
    setString(url);
    toast.show({ title: t('Copied to clipboard') });
  }
}

export default observer(function TemplateButton(props: {
  template: Template;
  publishedTemplate: PublishedTemplate;
  editMode: boolean;
  onPress: (action: 'name' | 'edit' | 'endEdit' | 'delete' | 'copy' | 'paste') => void;
}) {
  const { template, publishedTemplate, editMode, onPress } = props;
  const navigation = useNavigation();
  const toast = useToast();
  const menu = useMenu();
  if (editMode) {
    return (
      <View style={styles.container}>
        <Button title={t('Done')} header onPress={() => onPress('endEdit')} />
        <Button key="delete" icon="trash" header destructive onPress={() => onPress('delete')} />
        <Button key="copy" icon="copy" header onPress={() => onPress('copy')} />
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <Button
          header
          icon="menu"
          onPress={() =>
            menu.show([
              { title: t('Edit name'), onPress: () => onPress('name') },
              { title: t('Select questions'), onPress: () => onPress('edit') },
              { title: t('Paste copied questions'), onPress: () => onPress('paste') },
              {
                title: t('Duplicate template'),
                onPress: async () => {
                  const newTemplate = await store.myTemplates.add({
                    ...toJS(template.data),
                    title: t('Copy of $1', template.data.title),
                  });
                  navigation.replace('template', { template: newTemplate, isNew: true });
                },
              },
              ...(!publishedTemplate.hasData
                ? [
                    {
                      title: t('Make template public'),
                      onPress: async () => navigation.push('publish', { template }),
                    },
                  ]
                : []),
              {
                title: t('Transfer ownership'),
                onPress: async () => {
                  const token = await template.transfer({});
                  shareTransferTemplate(template, token, toast);
                },
              },
              {
                title: t('Request history'),
                onPress: async () => navigation.push('requests', { template, filter: 'history' }),
              },
              {
                title: t('Delete template'),
                destructive: true,
                onPress: async () => {
                  await template.deletePermanently({});
                  navigation.goBack();
                },
              },
            ])
          }
        />
        <Button
          header
          icon="plus"
          onPress={async () => {
            const questionId = Date.now();
            await template.update({
              [`questions.${questionId}.q`]: '',
              [`questions.${questionId}.a`]: '',
              [`questions.${questionId}.f`]: [],
              [`questions.${questionId}.d`]: getContext().serverTimestamp(),
            });
            navigation.push('question', {
              template,
              questionId,
              isNew: true,
            });
          }}
        />
      </View>
    );
  }
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
});
