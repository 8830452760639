import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Title, useShadowNode, useTheme, Shadows } from '../components';
import { Sounds } from '../sound';
import { GameClientPhaseType } from '../store';

export default function GameQuestionView(props: {
  question: string;
  phaseType: GameClientPhaseType;
  sound?: string;
  image?: string;
}) {
  const { question, sound, phaseType } = props;
  const isShadowNode = useShadowNode();
  const { c } = useTheme();
  React.useEffect(() => {
    if (sound && phaseType === 'lie' && !isShadowNode) {
      Sounds.playUri(sound);
    }
  }, [isShadowNode, sound, phaseType]);
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: c('accent'),
          ...Shadows.regular,
        },
      ]}>
      <Title color={c('accent', 'text')} large>
        {question ?? ''}
      </Title>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginTop: 16,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
});
