import isEqual from 'lodash/isEqual';
import * as React from 'react';

import { Cell, CellKeyframes, CellTransition, cellTransition } from './Cell';

type Props = React.ComponentProps<typeof Cell> & {
  onExit?: () => any;
};

type State = {
  isExit?: boolean;
  transition?: CellTransition;
  keyframes?: CellKeyframes;
};

export class CellContainerNode extends React.Component<Props, State> {
  state: State = {};

  static getKeyFrames(transition: CellTransition, props: Props) {
    const isExit = !!props.onExit;
    const r = (val: any) => (typeof val === 'function' ? val(props) : val);
    return (
      transition.keyframes ?? {
        '0%': {
          ...(transition.opacity != null ? { opacity: isExit ? 1 : r(transition.opacity) } : {}),
          ...(transition.left != null ? { left: isExit ? 0 : r(transition.left) } : {}),
          ...(transition.top != null ? { top: isExit ? 0 : r(transition.top) } : {}),
          ...(transition.scaleX != null ? { scaleX: isExit ? 1 : r(transition.scaleX) } : {}),
          ...(transition.scaleY != null ? { scaleY: isExit ? 1 : r(transition.scaleY) } : {}),
        },
        '100%': {
          ...(transition.opacity != null ? { opacity: isExit ? r(transition.opacity) : 1 } : {}),
          ...(transition.left != null ? { left: isExit ? r(transition.left) : 0 } : {}),
          ...(transition.top != null ? { top: isExit ? r(transition.top) : 0 } : {}),
          ...(transition.scaleX != null ? { scaleX: isExit ? r(transition.scaleX) : 1 } : {}),
          ...(transition.scaleY != null ? { scaleY: isExit ? r(transition.scaleY) : 1 } : {}),
        },
      }
    );
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const isExit = !!props.onExit;
    const transition = cellTransition(isExit ? props.exit : props.enter);
    if (transition === state.transition && isExit === state.isExit) return null;
    const keyframes = transition ? CellContainerNode.getKeyFrames(transition, props) : undefined;
    return {
      isExit,
      transition,
      keyframes: isEqual(keyframes, state.keyframes) ? state.keyframes : keyframes,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.onExit && !prevProps.onExit) {
      setTimeout(
        this.props.onExit,
        ((this.state.transition?.duration ?? 1) + (this.state.transition?.delay ?? 0)) * 1000
      );
    }
  }

  render() {
    const { enter, exit, onExit, children, ...cellProps } = this.props;
    const { transition, keyframes } = this.state;

    return (
      <Cell
        debugName={cellProps.debugName ?? 'CellContainerNode'}
        {...cellProps}
        duration={transition?.duration ?? 1}
        delay={transition?.delay ?? 0}
        keyframes={keyframes}>
        {children}
      </Cell>
    );
  }
}
