import * as React from 'react';

import { Button, useTheme } from '../components';
import { store, observer } from '../store';
import { t, useNavigation } from '../utils';

export default observer(function AuthButton() {
  const { userId, userName } = store.auth;
  const navigation = useNavigation();
  const { c } = useTheme();
  if (!userId) return null;
  return (
    <Button
      header
      color={c('base', 'text')}
      title={userId ? `${userName ?? t('Account')} 😃` : t('Sign in')}
      onPress={() => navigation.push(userId ? 'account' : 'signin')}
    />
  );
});
