import * as React from 'react';

import { Button } from '../components';

export default function GameChoiceItem(props: {
  text: string;
  disabled?: boolean;
  onPress?: () => any;
}) {
  const { text, onPress, disabled } = props;
  return <Button type="filled" small disabled={disabled} title={text} onPress={onPress} />;
}
