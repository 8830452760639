import * as React from 'react';

import { List } from '../components';
import AdminActionListItem from './AdminActionListItem';
import { adminActions } from './AdminActions';

export default function AdminActionsScreen() {
  return (
    <List<string>
      dataSource={Object.keys(adminActions)}
      renderItem={(actionId) => <AdminActionListItem adminActionId={actionId} />}
    />
  );
}
