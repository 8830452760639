import * as React from 'react';

import { Button, List, useMenu, useToast } from '../components';
import { observer, store, TemplateDiff } from '../store';
import useTemplateQuestionsSections from '../template/useTemplateQuestionsSections';
import { t, useTemplateRequest, useNavigation, usePublishedTemplate } from '../utils';
import TemplateRequestListItem from './TemplateRequestListItem';

export default observer(function TemplateRequestScreen() {
  const navigation = useNavigation();
  const menu = useMenu();
  const toast = useToast();
  const templateRequest = useTemplateRequest()!;
  const publishedTemplate = usePublishedTemplate();
  const isOwner = templateRequest.data.ownerId === store.auth.userId;
  const isModerator = store.auth.isAdmin;
  const { status } = templateRequest;
  const isLoading = (templateRequest.isLoading || publishedTemplate?.isLoading) ?? false;
  const diff =
    publishedTemplate?.hasData && templateRequest.hasData && templateRequest.data.data
      ? TemplateDiff.create(publishedTemplate, templateRequest)
      : undefined;
  const sections = useTemplateQuestionsSections({
    template: templateRequest,
    diff,
    showFilter: true,
  });

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          header
          icon="menu"
          onPress={() =>
            menu.show(
              isModerator || (isOwner && (status === 'notReviewed' || status === 'rejected'))
                ? [
                    {
                      title: status === 'notReviewed' ? t('Cancel request') : t('Delete request'),
                      destructive: true,
                      onPress: async () => {
                        await templateRequest.deletePermanently({});
                        navigation.goBack();
                      },
                    },
                  ]
                : []
            )
          }
        />
      ),
    });
  }, [templateRequest.hasData]);

  return (
    <List loading={isLoading} sections={sections}>
      {isModerator && status === 'notReviewed' ? (
        <List.Button
          title={t('Start review')}
          onPress={async () => {
            await templateRequest.startReview({ message: 'TODO' });
            toast.show({ title: t('Review in progress') + '...' });
          }}
        />
      ) : undefined}
      {isModerator && status === 'underReview' ? (
        <List.Button
          title={t('Accept request')}
          color="success"
          onPress={async () => {
            await templateRequest.accept({ message: 'TODO' });
            toast.show({ title: t('Request accepted') });
            navigation.goBack();
          }}
        />
      ) : undefined}
      {isModerator && status === 'underReview' ? (
        <List.Button
          title={t('Reject request')}
          destructive
          confirmed
          onPress={async () => {
            await templateRequest.reject({ message: 'TODO' });
            toast.show({ title: t('Request rejected') });
            navigation.goBack();
          }}
        />
      ) : undefined}
      <TemplateRequestListItem templateRequest={templateRequest} onPress={null} />
    </List>
  );
});
