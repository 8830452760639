import * as React from 'react';

import { List, useToast } from '../components';
import { observer, functions, httpsCallable } from '../store';
import { useRoute, useNavigation } from '../utils';
import { getAdminAction } from './AdminActions';

export default observer(function AdminDatabaseMigration() {
  const adminActionId: string = useRoute().params.adminActionId;
  const adminAction = getAdminAction(adminActionId)!;
  const toast = useToast();
  const navigation = useNavigation();
  // @ts-ignore
  const [fields, setFields] = React.useState(adminAction.fields);
  const { title, action, inputs } = adminAction;

  return (
    <List>
      <List.Text large>{title}</List.Text>
      <List.Text small>
        Executing admin actions is dangerous. Do not continue unless you know what you are doing!
      </List.Text>
      {Object.keys(inputs ?? {}).map((key) => (
        <List.TextInput
          key={key}
          label={key}
          onChangeText={(value: string) => {
            try {
              const result = inputs?.[key](value);
              setFields((oldFields: any) => ({
                ...oldFields,
                ...result,
              }));
            } catch (err) {
              toast.error(err as Error, 'adminActionError');
            }
          }}
        />
      ))}
      {Object.keys(fields).map((key) =>
        typeof fields[key] === 'string' ? (
          <List.TextInput
            key={key}
            label={key}
            value={fields[key] ?? ''}
            onChangeText={(value: string) =>
              setFields((oldFields: any) => ({
                ...oldFields,
                [key]: value,
              }))
            }
          />
        ) : undefined
      )}
      <List.Button
        title={title}
        onPress={async () => {
          const request = {
            ...fields,
            api: 'admin',
            action,
          };
          console.log(
            `Executing admin action: ${adminActionId}, request: ${JSON.stringify(request)}`
          );
          const response = await httpsCallable(functions, 'apiv1')(request);
          console.log(`Admin action executed: ${JSON.stringify(response.data)}`);
          toast.show({
            title: `"${title}" completed`,
          });
          adminAction.onSuccess?.({
            request,
            response: response.data,
            navigation,
            toast,
          });
        }}
      />
    </List>
  );
});
