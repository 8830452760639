// import * as Localization from 'expo-localization';
import { is } from './is';

const locale = is('development') || location.origin.includes('liegebeest.nl') ? 'nl-NL' : 'en-US';

export function getLocale(): string {
  return locale;
  // return Localization.locale;
}

export function getLanguageCode(): string {
  return getLocale().substring(0, 2).toLowerCase() || 'en';
}

export function getCountryCode(): string {
  const locale = getLocale();
  if (locale.length === 5) {
    return locale.substring(3, 5).toUpperCase();
  }
  return locale.substring(0, 2).toUpperCase() || 'US';
}
