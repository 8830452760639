import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StyleSheet, View, ScrollView, SectionList } from 'react-native';

import { is, t } from '../../utils';
import { Title } from '../Text';
import { ToastItem } from '../toast/ToastItem';
import { ListButton } from './ListButton';
import { ListCheck } from './ListCheck';
import { ListItem } from './ListItem';
import { ListNumberInput } from './ListNumberInput';
import { ListTabBar } from './ListTabBar';
import { ListText } from './ListText';
import { ListTextInput } from './ListTextInput';

export interface IListDataItem {
  readonly id: string | undefined;
}

export type ListDataItem = IListDataItem | string;

export type ListSection<T extends ListDataItem = any> = {
  dataSource?: any;
  renderItem?: (item: T, index: number) => React.ReactElement;
  renderSectionHeader?: () => React.ReactElement;
};

type Props<T extends ListDataItem> = {
  children?: any;
  loading?: boolean;
  error?: Error;
  placeholder?: string;
  dataSource?: any;
  sections?: ListSection[];
  renderItem?: (item: T, index: number) => React.ReactElement;
  renderSectionHeader?: () => React.ReactElement;
};

function dataSourceToArray<T extends ListDataItem>(dataSource: any): T[] {
  if (!dataSource) {
    return [];
  } else if (Array.isArray(dataSource)) {
    return dataSource;
  } else if (dataSource.docs) {
    return dataSource.docs.slice(0);
  }
  return [];
}

function keyExtractor<T extends ListDataItem>(item: T): string {
  // @ts-ignore
  return typeof item === 'string' ? item : item.id!;
}

function ListComponent<T extends ListDataItem = ListDataItem>(props: Props<T>) {
  const { children, placeholder, loading, error, dataSource, renderItem, renderSectionHeader } =
    props;
  const sections: ListSection[] =
    props.sections ?? (props.dataSource ? [{ dataSource, renderItem, renderSectionHeader }] : []);
  const listSections = sections.map((section: any) => ({
    data: dataSourceToArray<T>(section.dataSource),
    renderItem: (data: any) => section.renderItem(data.item, data.index),
  }));

  const isLoading =
    loading || // eslint-disable-line
    sections.find(({ dataSource }) => dataSource.isLoading || dataSource?.isActive === false);
  const isEmpty =
    !isLoading &&
    !!placeholder &&
    !React.Children.count(children) &&
    !listSections.find(({ data }) => data.length);

  if (error) {
    return (
      <View style={styles.placeholder}>
        <ToastItem title={error.message} color="error" />
      </View>
    );
  } else if (isLoading || isEmpty) {
    return (
      <View style={styles.placeholder}>
        <Title small>{isLoading ? `${t('Loading')} ...` : placeholder!}</Title>
      </View>
    );
  } else if (!sections.length) {
    return (
      <View style={styles.container}>
        <ScrollView style={styles.content}>{children}</ScrollView>
      </View>
    );
  }

  // Render children as the first section-header
  if (children) {
    sections.unshift({ dataSource: [], renderSectionHeader: () => children });
    listSections.unshift({ data: [], renderItem: () => null });
  }

  return (
    <View style={styles.container}>
      <SectionList
        style={styles.content}
        sections={listSections}
        renderSectionHeader={(listSection) => {
          const section = sections[listSections.indexOf(listSection.section)];
          return section.renderSectionHeader?.() ?? renderSectionHeader?.() ?? null;
        }}
        keyExtractor={keyExtractor}
        // stickySectionHeadersEnabled
      />
    </View>
  );

  /* return (
      <ScrollView style={styles.container}>
        {data?.map((item, index) => {
          return <View key={keyExtractor(item)}>{renderItem({ item, index })}</View>;
        })}
      </ScrollView>
    ); */
}

export const List: typeof ListComponent & {
  Button: typeof ListButton;
  Item: typeof ListItem;
  TextInput: typeof ListTextInput;
  NumberInput: typeof ListNumberInput;
  Text: typeof ListText;
  TabBar: typeof ListTabBar;
  Check: typeof ListCheck;
} = observer(ListComponent) as any;

List.Button = ListButton;
List.Item = ListItem;
List.TextInput = ListTextInput;
List.NumberInput = ListNumberInput;
List.Text = ListText;
List.TabBar = ListTabBar;
List.Check = ListCheck;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    paddingTop: 16,
    maxWidth: is('mobile') ? undefined : 800,
  },
  placeholder: {
    flex: 1,
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
