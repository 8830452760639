import * as React from 'react';
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';

import { Animation, AnimationContainer, AnimationConfig } from '../animation';
import { useTheme, Shadows, useHeaderHeight } from '../theme';
import { MenuItem, MenuOption } from './MenuItem';

export class Menu {
  private setVisible(_fn: () => boolean) {}
  private setOptions(_fn: () => MenuOption[]) {}

  async show(options: MenuOption[]) {
    this.setVisible(() => true);
    this.setOptions(() => options);
  }

  hide = () => {
    this.setVisible(() => false);
  };
}

const animationConfig: AnimationConfig = {
  enter: {
    duration: 0.2,
    opacity: 0,
    transform: [{ translateX: 100 }],
  },
  exit: {
    duration: 0.3,
    opacity: 0,
  },
};

export const MenuRenderer = (props: { menu: Menu }) => {
  const { menu } = props;
  const { c } = useTheme();
  const { headerHeight } = useHeaderHeight();
  const [visible, setVisible] = React.useState(false);
  const [options, setOptions] = React.useState<MenuOption[]>([]);
  // @ts-expect-error Property 'setVisible' is private and only accessible within class 'Menu'
  menu.setVisible = setVisible;
  // @ts-expect-error Property 'setOptions' is private and only accessible within class 'Menu'
  menu.setOptions = setOptions;
  return (
    <View style={StyleSheet.absoluteFill} pointerEvents={visible ? 'auto' : 'none'}>
      <View style={[styles.background, visible ? styles.visible : undefined]} />
      <TouchableWithoutFeedback onPress={menu.hide}>
        <View style={styles.touchable}>
          <AnimationContainer style={{ marginTop: headerHeight + 8 }} clip debugName="Menu">
            {visible ? (
              <Animation id="menu" config={animationConfig}>
                <View style={[styles.content, { backgroundColor: c('surface') }]}>
                  {options.map((option, idx) => (
                    <MenuItem key={idx} menu={menu} option={option} separator={idx > 0} />
                  ))}
                </View>
              </Animation>
            ) : undefined}
          </AnimationContainer>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = StyleSheet.create({
  background: {
    ...StyleSheet.absoluteFillObject,
    opacity: 0,
    backgroundColor: '#00000022',
  },
  visible: {
    opacity: 1,
  },
  touchable: {
    flex: 1,
  },
  content: {
    ...Shadows.regular,
    borderRadius: 8,
    flexDirection: 'column',
    alignSelf: 'flex-end',
    width: 300,
    marginRight: 8,
  },
});
