import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { PureComponent, useShadowNode } from '../components';
import { Sounds } from '../sound';
import { Game, GameClientPhaseStatus, observer } from '../store';
import GameChoiceResultItem from './GameChoiceResultItem';

export default observer(function GameTruthView(props: {
  game: Game;
  phaseStatus: GameClientPhaseStatus;
  sound?: string;
  image?: string;
}) {
  const { game, phaseStatus, sound } = props;
  const { choices, truth } = game;
  const choiceResult = choices.find((c) => c.text === truth)!;
  const isShadowNode = useShadowNode();
  React.useEffect(() => {
    if (sound && !isShadowNode) {
      Sounds.playUri(sound);
    }
  }, [isShadowNode, sound]);
  return (
    <PureComponent props={[phaseStatus]}>
      {() => (
        <View style={styles.container}>
          <GameChoiceResultItem
            choiceResult={choiceResult}
            phase={phaseStatus === 'noInput' ? 'none' : 'chosenBy'}
          />
        </View>
      )}
    </PureComponent>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
