import { Document } from 'firestorter';

import { TemplateQuestions } from './TemplateQuestions';
import { functions, httpsCallable } from './firebase';
import { asyncAction } from './framework';
import { ITemplate } from './interfaces';
import type { PublishedTemplateData } from './types';

export class PublishedTemplate extends Document<PublishedTemplateData> implements ITemplate {
  public readonly questions = new TemplateQuestions(this, 'questions');

  get title() {
    return this.data.title;
  }

  get date() {
    return this.data.date;
  }

  get version() {
    return this.data.version;
  }

  unpublish = asyncAction(async (config: { message: string }) => {
    await httpsCallable(
      functions,
      'apiv1'
    )({
      api: 'template',
      action: 'unpublish',
      templateId: this.id,
      message: config.message,
    });
  });

  navigateToQuestion(navigation: any, questionId: string) {
    navigation.push('publishedQuestion', {
      publishedTemplate: this,
      questionId,
    });
  }
}
