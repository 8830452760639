import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Title, useTheme } from '../components';

export default function GamePlayerCount(props: { count: number }) {
  const { count } = props;
  const { c } = useTheme();
  return (
    <View style={styles.container}>
      <Title small color={c('surface', 'text')}>{`${count}x`}</Title>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 40,
    alignItems: 'center',
  },
});
