import { Collection, CollectionSource, ICollectionOptions } from 'firestorter';
import map from 'lodash/map';
import { toJS } from 'mobx';

import { PublicTemplateList } from './PublicTemplateList';
import { PublicTemplateListItemData } from './types';

export class PublicTemplates extends Collection<PublicTemplateList> {
  constructor(source: CollectionSource, options?: ICollectionOptions<PublicTemplateList>) {
    super(source, {
      ...(options ?? {}),
      createDocument: (source, options) => new PublicTemplateList(source, options),
    });
  }

  get templates(): PublicTemplateListItemData[] {
    return this.docs.flatMap((doc) => doc.templates.toArray());
  }

  map<R>(callback: (data: PublicTemplateListItemData, id: string) => R): R[] {
    return map(
      this.docs
        .map((doc) => toJS(doc.data.templates))
        .reduce(
          (prev, cur) => ({
            ...prev,
            ...cur,
          }),
          {}
        ),
      callback
    );
  }
}
