import * as React from 'react';
import { StyleSheet } from 'react-native';

import { Title } from '../Text';

export function ListText(props: React.ComponentProps<typeof Title>) {
  return <Title {...props} style={[styles.container, props.style]} />;
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    // marginTop: 8,
    marginBottom: 8,
  },
});
