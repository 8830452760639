import { Collection, CollectionSource, ICollectionOptions } from 'firestorter';

import { TemplateRequest } from './TemplateRequest';

export class TemplateRequests extends Collection<TemplateRequest> {
  constructor(source: CollectionSource, options?: ICollectionOptions<TemplateRequest>) {
    super(source, {
      ...(options ?? {}),
      createDocument: (source, options) => new TemplateRequest(source, options),
    });
  }
}
