import * as React from 'react';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';

import { Button, List, Spacer, useToast, Theme, Background, Check, Shadows } from '../components';
import GamePlayerItem from '../game/GamePlayerItem';
import { GamePlayer } from '../store';

const players = [
  { name: 'Speler', symbol: '🤡', color: '#614ac5', score: 500 },
  { name: 'Speler', symbol: '💩', color: '#614ac5', score: 500 },
  { name: 'Speler', symbol: '👻', color: '#614ac5', score: 500 },
  { name: 'Speler', symbol: '👽', color: '#614ac5', score: 500 },
  { name: 'Speler', symbol: '🤖', color: '#614ac5', score: 500 },
  { name: 'Speler', symbol: '🤠', color: '#614ac5', score: 500 },
];
const gamePlayers = players as GamePlayer[];

const colors = [undefined, '#e65a09', '#D9213C', '#119648', '#F04932', '#2b2b2b'];
const backgrounds = [
  undefined,
  require('../assets/images/background.jpg'),
  require('../assets/images/modal-background.jpg'),
];

export default function DesignLanguageScreen() {
  const toast = useToast();
  const [themeColor, setThemeColor] = React.useState<string | undefined>();
  const [background, setBackground] = React.useState<string | undefined>();
  const [rotate, setRotate] = React.useState<boolean>(false);

  return (
    <List>
      {themeColor ? <Theme accentColor={themeColor} /> : undefined}
      <Background image={background} rotate={rotate} />
      <List.Text small>Thema</List.Text>
      <View style={styles.row}>
        {colors.map((color, idx) => (
          <TouchableOpacity
            key={idx}
            style={[styles.color, { backgroundColor: color }]}
            onPress={() => setThemeColor(color)}
          />
        ))}
      </View>
      <View style={styles.row}>
        {backgrounds.map((background, idx) => (
          <TouchableOpacity
            key={idx}
            style={styles.backgroundContainer}
            onPress={() => setBackground(background)}>
            <Image style={styles.background} source={background} />
          </TouchableOpacity>
        ))}
        <Check title="Draaiend" value={rotate} onChangeValue={setRotate} />
      </View>
      <List.Text small>Teksten</List.Text>
      <List.Text xxlarge>Tekst (xxl)</List.Text>
      <List.Text xlarge>Tekst (xl)</List.Text>
      <List.Text large>Tekst (l)</List.Text>
      <List.Text>Tekst (n)</List.Text>
      <List.Text small>Tekst (s)</List.Text>
      <List.Item
        label="Elementen"
        title="Regel"
        value="Waarde"
        onPress={() =>
          toast.show({
            title: `Notificatie ${players[Math.floor(Math.random() * players.length)].symbol}`,
            duration: 5,
          })
        }
      />
      <View style={styles.row}>
        <GamePlayerItem player={gamePlayers[0]} valueType="roundScore" />
      </View>
      <View style={styles.row}>
        {gamePlayers.map((player, idx) => (
          <View key={player.symbol} style={{ transform: [{ translateX: idx * -16 }] }}>
            <GamePlayerItem badge player={player} />
          </View>
        ))}
      </View>
      <List.Text small>Knoppen</List.Text>
      <View style={styles.row}>
        <Button title="Normaal" />
        <Spacer />
        <Button title="Ingedrukt" pressed />
        <Spacer />
        <Button title="Uitgesch" disabled />
      </View>
      <View style={styles.row}>
        <Button title="Klein" small />
        <Spacer />
        <Button title="Ingedrukt" small pressed />
        <Spacer />
        <Button title="Uitgesch" small disabled />
      </View>
      <View style={styles.row}>
        <Button
          title="Gevaarlijk"
          destructive
          onPress={() => toast.error(new Error('Gevaarlijke actie'))}
        />
        <Spacer />
        <Button title="Ingedrukt" destructive pressed />
        <Spacer />
        <Button title="Uitgesch" destructive disabled />
      </View>
      <View style={styles.row}>
        <Button title="Navigate knop" header />
        <Spacer />
        <Button title="Ingedrukt" icon="menu" header pressed />
        <Spacer />
        <Button title="Ingedrukt" icon="menu" header disabled />
      </View>
      <List.TextInput label="Tekst invoer" placeholder="Tekst invoer" />
      <List.TextInput style={styles.largeTextInput} placeholder="Meerlijnig" multiline />
    </List>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    marginHorizontal: 16,
  },
  color: {
    width: 30,
    height: 30,
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 4,
    ...Shadows.regular,
  },
  largeTextInput: {
    height: 200,
  },
  backgroundContainer: {
    width: 50,
    height: 50,
    borderRadius: 4,
    marginRight: 8,
    ...Shadows.regular,
  },
  background: {
    width: 50,
    height: 50,
    borderRadius: 4,
    resizeMode: 'cover',
  },
});
