import { observable, runInAction } from 'mobx';

export interface IAsyncAction<RequestType = void, ResponseType = void> {
  (request: RequestType): Promise<ResponseType>;
  readonly inProgress: boolean;
  readonly request?: RequestType;
  readonly response?: ResponseType;
  readonly error?: Error;
  readonly errorMessage: string;
}

export type AsyncActionMode =
  | 'single' // Can not be invoked while another invoke is still in progress (throws error when a previous invoke is still in progress)
  | 'queue' // Invokes are queued; and executed serially
  | 'last'; // Wait for the last invoke to complete; and then invoke the last in the queue (discard any invokes in the middle of the queue)

/**
 * Creates a callable action object with an observable  `inProgress`
 * property, as well as error, response and request properties.
 *
 * This method can be used to easily create functions (aka actions)
 * for which the executing status/result is stored in an observable,
 * which can be easily monitored and reacted to from a React component.
 * This makes writing React Component code easier and requires less
 * state administration in your Components.
 *
 * Usage
 * ```js
 * // Store
 * class MyStore {
 *   ...
 *   save = asyncAction(async () => {
 *     ... Perform you save logic here
 *   });
 *   ...
 * }
 *
 * const store = new MyStore();
 * ```
 *
 * ```jsx
 * // Component
 * const MyComp = observer(() => {
 *   return (
 *     <View>
 *       <Button loading={store.save.inProgress} onPress={() => store.save()} />
 *     </View>
 * });
 * ```
 *
 * @param {function} callFn - Function to execute
 * @param {object} [config] - Configuration option
 * @param {AsyncActionMode} [config.mode] - Mode, either "single" (default), "queue" or "last"
 * @return {IAsyncAction} A callable action
 */

export function asyncAction<RequestType, ResponseType>(
  callFn: (request: RequestType) => Promise<ResponseType>,
  config?: {
    mode: AsyncActionMode;
  }
): IAsyncAction<RequestType, ResponseType> {
  const _inProgress = observable.box(false);
  const _request = observable.box<RequestType | undefined>(undefined);
  const _response = observable.box<ResponseType | undefined>(undefined);
  const _error = observable.box<Error | undefined>(undefined);
  const mode: AsyncActionMode = (config ? config.mode : undefined) ?? 'single';
  let lastCall: Promise<ResponseType | undefined> = Promise.resolve<ResponseType | undefined>(
    undefined
  );
  let invokeCount = 0;
  const func = async (request: RequestType) => {
    const prevCall = lastCall;
    const invokeIndex = ++invokeCount;
    async function fn() {
      const inProgress = runInAction(() => _inProgress.get());
      if (inProgress) {
        if (mode === 'single') {
          throw new Error(
            `Operation already in progress, new: ${JSON.stringify(
              request
            )}, inProgress ${JSON.stringify(_request.get())}`
          );
        } else {
          await prevCall;
          if (mode === 'last' && invokeIndex !== invokeCount) {
            // console.log('Ignoring request');
            return lastCall;
          }
        }
      }
      runInAction(() => {
        _inProgress.set(true);
        _request.set(request);
        _response.set(undefined);
        _error.set(undefined);
      });
      try {
        const response = await callFn(request);
        runInAction(() => {
          _response.set(response);
          _inProgress.set(false);
        });
        return response;
      } catch (err) {
        runInAction(() => {
          _inProgress.set(false);
          _error.set(err as Error);
        });
        throw err;
      }
    }
    lastCall = fn();
    return lastCall;
  };
  Object.defineProperties(func, {
    inProgress: {
      get(): boolean {
        return _inProgress.get();
      },
    },
    request: {
      get(): RequestType | undefined {
        return _request.get();
      },
    },
    response: {
      get(): ResponseType | undefined {
        return _response.get();
      },
    },
    error: {
      get(): Error | undefined {
        return _error.get();
      },
    },
    errorMessage: {
      get(): string {
        const err = _error.get();
        return err ? err.message : '';
      },
    },
    mode: {
      get(): AsyncActionMode {
        return mode;
      },
    },
  });

  return func as any;
}
