import isEqual from 'lodash/isEqual';
import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { List, Icon, Spacer } from '../components';
import { observer, ITemplate, TemplateDiff } from '../store';
import { t, useTemplateRequest, useRoute, useTemplate, usePublishedTemplate } from '../utils';
import TemplateQuestionInput from './TemplateQuestionInput';

export default observer(function TemplateQuestionScreen() {
  const { questionId } = useRoute().params;
  const privateTemplate = useTemplate();
  const templateRequest = useTemplateRequest();
  const publishedTemplate = usePublishedTemplate()!;
  const template: ITemplate | undefined = privateTemplate ?? templateRequest;
  // eslint-disable-next-line
  const isLoading = template?.isLoading || publishedTemplate?.isLoading || false;
  const questions = template?.questions ?? publishedTemplate.questions;
  const diff =
    !isLoading && template?.hasData && publishedTemplate.hasData
      ? TemplateDiff.create(publishedTemplate, template, questionId)
      : undefined;
  let oldQuestion = diff?.questions[questionId].oldQuestion;
  let newQuestion = questions.get(questionId) ?? diff?.questions[questionId].newQuestion;
  if (!newQuestion && oldQuestion) {
    newQuestion = oldQuestion;
    oldQuestion = undefined;
  }
  const isQuestionChanged =
    oldQuestion &&
    (oldQuestion.q !== newQuestion?.q ||
      oldQuestion.qs !== newQuestion?.qs ||
      oldQuestion.qi !== newQuestion?.qi);
  const isAnswerChanged =
    oldQuestion &&
    (oldQuestion.a !== newQuestion?.a ||
      oldQuestion.as !== newQuestion?.as ||
      oldQuestion.ai !== newQuestion?.ai);
  const isFakeAnswerChanged = oldQuestion && !isEqual(oldQuestion.f, newQuestion?.f);
  return (
    <List loading={isLoading}>
      {isQuestionChanged ? (
        <TemplateQuestionInput
          multiline
          value={oldQuestion?.q ?? ''}
          sound={oldQuestion?.qs}
          image={oldQuestion?.qi}
          editable={false}
          color="error"
        />
      ) : undefined}
      {isQuestionChanged ? (
        <View style={styles.changeContainer}>
          <Icon name="arrow-down" color="success" size={40} />
        </View>
      ) : undefined}
      <TemplateQuestionInput
        multiline
        value={newQuestion?.q ?? ''}
        sound={newQuestion?.qs}
        image={newQuestion?.qi}
        editable={false}
        color={isQuestionChanged ? 'success' : undefined}
      />
      <Spacer small />
      <List.Text small>{t('Answer')}</List.Text>
      {isAnswerChanged ? (
        <TemplateQuestionInput
          value={oldQuestion?.a ?? ''}
          sound={oldQuestion?.as}
          image={oldQuestion?.ai}
          editable={false}
          color="error"
        />
      ) : undefined}
      {isAnswerChanged ? (
        <View style={styles.changeContainer}>
          <Icon name="arrow-down" color="success" size={40} />
        </View>
      ) : undefined}
      <TemplateQuestionInput
        value={newQuestion?.a ?? ''}
        sound={newQuestion?.as}
        image={newQuestion?.ai}
        editable={false}
        color={isAnswerChanged ? 'success' : undefined}
      />
      <Spacer small />
      <List.Text small>{t('Fake answers')}</List.Text>
      {isFakeAnswerChanged ? (
        <List.TextInput
          style={styles.fakeAnswersInput}
          multiline
          value={oldQuestion?.f?.join(', ') ?? ''}
          editable={false}
          color="error"
        />
      ) : undefined}
      {isFakeAnswerChanged ? (
        <View style={styles.changeContainer}>
          <Icon name="arrow-down" color="success" size={40} />
        </View>
      ) : undefined}
      <List.TextInput
        style={styles.fakeAnswersInput}
        multiline
        value={newQuestion?.f?.join(', ') ?? ''}
        editable={false}
        color={isFakeAnswerChanged ? 'success' : undefined}
      />
    </List>
  );
});

const styles = StyleSheet.create({
  fakeAnswersInput: {
    height: 90,
  },
  changeContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 8,
  },
});
