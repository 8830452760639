import { GameSettingsData } from '../store/types';

export const GameSettings: GameSettingsData = {
  introDuration: 8 * 1000,
  lieDuration: 30 * 1000,
  chooseDuration: 30 * 1000,
  resultsDuration: 30 * 1000,
  minPlayers: 1,
  maxPlayers: 50,
  questionCount: 10,
};

export const GameScoring = {
  findingTruth: 1000,
  receivingLie: 500,
};

export const GamePlayerSymbols = [
  '🤡',
  '💩',
  '👻',
  '👽',
  '🤖',
  '👾',
  '🤠',
  '💧',
  '💣',
  '🐸',
  '🦆',
  '👶',
  '🛎️',
  '🎺',
  '🥁',
  '🐈',
  '🐩',
  '🎸',
  '🚗',
  '🥸',
  '🎹',
  '🦉',
  '🐝',
  '🛵',
  '🐴',
  '🐘',
  '👹',
  '👅',
  '🏍️',
  '🏄',
  /* '🚀',
  '🦗',
  '🦟',
  '🦀',
  '🎃', */
];
