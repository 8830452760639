import * as React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Icon } from './Icon';
import { Spacer } from './Spacer';
import { Title } from './Text';
import { useTheme, ColorName } from './theme';

export const Check = (props: {
  value?: boolean;
  title?: string;
  onChangeValue?: (value: boolean) => any;
  style?: any;
  color?: ColorName;
}) => {
  const { value, title, onChangeValue, style, color } = props;
  const { c } = useTheme();
  return (
    <TouchableOpacity
      style={[styles.container, style]}
      disabled={!onChangeValue}
      onPress={() => onChangeValue?.(!value)}>
      <Icon
        name={value ? 'check' : 'uncheck'}
        color={color ? c(color) : value ? c('accent') : c('base', 'text')}
        size="large"
      />
      <Spacer />
      {title ? <Title small>{title}</Title> : undefined}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
