import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { List, Icon, IconName, ColorName, useTheme } from '../components';
import { observer, TemplateDiff, ITemplate } from '../store';
import { useNavigation } from '../utils';

export default observer(function TemplateQuestionListItem(
  props: Partial<React.ComponentProps<typeof List.Item>> & {
    questionId: string;
    template: ITemplate;
    diff?: TemplateDiff;
    edit?: boolean;
  }
) {
  const { template, diff, questionId, onPress, edit, ...otherProps } = props;
  const { c } = useTheme();
  const navigation = useNavigation();
  const templateQuestion = template?.questions.get(questionId);
  const oldQuestion = diff?.questions[questionId]?.oldQuestion;
  const newQuestion = diff?.questions[questionId]?.newQuestion;
  const question = templateQuestion ?? oldQuestion;
  let color: ColorName | undefined = undefined;
  let icon: IconName | undefined = undefined;
  if (oldQuestion && newQuestion) {
    color = 'accent';
    icon = 'edit';
  } else if (!oldQuestion && newQuestion) {
    color = 'success';
    icon = 'plus';
  } else if (!newQuestion && oldQuestion) {
    color = 'error';
    icon = 'trash';
  } else {
    color = c('accent', 'back', 'disabled');
  }

  return (
    <List.Item
      small
      title={question?.q ?? ''}
      color={diff ? color : undefined}
      icon={diff ? icon : undefined}
      numberOfLines={2}
      chevron={false}
      onPress={
        onPress === undefined
          ? () =>
              edit
                ? navigation.push('question', {
                    template,
                    questionId,
                  })
                : template.navigateToQuestion(navigation, questionId)
          : onPress
      }
      value={
        <View style={styles.valueContainer}>
          {question?.qi || question?.ai ? <Icon name="image" color={color} /> : undefined}
          {question?.qs || question?.as ? <Icon name="sound" color={color} /> : undefined}
        </View>
      }
      {...otherProps}
    />
  );
});

const styles = StyleSheet.create({
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 4,
  },
});
