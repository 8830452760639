import * as React from 'react';
import { View } from 'react-native';

type ColorStates = {
  neutral: string;
  disabled: string;
  pressed?: string;
};

type Color = {
  back: string | ColorStates;
  text: string | ColorStates;
  border?: string | ColorStates;
};

type Colors = {
  base: Color;
  surface: Color;
  accent: Color;
  error: Color;
  success: Color;
};

export type ColorField = keyof Color;

export type ColorState = keyof ColorStates;

export type ColorName = keyof Colors;

export function transparentColor(color: string, opacity: number) {
  const val = Math.round(opacity * 255);
  return `${color.substring(0, 7)}${val < 16 ? '0' : ''}${val.toString(16)}`;
}

export function invertColor(color: string) {
  color = color.substring(1); // remove #
  const num = 0xffffff ^ parseInt(color, 16);
  color = num.toString(16); // convert to hex
  color = ('000000' + color).slice(-6); // pad with leading zeros
  color = '#' + color; // prepend #
  return color;
}

export type ThemeProps = {
  accentColor: string;
  baseColor?: string;
  baseTextColor?: string;
  backgroundColor?: string;
  backgroundTextColor?: string;
  errorColor?: string;
  successColor?: string;
};

export class ThemeData {
  public readonly colors: Colors;

  constructor(colors: Colors) {
    this.colors = colors;
  }

  c = (color: ColorName, field: ColorField = 'back', state: ColorState = 'neutral'): ColorName => {
    let colorCode: string = '';
    let c: Color | undefined = this.colors[color];
    if (!c && field !== 'back') {
      c = Object.values(this.colors).find((p) =>
        typeof p.back === 'string' ? p.back === color : p.back.neutral === color
      );
    }
    if (c) {
      const fieldData = c[field] ?? c['text'];
      if (typeof fieldData === 'string') {
        colorCode = fieldData;
      } else {
        colorCode = fieldData[state] ?? fieldData.disabled;
      }
    } else {
      colorCode = field === 'back' ? color : invertColor(color);
    }
    return colorCode as ColorName;
  };

  setTheme = (theme: ThemeData) => {
    // nop, override to implement
  };

  static create(props: ThemeProps) {
    const {
      accentColor,
      baseColor = '#ffffff',
      backgroundColor = 'transparent',
      errorColor = '#F04932',
      successColor = '#119648',
    } = props;
    const backgroundTextColor = props.backgroundTextColor ?? baseColor;
    const accentSemi = transparentColor(accentColor, 0.4);
    const baseSemi = transparentColor(baseColor, 0.5);
    const errorSemi = transparentColor(errorColor, 0.5);
    const successSemi = transparentColor(successColor, 0.5);
    return new ThemeData({
      base: { back: backgroundColor, text: backgroundTextColor, border: baseColor },
      surface: {
        back: { neutral: baseColor, disabled: baseSemi },
        text: { neutral: accentColor, disabled: accentSemi },
        border: { neutral: baseColor, disabled: baseColor, pressed: accentColor },
      },
      accent: {
        back: { neutral: accentColor, disabled: accentSemi },
        text: { neutral: baseColor, disabled: baseSemi },
        border: accentColor,
      },
      error: {
        back: { neutral: errorColor, disabled: errorSemi, pressed: errorSemi },
        text: { neutral: baseColor, disabled: baseSemi, pressed: baseSemi },
        border: errorColor,
      },
      success: {
        back: { neutral: successColor, disabled: successSemi, pressed: successSemi },
        text: { neutral: baseColor, disabled: baseSemi, pressed: baseSemi },
        border: successColor,
      },
    });
  }
}

const defaultTheme = ThemeData.create({
  accentColor: '#e65a09',
  backgroundTextColor: '#e65a09',
});
// const defaultTheme = Theme.create({ accent: '#D9213C' });
// const defaultTheme = Theme.create({ accent: '#333333' });

const ThemeContext = React.createContext<ThemeData>(defaultTheme);

export function useTheme() {
  return React.useContext(ThemeContext);
}

export const ThemeProvider = (
  props: React.ComponentProps<typeof View> & {
    children: any;
  }
) => {
  const [theme, setTheme] = React.useState(defaultTheme);
  theme.setTheme = setTheme;
  return <ThemeContext.Provider value={theme}>{props.children}</ThemeContext.Provider>;
};

export function Theme(props: ThemeProps) {
  const theme = useTheme();
  React.useEffect(() => {
    theme.setTheme(ThemeData.create(props));
    return () => theme.setTheme(defaultTheme);
  }, Object.values(props));
  return null;
}
