import * as React from 'react';

import { List, useToast } from '../components';
import { observer, TemplateDiff } from '../store';
import TemplateListItem from '../template/TemplateListItem';
import useTemplateQuestionsSections from '../template/useTemplateQuestionsSections';
import { t, useTemplate, useNavigation, usePublishedTemplate } from '../utils';

export default observer(function PublishTemplateScreen() {
  const navigation = useNavigation();
  const toast = useToast();
  const template = useTemplate()!;
  const publishedTemplate = usePublishedTemplate()!;
  const [agreeToTerms, setAgreeToTerms] = React.useState(false);
  const { title, questions } = template;
  const diff =
    publishedTemplate.hasData && template.hasData
      ? TemplateDiff.create(publishedTemplate, template)
      : undefined;
  const isLoading = template.isLoading || publishedTemplate.isLoading;
  const sections = useTemplateQuestionsSections({
    template,
    diff,
    showFilter: true,
  });

  return (
    <List loading={isLoading} sections={sections}>
      <TemplateListItem template={template} onPress={null} />
      <List.Check
        value={agreeToTerms}
        title={t(
          'I hereby grant permission to public use of the template and confirm that I knowingly do not violate any copyrighted material of others'
        )}
        onChangeValue={setAgreeToTerms}
      />
      <List.Button
        title={t('Publish template', title)}
        onPress={async () => {
          await template.publish({
            agreeToTerms,
          });
          toast.show({ title: t('Request sent') });
          navigation.goBack();
        }}
        disabled={!questions.length || isLoading || !agreeToTerms}
      />
    </List>
  );
});
