import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { List, Title, useTheme, ColorName } from '../components';
import { observer, TemplateRequest } from '../store';
import { t, useNavigation, formatDate } from '../utils';

type Field = {
  type: string;
  text: string;
  color?: ColorName;
};

export default observer(function TemplateRequestListItem(
  props: Partial<React.ComponentProps<typeof List.Item>> & {
    templateRequest: TemplateRequest;
    compact?: boolean;
  }
) {
  const { templateRequest, compact, onPress, ...otherProps } = props;
  const navigation = useNavigation();
  const { c } = useTheme();
  const { questions, date, type, status, title, name, message, isOwner } = templateRequest;
  let requestText: string;
  const fields: Field[] = [];

  switch (type) {
    case 'publish':
      requestText = t('Request for publication') + '...';
      break;
    case 'unpublish':
      requestText = t('Revoke publication request') + '...';
      break;
  }
  if (!isOwner) {
    fields.push({ type: t('From'), text: name });
  }
  fields.push({ type: t('Date'), text: formatDate(date, true) });
  if (title) {
    fields.push({ type: t('Name'), text: title });
  }
  if (questions.hasData) {
    // TODO add number of different questions, eg: "12 questions (3 changed)"
    fields.push({ type: t('Questions'), text: `${questions.length}` });
  }
  if (message) {
    fields.push({ type: t('Message'), text: message });
  }
  switch (status) {
    case 'notReviewed':
      fields.push({
        type: t('Status'),
        text: t('Request sent'),
        color: c('surface', 'text', 'disabled'),
      });
      // statusColor = Palette.lightGray.color as any;
      break;
    case 'underReview':
      fields.push({
        type: t('Status'),
        text: t('Review in progress') + '...',
        color: c('surface', 'text'),
      });
      break;
    case 'accepted':
      fields.push({
        type: t('Status'),
        text: t('Request accepted'),
        color: c('success'),
      });
      break;
    case 'rejected':
      fields.push({ type: t('Status'), text: t('Request rejected'), color: c('error') });
      break;
  }

  return (
    <List.Item
      onPress={
        onPress === undefined
          ? () =>
              navigation.push('request', {
                templateRequest,
              })
          : onPress
      }
      {...otherProps}>
      {({ color }) => (
        <View style={compact ? styles.compactContent : styles.content}>
          <View style={styles.headerRow}>
            <Title>{requestText}</Title>
            <Title small>{formatDate(date)}</Title>
          </View>
          {!compact
            ? fields.map((field) => (
                <View key={field.type} style={styles.detailRow}>
                  <Title style={styles.type} small>
                    {field.type}
                  </Title>
                  <Title style={styles.value} color={field.color}>
                    {field.text}
                  </Title>
                </View>
              ))
            : undefined}
          {compact && status !== 'notReviewed' ? (
            <Title small>{fields.find((f) => f.type === t('Status'))?.text!}</Title>
          ) : undefined}
        </View>
      )}
    </List.Item>
  );
});

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'column',
    marginVertical: 8,
  },
  compactContent: {
    flex: 1,
    flexDirection: 'column',
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  detailRow: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
  },
  type: {
    width: 80,
  },
  value: {
    flex: 1,
  },
});

/*

[Gebruiker]   [datum]
[Naam]     
[aantal vragen]   [taal]
[status]


[datum]


Naam
Aantal vragen

Status
Taal

Van wie
Datum van verzoek



*/
