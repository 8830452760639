import * as React from 'react';

import { List } from '../components';
import { store, observer } from '../store';
import TemplateListItem from '../template/TemplateListItem';
import { t, useNavigation } from '../utils';

export default observer(function SelectTemplateScreen() {
  const { selectedTemplate, myTemplates, publishedTemplates } = store;
  const [tabIndex, setTabIndex] = React.useState(selectedTemplate.type === 'published' ? 0 : 1);
  const navigation = useNavigation();

  return (
    <List
      loading={
        tabIndex === 0
          ? publishedTemplates.isLoading && !publishedTemplates.hasDocs
          : myTemplates.isLoading && !myTemplates.hasDocs
      }
      placeholder={t('No templates yet')}>
      <List.TabBar
        index={tabIndex}
        values={[t('Public'), t('My templates')]}
        onChangeValue={setTabIndex}
      />
      {tabIndex === 0
        ? publishedTemplates.map((template, id) => (
            <List.Item
              key={id}
              title={template.t}
              value={template.q === 1 ? t('$1 question', '1') : t('$1 questions', template.q + '')}
              selected={id === store.selectedTemplate.publishedTemplate?.id}
              onPress={() => {
                store.selectedTemplate.publishedTemplate = {
                  id,
                  data: template,
                };
                navigation.goBack();
              }}
            />
          ))
        : myTemplates.docs.map((template) => (
            <TemplateListItem
              key={template.id}
              template={template}
              selected={template.id === selectedTemplate.privateTemplate?.id}
              onPress={() => {
                selectedTemplate.privateTemplate = template;
                navigation.goBack();
              }}
            />
          ))}
    </List>
  );
});
