import * as React from 'react';

import { Toast, BetaBadge, useToast } from '../components';
import { Sounds } from '../sound';
import { store, observer, Game } from '../store';
import { useTitle, useRoute, useNavigation, t } from '../utils';

export default observer(function GameStatusView(props: { game: Game }) {
  const { game } = props;
  const { auth } = store;
  const route = useRoute();
  const navigation = useNavigation();
  const toast = useToast();

  const [enableSound] = React.useState(() => async () => {
    try {
      await Sounds.requestPermissions();
    } catch (err) {
      toast.error(err as Error);
    }
  });
  const { title, status } = game;
  useTitle(title);

  async function joinGame() {
    try {
      await game.join({});
      toast.show({ title: t('You have joined') });
    } catch (e) {
      toast.error(e as Error);
    }
  }

  // Join game through link
  React.useEffect(() => {
    if (route.params.action === 'join') {
      if (!auth.isLoading && !game.isLoading && game.hasData && game.status === 'notStarted') {
        if (!auth.user) {
          navigation.push('signin', {
            joinGame,
          });
        } else if (!game.me) {
          joinGame();
        }
      }
    }
  }, [game.isLoading, auth.isLoading]);

  return (
    <>
      {status === 'paused' ? <Toast title={t('Game paused')} /> : undefined}
      <BetaBadge hidden={status === 'started' || status === 'paused'} />
      {Sounds.isNotAllowed ? (
        <Toast title={t('Tap to allow sounds')} icon="sound-disabled" onPress={enableSound} />
      ) : undefined}
    </>
  );
});
