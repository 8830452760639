import { Store } from './Store';
export * from './firebase';
export * from './framework';
export * from './Game';
export * from './Games';
export * from './GamePlayer';
export * from './Template';
export * from './Templates';
export * from './TemplateRequest';
export * from './TemplateRequests';
export * from './PublishedTemplate';
export * from './TemplateQuestions';
export * from './TemplateDiff';
export * from './types';
export * from './interfaces';
export { observer } from 'mobx-react-lite';

export type { Store };

export const store = new Store();
