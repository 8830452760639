import * as React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import Router from './Router';
import { BackgroundContainer, BetaBadgeContainer } from './components/background';
import { MenuContainer } from './components/menu';
import { ThemeProvider } from './components/theme';
import { ToastContainer } from './components/toast';

export default () => (
  <SafeAreaProvider>
    <ThemeProvider>
      <BackgroundContainer>
        <BetaBadgeContainer>
          <MenuContainer>
            <ToastContainer>
              <Router />
            </ToastContainer>
          </MenuContainer>
        </BetaBadgeContainer>
      </BackgroundContainer>
    </ThemeProvider>
  </SafeAreaProvider>
);
