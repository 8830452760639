import isEqual from 'lodash/isEqual';

import type { ITemplate } from './interfaces';
import type { TemplateQuestionData } from './types';

export type TemplateDiffQuestions = {
  [questionId: string]: {
    oldQuestion?: TemplateQuestionData;
    newQuestion?: TemplateQuestionData;
  };
};

export class TemplateDiff {
  public readonly oldTemplate: ITemplate;
  public readonly newTemplate: ITemplate;
  public readonly title?: string;
  public readonly questions: TemplateDiffQuestions;
  public readonly count: number;

  constructor(
    oldTemplate: ITemplate,
    newTemplate: ITemplate,
    questions: TemplateDiffQuestions,
    title?: string
  ) {
    this.oldTemplate = oldTemplate;
    this.newTemplate = newTemplate;
    this.title = title;
    this.questions = questions;
    this.count = (title != null ? 1 : 0) + Object.values(questions).length;
  }

  static create(
    oldTemplate: ITemplate,
    newTemplate: ITemplate,
    questionId?: string
  ): TemplateDiff | undefined {
    const title = oldTemplate.title !== newTemplate.title ? newTemplate.title : undefined;
    const questions: TemplateDiffQuestions = {};

    oldTemplate.questions.forEach((oldQuestion, id) => {
      if (!questionId || id === questionId) {
        const newQuestion = newTemplate.questions.get(id);
        if (!newQuestion) {
          questions[id] = { oldQuestion, newQuestion };
        }
      }
    });

    newTemplate.questions.forEach((newQuestion, id) => {
      if (!questionId || id === questionId) {
        const oldQuestion = oldTemplate.questions.get(id);
        if (!oldQuestion || !isEqual(oldQuestion, newQuestion)) {
          questions[id] = { oldQuestion, newQuestion };
        }
      }
    });

    return Object.keys(questions).length || title != null
      ? new TemplateDiff(oldTemplate, newTemplate, questions, title)
      : undefined;
  }
}
