import phone from 'phone';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getLocale } from '../utils';

import { List, useToast, Styles } from '../components';
import { observer, firebaseConfig, store, auth } from '../store';
import { t, getCountryCode, getLanguageCode, useNavigation, useRoute, is } from '../utils';
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from './expo-firebase-recaptcha';

const DEV_PHONENUMBER = '+31123456789';
const DEV_VERIFICATIONCODE = '123456';
const DEV_ENABLED = is('development');
// const DEV_ENABLED = false;

export default observer(function SignInScreen() {
  const navigation = useNavigation();
  const route = useRoute();
  const toast = useToast();
  const recaptchaVerifier = React.useRef(null);
  const verificationCodeTextInput = React.useRef(null);
  const [phoneNumber, setPhoneNumber] = React.useState(DEV_ENABLED ? DEV_PHONENUMBER : '');
  const [verificationId, setVerificationId] = React.useState('');
  const [inProgress, setInProgress] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState('');
  const safeInsets = useSafeAreaInsets();

  return (
    <View style={Styles.flex}>
      <FirebaseRecaptchaVerifierModal
        ref={recaptchaVerifier}
        // @ts-ignore TODO
        firebaseConfig={firebaseConfig}
        attemptInvisibleVerification
        appVerificationDisabledForTesting={DEV_ENABLED && phoneNumber === DEV_PHONENUMBER}
        languageCode={getLanguageCode()}
        // @ts-ignore Auth not defined on FirebaseRecaptchaVerifierModal
        auth={auth}
      />
      <List>
        {!verificationId ? (
          <List.TextInput
            label={t('Sign in with your phone number')}
            value={phoneNumber}
            autoFocus
            autoComplete="tel"
            editable={!inProgress}
            keyboardType="phone-pad"
            textContentType="telephoneNumber"
            placeholder={getLocale() === 'nl-NL' ? '06________' : t('E.g. +316_________')}
            onChangeText={(phoneNumber: string) => setPhoneNumber(phoneNumber)}
          />
        ) : undefined}
        {phoneNumber && !verificationId ? (
          <List.Button
            title={t('Send verification code')}
            disabled={phoneNumber.length < 1 || inProgress}
            onPress={async () => {
              const phoneProvider = store.auth.createPhoneAuthProvider();
              try {
                setInProgress(true);
                setVerificationId('');
                let normalizedPhoneNumber = phone(phoneNumber, {
                  country: getCountryCode(),
                });
                if (!normalizedPhoneNumber.isValid && phoneNumber !== DEV_PHONENUMBER) {
                  normalizedPhoneNumber = phone(phoneNumber);
                  if (!normalizedPhoneNumber.isValid) {
                    throw new Error(t('Invalid phone number ($1)', getCountryCode()));
                  }
                }
                const verificationId = await phoneProvider.verifyPhoneNumber(
                  normalizedPhoneNumber.phoneNumber! ?? phoneNumber,
                  recaptchaVerifier.current!
                );
                setInProgress(false);
                setVerificationId(verificationId);
                if (phoneNumber === DEV_PHONENUMBER) {
                  setVerificationCode(DEV_VERIFICATIONCODE);
                }
                // @ts-ignore TODO
                verificationCodeTextInput.current?.focus();
              } catch (e) {
                toast.error(e as Error);
                setInProgress(false);
              }
            }}
          />
        ) : undefined}
        {verificationId ? (
          <List.TextInput
            label={t('Enter received code')}
            ref={verificationCodeTextInput}
            value={verificationCode}
            editable={!!verificationId && !inProgress}
            keyboardType="number-pad"
            placeholder="123456"
            onChangeText={(verificationCode: string) => setVerificationCode(verificationCode)}
          />
        ) : undefined}
        {verificationId ? (
          <List.Button
            title={t('Confirm verification code')}
            disabled={!verificationCode || inProgress}
            onPress={async () => {
              try {
                setInProgress(true);
                const credential = store.auth.createPhoneAuthProviderCredential(
                  verificationId,
                  verificationCode
                );
                const user = await store.auth.signInWithCredential(credential);
                setInProgress(false);
                setVerificationId('');
                setVerificationCode('');
                toast.show({ title: t('You are signed in') });
                // @ts-ignore TODO
                verificationCodeTextInput.current?.clear();
                const joinGame = route.params?.joinGame;
                if (user.user?.displayName) {
                  navigation.goBack();
                  joinGame?.();
                } else {
                  navigation.replace('account', {
                    isNew: true,
                    joinGame,
                  });
                }
              } catch (e) {
                toast.error(e as Error);
                setInProgress(false);
              }
            }}
          />
        ) : undefined}
      </List>
      <View style={[styles.recaptchaBanner, { bottom: Math.max(safeInsets.bottom, 16) }]}>
        <FirebaseRecaptchaBanner />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  recaptchaBanner: {
    position: 'absolute',
    left: 16,
    right: 16,
  },
});
