import * as React from 'react';

import { List } from '../components';
import { observer } from '../store';
import { useNavigation } from '../utils';
import { getAdminAction } from './AdminActions';

export default observer(function AdminActionListItem(
  props: Partial<React.ComponentProps<typeof List.Item>> & {
    adminActionId: string;
  }
) {
  const { adminActionId, onPress, ...otherProps } = props;
  const navigation = useNavigation();
  return (
    <List.Item
      title={getAdminAction(adminActionId)!.title}
      onPress={
        onPress === undefined ? () => navigation.push('adminAction', { adminActionId }) : onPress
      }
      {...otherProps}
    />
  );
});
