import * as React from 'react';

import { PureComponent } from '../components';
import { Game, observer, GamePlayer, GameClientPhaseType, GameClientPhaseStatus } from '../store';
import GamePlayerItem, { GamePlayerValueType } from './GamePlayerItem';
import {
  CellContainer,
  layoutCells,
  CellLayoutDirection,
  CellBorder,
  Cell,
  CellBackground,
  CellTransition,
  cellTransition,
} from './cell';

export default observer(function GamePlayersView(
  props: Omit<React.ComponentProps<typeof CellContainer>, 'layout'> & {
    game: Game;
    phaseType: GameClientPhaseType;
    phaseStatus: GameClientPhaseStatus;
  }
) {
  const { game, phaseType, phaseStatus, ...cellProps } = props;
  const { players } = game;
  let players1: GamePlayer[] = [];
  let layoutDirection: CellLayoutDirection = 'column';
  let playSound = false;
  let valueType: GamePlayerValueType | undefined;
  let enterAnim: CellTransition | undefined = undefined;
  let enterDelay = 0;
  let exitAnim: CellTransition | undefined = undefined;
  let exitDelay = 0;
  let hideName = false;
  switch (phaseType) {
    case 'wait':
      enterAnim = cellTransition('slideBottom');
      exitAnim = cellTransition('fadeSlow');
      players1 = players.toArray().sort((a, b) => a.time - b.time);
      playSound = true;
      break;
    case 'intro':
      layoutDirection = 'row';
      players1 = players.toArray().sort((a, b) => a.time - b.time);
      exitAnim = cellTransition('slideBottom');
      exitDelay = 0.05;
      break;
    case 'lie':
      layoutDirection = 'row';
      enterAnim = cellTransition('slideRight');
      players1 = players
        .toArray()
        .sort((a, b) => a.time - b.time)
        .filter((p) => p.hasLied);
      playSound = true;
      break;
    case 'choose':
      layoutDirection = 'row';
      enterAnim = cellTransition('slideRight');
      hideName = true;
      players1 = players
        .toArray()
        .sort((a, b) => a.time - b.time)
        .filter((p) => p.hasChosen);
      playSound = true;
      break;
    case 'votes':
      layoutDirection = 'row';
      enterAnim = cellTransition('slideBottom');
      enterDelay = 0.1;
      if (phaseStatus === 'noInput') {
        const { choices, myLie } = game;
        const choice = choices.find((choice) => choice.text === myLie);
        players1 = choice?.chosenBy ?? [];
      }
      break;
    case 'choice':
      layoutDirection = 'row';
      enterAnim = cellTransition('slideBottom');
      enterDelay = 0.1;
      if (phaseStatus === 'noInput') {
        const { choices, myChoice } = game;
        const choice = choices.find((choice) => choice.text === myChoice);
        players1 = choice?.liedBy ?? [];
      }
      break;
    case 'truth':
      layoutDirection = 'row';
      enterAnim = cellTransition('slideBottom');
      enterDelay = 0.1;
      if (phaseStatus === 'noInput') {
        const { choices, truth } = game;
        const choice = choices.find((choice) => choice.text === truth);
        players1 = choice?.chosenBy ?? [];
      }
      break;
    case 'roundScore':
      enterAnim = cellTransition('slideBottom');
      valueType = 'roundScore';
      players1 = players
        .toArray()
        .sort((a, b) => (a.score === b.score ? b.time - a.time : b.score - a.score));
      break;
    case 'totalScore':
      valueType = 'totalScore';
      players1 = players
        .toArray()
        .sort((a, b) =>
          a.totalScore === b.totalScore ? b.time - a.time : b.totalScore - a.totalScore
        );
      break;
    default:
      players1 = players.toArray();
      break;
  }

  return (
    <PureComponent
      props={[
        layoutDirection,
        players1,
        valueType,
        hideName,
        playSound,
        enterAnim,
        enterDelay,
        exitAnim,
        exitDelay,
        cellProps.width,
        cellProps.height,
      ]}>
      {() => (
        <CellContainer {...cellProps} debugName="GamePlayers">
          <Cell key="placeholder" clip>
            <CellBackground width={cellProps.width} height={cellProps.height} color="base" />
            <CellBorder width={cellProps.width} height={cellProps.height} edge="left" hidden />
            <CellBorder width={cellProps.width} height={cellProps.height} edge="top" />
          </Cell>
          {layoutCells(
            {
              width: cellProps.width,
              height: cellProps.height,
              direction: layoutDirection,
            },
            players1.map((player, idx) => (
              <GamePlayerItem
                key={player.id}
                enter={
                  enterDelay
                    ? {
                        ...enterAnim,
                        delay: enterDelay * idx,
                      }
                    : enterAnim
                }
                exit={
                  exitDelay
                    ? {
                        ...exitAnim,
                        delay: exitDelay * idx,
                      }
                    : exitAnim
                }
                player={player}
                valueType={valueType}
                playSound={playSound}
                vertical={layoutDirection === 'row'}
                hideName={hideName}
              />
            ))
          )}
        </CellContainer>
      )}
    </PureComponent>
  );
});
