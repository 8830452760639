import * as React from 'react';
import { StyleSheet } from 'react-native';

import { Title } from '../components';
import { GamePlayer } from '../store';

export default function GamePlayerName(props: { player: GamePlayer; style?: any }) {
  const { player, style } = props;
  const { name, color } = player;
  return (
    <Title style={[styles.name, style]} color={color}>
      {name}
    </Title>
  );
}

const styles = StyleSheet.create({
  name: {
    marginRight: 16,
  },
});
