import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { NumberInput } from '../NumberInput';
import { Spacer } from '../Spacer';
import { Title } from '../Text';

export const ListNumberInput = React.forwardRef(
  (
    props: React.ComponentProps<typeof NumberInput> & {
      label?: string;
    },
    ref: any
  ) => {
    const { label, ...otherProps } = props;
    return (
      <View style={styles.container}>
        {label ? <Spacer small /> : undefined}
        {label ? <Title small>{label}</Title> : undefined}
        {label ? <Spacer small /> : undefined}
        <NumberInput ref={ref} {...otherProps} />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 8,
  },
});
