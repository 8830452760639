const en = require('./en/translation.json');
Object.keys(en).forEach((key) => (en[key] = key));

const languages = {
  en,
  nl: require('./nl/translation.json'),
};

if (__DEV__) {
  for (const [langKey, translation] of Object.entries(languages)) {
    for (const key in translation) {
      const text = translation[key];
      if (text === '') {
        console.debug(langKey + ': no text specified for: ' + key);
      } else if (!text.indexOf) {
        console.debug(langKey + ': invalid value for: ' + key + ': ' + text);
      }
    }
  }
}

export default languages;
