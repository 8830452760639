import * as React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

import { Title } from './Text';
import { useTheme, Shadows } from './theme';

export type TabBarProps = {
  index: number;
  values: string[];
  onChangeValue: (index: number) => any;
  style?: any;
};

export const TabBar = (props: TabBarProps) => {
  const { index, values, onChangeValue, style } = props;
  const { c } = useTheme();
  return (
    <View style={[styles.container, style]}>
      {values.map((option, idx) => (
        <TouchableOpacity
          key={idx}
          style={{
            flex: 1,
            justifyContent: 'center',
            backgroundColor: c(index === idx ? 'accent' : 'surface'),
            // borderColor: c('surface', true),
            // borderWidth: 1,
            // borderLeftWidth: idx ? 0 : 1,
            borderTopLeftRadius: idx === 0 ? 8 : 0,
            borderBottomLeftRadius: idx === 0 ? 8 : 0,
            borderTopRightRadius: idx === values.length - 1 ? 8 : 0,
            borderBottomRightRadius: idx === values.length - 1 ? 8 : 0,
          }}
          onPress={() => (idx !== index ? onChangeValue(idx) : undefined)}>
          <Title center small color={c(index === idx ? 'accent' : 'surface', 'text')}>
            {option}
          </Title>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 32,
    borderRadius: 8,
    ...Shadows.regular,
  },
});
